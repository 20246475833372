import * as React from 'react';
import {CircularProgress} from "@material-ui/core";
import gql from "graphql-tag";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Add, Delete, Edit} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import ButtonLink from "../ButtonLink";
import DataTable from "../tables/DataTable";

class Steps extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            steps: []
        };
    };

    componentDidMount() {
        this.loadSteps();
    }

    render() {
        if (this.state.loading) {
            return <CircularProgress/>;
        }
        else {
            return <Box>
                <Box mb={1} className={'vertically-centered'} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant={'body2'}>Steps are the individual stages that work will transition through. Steps are chained together to form the workflow of a folder. </Typography>
                    {this.renderMenu()}
                </Box>

                <DataTable
                    columns={[
                        {
                            Header: 'Name',
                            accessor: 'name'
                        },
                        {
                            Header: '',
                            id: 'actions',
                            width: 100,
                            Cell: ({ row }) =>
                                <Box className={'vertically-centered'}>
                                    <ButtonLink to={`/steps/${row.original.id}/edit`}>
                                        <Tooltip title="Edit this step">
                                            <IconButton>
                                                <Edit/>
                                            </IconButton>
                                        </Tooltip>
                                    </ButtonLink>

                                    <Tooltip title="Delete">
                                        <IconButton className={'delete-step'} onClick={() => {this.delete(row.original)}}>
                                            <Delete/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                        },
                    ]}
                    data={this.state.steps}
                    initialSort={[{id: 'name'}]}/>
            </Box>;
        }
    }

    delete = (step) => {
        if (this.props.unitTest ||
            window.confirm('Are you sure you want to delete this step? Attempting to delete a step that is still being used by a template will fail')) {
            this.setState({loading: true}, () => {
                this.props.asyncCallMade(
                    this.props.apiClient.mutate({
                        mutation: gql(`mutation DeleteStep($id: ID!) {
                            deleteStep(id: $id)
                        }`),
                        variables: {
                            id: step.id
                        }
                    }).then(() => {
                        this.loadSteps();
                    }).catch((reason) => {
                        console.log(`Failed to delete step ${step.id} because ${reason}`);
                        this.setState({
                            loading: false
                        });
                    })
                );
            });
        }
    };

    loadSteps = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {steps { id name }}`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    loading: false,
                    steps: results.data['steps']
                });
            })
        );
    };

    renderMenu() {
        return <ButtonLink to={`/steps/new`}>
            <Tooltip title={'Add a new step'}>
                <Fab color="primary">
                    <Add/>
                </Fab>
            </Tooltip>
        </ButtonLink>;
    }
}

export default Steps;
