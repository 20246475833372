import React, { Component } from 'react'

export default class WrappedFrame extends Component {
    state = { contentHeight: 100 };
    
    static defaultProps = {
        title: 'No title available',
        srcDoc: '<html><body><span>Content missing</span></body></html>'
    };
      
    handleResize = () => {
      const { body, documentElement } = this.container.contentWindow.document;
      const maxHeight = 1024;
      const contentHeight = Math.min(Math.max(
        body.clientHeight,
        body.offsetHeight,
        body.scrollHeight,
        documentElement.clientHeight,
        documentElement.offsetHeight,
        documentElement.scrollHeight
        ), maxHeight);
      if (contentHeight !== this.state.contentHeight) this.setState({ contentHeight });
    };
    
    onLoad = () => {
      this.container.contentWindow.addEventListener('resize', this.handleResize);
      this.handleResize();
    }
    
    componentWillUnmount() {
      this.container.contentWindow.removeEventListener('resize', this.handleResize);
    }
    
    render() {
      const { contentHeight } = this.state;
      return (
        <iframe          
          onLoad={this.onLoad}
          ref={(container) => { this.container = container; }}          
          srcDoc={this.props.srcDoc}
          style={{ width: '100%', height: `${contentHeight}px` }}
          title={this.props.title}
          sandbox
        />
      );
    }
  }