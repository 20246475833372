import * as React from 'react';
import Amplify, { Auth } from 'aws-amplify';
import {PureComponent} from 'react';
import { TenantLogin } from '@verlata/fyler-office-common';
import SignInForm from './SignInForm';
import ForgotPasswordForm from './ForgotPasswordForm'
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Home from "../Home";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import {Error} from "@material-ui/icons";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import {UserContext} from './UserContext';
import Header from "../Header";
import {withStyles} from "@material-ui/styles";
import Sales from "../Sales";
import {FylerLogger, ApiClientConfig} from "@verlata/fyler-office-common";
import {ApiClientContext} from "../ApiClientContext";
import {AUTH_TYPE} from "aws-appsync/lib";
import {AWSAppSyncClient} from "aws-appsync";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
});

class Authentication extends PureComponent {

    state = {
        apiClient: null,
        errorMessage: null,
        newPassword: null,
        signIn: false,
        working: true,
        signingIn: false,
        user: null,
        username: null,
    };
    tenantLogin = new TenantLogin(this.configureAuth);

    componentWillMount() {
        this.loadUser();
    }

    render() {
        if (this.state.working) {
            return <CircularProgress/>;
        }
        else if (!!this.state.user) {
            return <UserContext.Provider value={this.state.user}> 
                <ApiClientContext.Provider value={this.state.apiClient}>
                    <Home signOut={this.signOut} user={this.state.user}/>
                </ApiClientContext.Provider>
            </UserContext.Provider>;
        }
        else {
            const { classes } = this.props;
            return <div>
                <Header goHome={() => {}} contextualTitle={'Sign in'} signIn={this.showSignIn}/>

                <main key={this.state.version} className={classes.content}>
                    <div className={classes.toolbar}/>

                    {!!this.state.errorMessage && this.renderError()}

                    {
                        this.state.signIn ?
                            <div style={{width: '512px', margin: 'auto'}}>
                                {
                                    this.state.resetPassword ?
                                        (this.renderNewPassword()) :
                                        (this.state.forgotPassword ? 
                                            this.renderForgotPassword() :
                                            this.renderLogin())
                                }
                            </div> :
                            <Sales/>
                    }

                </main>
            </div>;
        }
    }

    renderError() {
        return <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                         open={true}
                         onClose={this.clearError}
                         autoHideDuration={5000}>
            <SnackbarContent className={'error'}
                             message={
                                 <div className={'vertically-centered'}>
                                     <Error/>
                                     <Box component={'span'} ml={1}>{this.state.errorMessage}</Box>
                                 </div>
                             }/>
        </Snackbar>;
    }

    renderLogin() {
        if (this.state.signingIn) {
            return <CircularProgress/>;
        }
        else {
            return <SignInForm login={this.login}
                                error={this.error}
                                username={this.state.username}
                                forgotPassword={() => {this.setState({forgotPassword: true})}}/>;
        }
    }

    renderForgotPassword() {
        return <ForgotPasswordForm tenantLogin={this.tenantLogin} 
                                   errorFunction={this.error} 
                                   goHome={() => {this.setState({forgotPassword: false})}}/>
    }

    renderNewPassword() {
        return (
            <Box mt={2}>
                <form>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={'h4'} component={'span'} color="textPrimary">
                                Reset Password
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name='password' label='New Password' type='password' autoFocus value={this.state.newPassword.password} xs={12} fullWidth required onChange={this.updatePassword}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name='confirmPassword' label='Confirm Password' type='password' value={this.state.newPassword.confirmPassword} xs={12} fullWidth required onChange={this.updateConfirmPassword}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" xs={12} onClick={this.resetPassword}>Reset</Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        );
    }

    configureAuth(configuration) {
        Amplify.configure(configuration);
    }

    clearError = () => {
        this.setState({errorMessage: null});
    };

    error = (message) => {
        console.log(`Error: ${message}`);
        this.setState({
            errorMessage: message,
            signingIn: false,
            working: false
        });
    };

    login = (username, password) => {
        this.setState({signingIn: true, username: username}, () => {
            try {
                this.tenantLogin.setUsername(username);

                Auth.signIn({ username, password })
                    .then((user) => {
                        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                            this.setState({resetPassword: true, newPassword: {
                                email: username, user: user, password: '', confirmPassword: ''}});
                        }
                        else if (!user.attributes.email_verified || 
                                 (typeof(user.attributes.email_verified) == "string" && !user.attributes.email_verified.toLowerCase() == "true")) {
                            console.log('email not verefied');
                            this.tenantLogin.loginSuccessful();
                            this.loadUser();
                        }
                        else {
                            this.tenantLogin.loginSuccessful();
                            this.loadUser();
                        }
                        return Promise.resolve();
                    })
                    .catch(err => {
                        this.error(err.message);
                    });
            }
            catch (e) {
                this.error(e.message);
            }
        });
    };

    loadUser = () => {
        this.setState({working: true}, () => {
            if (this.tenantLogin.isLoggedIn()) {
                Auth.currentAuthenticatedUser()
                    .then(currentUser => {
                        Auth.currentSession()
                            .then((session) => {
                                this.setState({
                                    user: {
                                        username: currentUser.username,
                                        tenantName: this.tenantLogin.getTenantName(),
                                        groups: session.getAccessToken().decodePayload()['cognito:groups'],
                                        ...currentUser.attributes
                                    },
                                    apiClient: new AWSAppSyncClient({
                                        url: ApiClientConfig.endpointForRegion(this.tenantLogin.getRegion()),
                                        region: this.tenantLogin.getRegion(),
                                        auth: {
                                            type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
                                            jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
                                        },
                                        disableOffline: true
                                    }),
                                    working: false,
                                    errorMessage: null
                                });
                            });
                    })
                    .catch(err => {
                        console.log('error is: ' + JSON.stringify(err));
                        this.error(err.message);
                    });
            }
            else {
                console.log('User is not logged in');
                this.setState({working: false});
            }
        });
    };

    resetPassword = () => {
        if (this.state.newPassword.password === this.state.newPassword.confirmPassword) {
            return Auth.completeNewPassword(
                this.state.newPassword.user,
                this.state.newPassword.password)
                .then((_) => {
                    this.login(this.state.newPassword.email, this.state.newPassword.password)}
                )
                .catch(err => {
                    this.error(err.message);
                });
        }
        else {
            this.error('The passwords do not match. Please try again.');
        }
    };

    showSignIn = () => {
        this.setState({signIn: true});
    };

    signOut = () => {
        Auth.signOut()
            .then(this.tenantLogin.logout)
            .then((_) => this.setState({user: null, working: false, signIn: false, signingIn: false, resetPassword: false}))
            .catch(err => FylerLogger.error(`Failed to sign out: ${JSON.stringify(err)}`));
    };

    updateConfirmPassword = (event) => {
        this.setState({
            newPassword: {
                ...this.state.newPassword,
                confirmPassword: event.target.value
            }
        });
    };

    updatePassword = (event) => {
        this.setState({
            newPassword: {
                ...this.state.newPassword,
                password: event.target.value
            }
        });
    };
}

export default withStyles(styles)(Authentication);
