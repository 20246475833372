import * as React from 'react';
import {CircularProgress} from "@material-ui/core";
import gql from "graphql-tag";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Add, Delete, Edit} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import ButtonLink from "../ButtonLink";
import DataTable from "../tables/DataTable";

class Fields extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            fields: []
        };
    };

    componentDidMount() {
        this.loadFields();
    }

    render() {
        if (this.state.loading) {
            return <CircularProgress/>;
        }
        else {
            return <Box>
                <Box mb={1} className={'vertically-centered'} style={{justifyContent: 'space-between'}}>
                    <Typography variant={'body2'}>
                        Fields are custom types used to defined the acceptable values for folder metadata.
                        Each field on a template will be one of these custom types or a built-in type.
                    </Typography>
                    {this.renderMenu()}
                </Box>

                <DataTable
                    columns={[
                        {
                            Header: 'Name',
                            accessor: 'name'
                        },
                        {
                            Header: '',
                            id: 'actions',
                            width: 100,
                            Cell: ({ row }) =>
                                <Box className={'vertically-centered'}>
                                    <ButtonLink to={`/fields/${row.original.id}/edit`}>
                                        <Tooltip title="Edit this field">
                                            <IconButton>
                                                <Edit/>
                                            </IconButton>
                                        </Tooltip>
                                    </ButtonLink>

                                    <Tooltip title="Delete">
                                        <IconButton className={'delete-field'} onClick={() => {this.delete(row.original)}}>
                                            <Delete/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                        },
                    ]}
                    data={this.state.fields}
                    initialSort={[{id: 'name'}]}/>
            </Box>;
        }
    }

    delete = (field) => {
        if (this.props.unitTest ||
            window.confirm('Are you sure you want to delete this field? Attempting to delete a field that is still being used by a template will fail')) {
            this.setState({loading: true}, () => {
                this.props.asyncCallMade(
                    this.props.apiClient.mutate({
                        mutation: gql(`mutation DeleteField($id: ID!) {
                            deleteField(id: $id)
                        }`),
                        variables: {
                            id: field.id
                        }
                    }).then(() => {
                        this.loadFields();
                    }).catch((reason) => {
                        console.log(`Failed to delete field ${field.id} because ${reason}`);
                        this.setState({
                            loading: false
                        });
                    })
                );
            });
        }
    };

    loadFields = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {fields { id name builtin }}`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    loading: false,
                    fields: results.data['fields'].filter((nextField) => {
                        return !nextField.builtin;
                    })
                });
            })
        );
    };

    renderMenu() {
        return <ButtonLink to={`/fields/new`}>
            <Tooltip title={'Add a new field'}>
                <Fab color="primary">
                    <Add/>
                </Fab>
            </Tooltip>
        </ButtonLink>;
    }
}

export default Fields;
