import * as React from 'react';
import {Link as RouterLink} from "react-router-dom";
import Button from "@material-ui/core/Button";

export default class ButtonLink extends React.Component {

    render() {
        return <Button component={this.link()} className={'vertically-centered'} {...this.props}>
            {this.props.children}
        </Button>;
    }

    link = () => {
        return React.forwardRef((itemProps, ref) => (
            <RouterLink {...itemProps} innerRef={ref} />
        ));
    };
}
