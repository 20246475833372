import * as React from 'react';
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

class StepForm extends React.Component {

    render() {
        return <Formik
            initialValues={this.props.step}
            validate={this.validate}
            onSubmit={this.props.save}
            render={({ values, isSubmitting }) => (
                <Form style={{width: '50%'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field name='name' label={'Name'} component={TextField} value={values.name} fullWidth/>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant='contained' color='primary' disabled={isSubmitting} type={'submit'}>
                                {this.props.submitButtonText}
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant='contained' color='secondary' disabled={isSubmitting} onClick={this.props.cancel}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}/>;
    };

    validate = (values) => {
        let errors = {};
        if (!values.name) {
            errors.name = 'Required';
        }
        return errors;
    };
}

export default StepForm;
