import * as React from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Auth } from 'aws-amplify';

class ForgotPasswordForm extends React.Component {

    state = {
        username: null,
        thinking: false,
        step: 1,
        code: null,
        newPassword: null,
        confirmPassword: null
    };


    render() {
        if (this.state.thinking) {
            return <CircularProgress />
        }
        else {
            switch(this.state.step) {
                case 1:
                    return <Box mt={2}>
                                <form onSubmit={this.forgotPassword}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TextField name='username' value={this.state.username} label='Email' xs={12} autoFocus fullWidth onChange={this.updateUsername} onKeyPress={this.keyPress}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                this.validateEmail(this.state.username) ?
                                                <p style={{ display: "flex", alignItems: "center", color: 'green'}}><CheckCircleIcon style={{fontSize: '16px'}} />&nbsp;must be a valid email</p> :
                                                <p style={{ display: "flex", alignItems: "center", color: 'red'}}><CancelIcon style={{fontSize: '16px'}} />&nbsp;must be a valid email</p>
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button variant="contained" color="primary" xs={12} onClick={this.props.goHome}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button variant="contained" color="primary" xs={12} type="submit" style={{float: 'right'}} disabled={!!!this.validateEmail(this.state.username)}>
                                                Get Reset Code
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                case 2:
                    return <Box mt={2}>
                                <h3>We've sent a code to <b>{this.state.username}</b> and it might take a couple minutes to come through.</h3>
                                <br/>
                                <form onSubmit={this.setCode}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TextField name='code' label='code' xs={12} autoFocus fullWidth onChange={this.updateCode} onKeyPress={this.keyPress}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <Button variant="contained" color="primary" xs={12} onClick={this.props.goHome}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button variant="contained" color="primary" xs={12} type="submit" style={{float: 'right'}} disabled={!!!this.state.code}>
                                                Next
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                case 3:
                    return <Box mt={2}>
                                <form onSubmit={this.defineNewPassword}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TextField name='password' label='Please enter a new password' xs={12} autoFocus fullWidth onChange={this.updateNewPassword} onKeyPress={this.keyPress}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField name='confirmPassword' label='Confirm password' xs={12} fullWidth onChange={this.updateConfirmPassword} onKeyPress={this.keyPress}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                this.containsNumber(this.state.newPassword) ?
                                                <p style={{ display: "flex", alignItems: "center", color: 'green'}}><CheckCircleIcon style={{fontSize: '16px'}} />&nbsp;must contain number</p> :
                                                <p style={{ display: "flex", alignItems: "center", color: 'red'}}><CancelIcon style={{fontSize: '16px'}} />&nbsp;must contain number</p>
                                            }
                                            {
                                                this.containsUppercase(this.state.newPassword) ?
                                                <p style={{ display: "flex", alignItems: "center", color: 'green'}}><CheckCircleIcon style={{fontSize: '16px'}} />&nbsp;must contain upper case</p> :
                                                <p style={{ display: "flex", alignItems: "center", color: 'red'}}><CancelIcon style={{fontSize: '16px'}} />&nbsp;must contain upper case</p>
                                            }
                                            {
                                                this.containsLowercase(this.state.newPassword) ?
                                                <p style={{ display: "flex", alignItems: "center", color: 'green'}}><CheckCircleIcon style={{fontSize: '16px'}} />&nbsp;must contain lower case</p> :
                                                <p style={{ display: "flex", alignItems: "center", color: 'red'}}><CancelIcon style={{fontSize: '16px'}} />&nbsp;must contain lower case</p>
                                            }
                                            {
                                                this.longEnough(this.state.newPassword) ?
                                                <p style={{ display: "flex", alignItems: "center", color: 'green'}}><CheckCircleIcon style={{fontSize: '16px'}} />&nbsp;must be at least 8 character</p> :
                                                <p style={{ display: "flex", alignItems: "center", color: 'red'}}><CancelIcon style={{fontSize: '16px'}} />&nbsp;must be at least 8 character</p>
                                            }
                                            {
                                                this.passwordsMatch() ?
                                                <p style={{ display: "flex", alignItems: "center", color: 'green'}}><CheckCircleIcon style={{fontSize: '16px'}} />&nbsp;passwords must match</p> :
                                                <p style={{ display: "flex", alignItems: "center", color: 'red'}}><CancelIcon style={{fontSize: '16px'}} />&nbsp;passwords must match</p>
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button variant="contained" color="primary" xs={12} onClick={this.props.goHome}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button variant="contained" color="primary" xs={12} type="submit" style={{float: 'right'}} disabled={!this.passwordsQualify()}>
                                                Next
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                case 4:
                    this.props.goHome();
                default:
                    return true;
            }
        }
    }

    forgotPassword = (event) => {
        event.preventDefault();
        this.setState({thinking: true}, () => {
            try {
                this.props.tenantLogin.setUsername(this.state.username);

                Auth.forgotPassword(this.state.username)
                    .then((result) => {
                        console.log(result);
                        
                        this.setState((prevState, props) => ({
                            step: prevState.step + 1,
                            thinking: false
                        }));

                        return Promise.resolve();
                    })
                    .catch(err => {
                        this.stopSpinner();
                        this.props.errorFunction(err.message);

                    });
            }
            catch (e) {
                this.stopSpinner();
                this.props.errorFunction(e.message);
            }
        });
    }

    setCode = (event) => {
        event.preventDefault();

        if (this.state.code) {
            this.setState({thinking:true}, () => {
                this.setState((prevState, props) => ({
                    step: prevState.step + 1,
                    thinking: false
                }));
            });
        }
        else {
            this.props.errorFunction("Please enter code from email")
        }
    }

    defineNewPassword = (event) => {
        event.preventDefault();
        this.setState({thinking: true}, () => {
            try {
                Auth.forgotPasswordSubmit(this.state.username, this.state.code, this.state.confirmPassword)
                    .then((result) => {
                        console.log(result);
                        
                        this.setState((prevState, props) => ({
                            step: prevState.step + 1,
                            thinking: false
                        }));

                        return Promise.resolve();
                    })
                    .catch(err => {
                        this.setState({
                            thinking: false,
                            step: 1,
                            code: null,
                            username: null
                        });
                        this.props.errorFunction(err.message);
                    });
            }
            catch (e) {
                this.stopSpinner();
                this.props.errorFunction(e.message);
            }
        });
    }

    updateUsername = (event) => {
        this.setState({username: event.target.value});
    };

    updateNewPassword = (event) => {
        this.setState({newPassword: event.target.value});
    };

    updateConfirmPassword = (event) => {
        this.setState({confirmPassword: event.target.value});
    };

    passwordsQualify() {
        if (this.state.newPassword != null &&
            this.longEnough(this.state.newPassword) &&
            this.containsNumber(this.state.newPassword) &&
            this.containsUppercase(this.state.newPassword) &&
            this.containsLowercase(this.state.newPassword) &&
            this.passwordsMatch()) {
                return true;
            }
        else {
            return false;
        }
    }

    containsNumber = (str) => {
        return !!str && /\d/.test(str);
    }

    containsUppercase = (str) => {
        return !!str && (/[A-Z]/.test(str));
    }

    containsLowercase = (str) => {
        return !!str && (/[a-z]/.test(str));
    }

    longEnough = (str) => {
        return !!str && str.length > 7;
    }

    passwordsMatch() {
        return !!this.state.newPassword && (this.state.newPassword == this.state.confirmPassword);
    }

    updateCode = (event) => {
        this.setState({code: event.target.value});
    };

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    stopSpinner() {
        this.setState({
            thinking: false
        });
    }
}

export default ForgotPasswordForm;
