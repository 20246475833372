import * as React from 'react';
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {withStyles} from '@material-ui/styles';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {UserContext} from "./auth/UserContext";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
    appBar: {
        backgroundColor: '#414042',
        zIndex: theme.zIndex.drawer + 1,
    }
});

class Header extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <AppBar position={'fixed'} className={classes.appBar}>
                <UserContext.Consumer>
                    {user =>
                        <Toolbar>
                            {!!user && this.renderTitle()}
                            {
                                !!user &&
                                    <Box component={'span'} style={{marginLeft: 'auto'}} className={'vertically-centered'}>
                                        <Box component={'span'} mr={3}>{this.renderSignOut()}</Box>
                                        <Box component={'span'} mr={3}>
                                            <IconButton title={'Help?'} onClick={() => this.loadKnowledgeBase()} style={{color: 'white'}}>
                                                <HelpIcon />
                                            </IconButton>
                                        </Box>
                                        <img src={`/assets/${user.tenantName}_logo.png`} alt={'Fyler'} style={{paddingRight: '15px'}}/>
                                    </Box>
                            }

                            { !!!user && this.renderSignIn() }
                        </Toolbar>
                    }
                </UserContext.Consumer>
            </AppBar>
        );
    }

    loadKnowledgeBase = () => {
        window.open("/KnowledgeBase", "_blank");
    }

    renderSignIn = () => {
        return <Button variant="contained" color={'primary'} style={{marginLeft: 'auto'}} onClick={this.props.signIn}>
            Sign In
        </Button>;
    };

    renderSignOut = () => {
        return <Button variant="contained" color={'primary'} size={'small'} onClick={this.props.signOut}>
            Sign Out
        </Button>;
    };

    renderTitle = () => {
        return <Box className={'vertically-centered'}>
            <Button onClick={this.props.goHome}>
                <img src={'/assets/monogram_rev_32.png'} alt={'Fyler Logo'}/>
            </Button>

            {this.props.children}

            <Typography variant={'h5'} component={'span'} style={{paddingLeft: '1rem'}}>
                {this.props.contextualTitle}
            </Typography>
        </Box>;
    };
}

export default withStyles(styles)(Header);
