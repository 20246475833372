import * as React from 'react';
import gql from "graphql-tag";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FieldForm from "./FieldForm";
import {withRouter} from "react-router-dom";

class AddField extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
        };
    };

    render() {
        return <Box>
            <Typography variant={'h4'}>
                New Field
            </Typography>

            <FieldForm field={{name: '', type: 'choice', values: []}}
                       submitButtonText={'Create'}
                       save={this.create}
                       cancel={this.done}/>
        </Box>;
    }

    create = (values, {setSubmitting}) => {
        console.log(`Creating field with: ${JSON.stringify(values)}`);

        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation CreateField($input: UpdateField!) {
                    createField(input: $input)
                }`),
                variables: {
                    input: {
                        name: values.name,
                        type: 'choice',
                        values: values.values
                    }
                }
            }).then((_) => {
                setSubmitting(false);
                this.done();
            })
        );
    };

    done = () => {
        this.props.history.goBack();
    };
}

export default withRouter(AddField);
