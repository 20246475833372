import React from 'react';
import gql from "graphql-tag";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FolderSearchResult from "./FolderSearchResult";
import SearchField from "../SearchField";
import {FileCopy, LocalShipping} from "@material-ui/icons";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import {CircularProgress} from "@material-ui/core";
import Box from "@material-ui/core/Box";

class MoveDocumentsPopup extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);


        this.state = {
            selected: props.selected,
            errors: 0
        };
    };

    render() {
        return <div>
            <Dialog open={true}
                    onClose={this.props.close}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={'md'}>
                <DialogTitle id="form-dialog-title">Moving / Copying {(this.state.selected !== null && this.state.selected !== undefined) ?
                                                            this.state.selected.length : 0} documents to another folder</DialogTitle>
                <DialogContent>
                    <Box className={'vertically-centered'} style={{flexDirection: 'column'}}>
                        <DialogContentText>
                            Search for a folder to move your selected document(s) to.
                        </DialogContentText>

                        <SearchField query={this.props.query}
                                     search={this.search}
                                     searching={this.state.searching}
                                     style={{marginLeft: 0}}/>
                    </Box>
                    {!!this.state.folderSearchResults && this.renderFolderSearchResults()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.close} color="primary">
                    Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            {!!this.state.documentsMoved && this.props.close()}
            {!!this.state.documentsMoved && this.props.reloadFolder()} {/* wtf? */}
        </div>;
    }

    renderFolderSearchResults = () => {
        return <FolderSearchResult
            columns={[
                {
                    Header: "Folder",
                    id: 'name',
                    Cell: ({ row }) => <Link href={'#'} className={'folder'} onClick={() => this.viewFolder(row.original.id)}>
                        {row.original.name}
                    </Link>,
                    sortType: (row0, row1) => {
                        return row0.original.name.localeCompare(row1.original.name);
                    },
                    minWidth: 200
                },
                {
                    Header: "",
                    id: 'actions',
                    Cell: ({ row }) => this.renderFolderSearchResultMoveActions(row.original),
                    width: 36
                },
                {
                    Header: "",
                    id: 'actions2',
                    Cell: ({ row }) => this.renderFolderSearchResultCopyActions(row.original),
                    maxWidth: 50
                }
            ]}
            initialSort={[{id: 'name'}]}
            results={this.state.folderSearchResults.filter((nextResult) => {
                return nextResult.id !== this.props.id;
            })}/>;
    };

    renderFolderSearchResultMoveActions = (row) => {
        if (this.state.movingTo === row.id) {
            return <CircularProgress />;
        }
        else if (this.state.working) {
            return <Tooltip title="Move to this folder">
                        <Button className={'move-to-folder'} variant={'outlined'} color={'primary'} disabled={true}>
                            <LocalShipping/>
                        </Button>
                    </Tooltip>
        }
        else {
            return <Tooltip title="Move to this folder">
                        <Button className={'move-to-folder'} variant={'outlined'} color={'primary'} onClick={(click) => this.actionSelectedDocumentsTo(row, 'move')}>
                            <LocalShipping/>
                        </Button>
                    </Tooltip>
        }
        
    }

    renderFolderSearchResultCopyActions = (row) => {
        if (this.state.movingTo === row.id) {
            return <CircularProgress />;
        }
        else if (this.state.working) {
            return <Tooltip title="Copy to this folder">
                        <Button className={'copy-to-folder'} variant={'outlined'} color={'primary'} disabled={true}>
                            <FileCopy/>
                        </Button>
                    </Tooltip>
        }
        else {
            return <Tooltip title="Copy to this folder">
                        <Button className={'copy-to-folder'} variant={'outlined'} color={'primary'} onClick={(click) => this.actionSelectedDocumentsTo(row, 'copy')}>
                            <FileCopy/>
                        </Button>
                    </Tooltip>
        }
        
    }

    actionSelectedDocumentsTo = (folder, action) => {
        this.setState({working: true,
                       movingTo: folder.id});

        const documents = this.state.selected.filter(item => item.__typename === 'DocSearchResult'); 
        //const folders = this.state.selected.filter(item => item.__typename == 'Folder');

        const actions = documents.map((row) => {
            return this.actionDocTo(action, row, folder.id)
        }).map(p => p.catch(e => e));

        Promise.all(actions).then((results) => {
            action === 'move' ? this.props.finishedMove(this.state.errors) : this.props.finishedCopy(this.state.errors);
            this.props.close();
        });
    };

    actionDocTo = (action, document, folderId) => {
        return new Promise((resolve, reject) => {
            console.log(this.actionWord(action) + 'ing doc ' + document.id + ' from ' + document.folder_id +' to ' + folderId);
            this.props.apiClient.mutate({
                mutation: gql(`mutation {
                    ${action}Doc(current_folder_id: "${document.folder_id}", doc_id: "${document.id}", new_folder_id: "${folderId}")
                }`)
            })
            .then((result) => {
                resolve();
            })
            .catch((reason) => {
                this.addError();
                console.log(`Failed to ${action} document: ${JSON.stringify(reason)}`);
                reject();
            });
        });
    }

    search = (query) => {
        this.setState({searching: true, results: null}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query({
                    query: gql(`query Query($query: String!) {
                        searchFolders(query: $query, size: 100) { id description type parent { id description } }
                    }`),
                    variables: {
                        query: query
                    },
                    fetchPolicy: 'no-cache'
                }).then((results) => {
                    const folders = results.data['searchFolders'];
                    this.setState({
                        searching: false,
                        folderSearchResults: folders
                    });
                })
            );
        });
    };

    addError = () => {
        this.setState((prevState, props) => ({
            errors: prevState.errors + 1
        }));
    }

    actionWord = (string) => {
        return string.replace('copy', 'Copy').replace('move', 'Mov');
    }
    
}

export default MoveDocumentsPopup;
