import * as React from 'react';
import {Field, FieldArray, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {CircularProgress, Tooltip} from "@material-ui/core";
import gql from "graphql-tag";
import FolderField from "./FolderField";

class FolderForm extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.normaliseFolderFields();

        this.state = {
            fields: [],
            working: true
        };
    };

    componentDidMount() {
        this.loadFields();
    }

    render() {
        if (this.state.working) {
            return <CircularProgress/>;
        }
        else {
            return <Formik
                initialValues={this.props.folder}
                validate={this.validate}
                onSubmit={this.props.save}
                render={({ values, setFieldValue, isSubmitting }) => (
                    <Form style={{width: '50%'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field name='description' label={'Name'} component={TextField} value={values.description} fullWidth/>
                            </Grid>
                            <Grid item xs={12}>
                                <FieldArray
                                    name='fields'
                                    render={arrayHelpers => (
                                        <Box mt={1}>
                                            <Typography variant={'h6'} component={'span'}>
                                                Fields
                                                <Tooltip title={'Add a custom field'}>
                                                    <IconButton onClick={() => arrayHelpers.push({ name: '', type: 'text_short', value: '', newField: true })}>
                                                        <AddCircleIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Typography>
                                            {values.fields.map((nextField, index) => (
                                                <Box key={index} mt={2}>
                                                    <Grid container spacing={1}>
                                                        <input type='hidden' name={`fields[${index}].type`} value={nextField.type}/>
                                                        {
                                                            nextField.newField ?
                                                                [
                                                                    <Grid key={`fields.${index}.label`} item xs={5} className={'vertically-centered'}>
                                                                        <Field name={`fields[${index}].name`} label={'Field'} component={TextField} value={nextField.name} fullWidth required/>
                                                                    </Grid>,
                                                                    <Grid key={`fields.${index}.value`} item xs={5}>
                                                                        <FolderField name={`fields[${index}].value`} field={nextField} fieldTypes={this.state.fields} setValue={setFieldValue}/>
                                                                    </Grid>
                                                                ] :
                                                                <Grid item xs={10}>
                                                                    <input type='hidden' name={`fields[${index}].name`} value={nextField.name}/>
                                                                    <FolderField name={`fields[${index}].value`} field={nextField} fieldTypes={this.state.fields} setValue={setFieldValue} variant='outlined'/>
                                                                </Grid>
                                                        }
                                                        <Grid item xs={2}>
                                                            <Tooltip title={'Remove this field'}>
                                                                <IconButton onClick={() => arrayHelpers.remove(index)} xs={2}>
                                                                    <RemoveCircleIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='contained' color='primary' disabled={isSubmitting} type={'submit'}>
                                    {this.props.submitButtonText}
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='contained' color='secondary' disabled={isSubmitting} onClick={this.props.cancel}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}/>;
        }
    };

    loadFields = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {fields { id type values }}`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    working: false,
                    fields: results.data['fields']
                });
            })
        );
    };

    normaliseFolderFields = () => {
        this.props.folder.fields.forEach((nextField) => {
            if (!!!nextField.value &&
                (nextField.type === 'text_short' || nextField.type === 'text_long')) {
                nextField.value = '';
            }
        });
    };

    validate = (values) => {
        let errors = {};
        if (!values.description) {
            errors.description = 'Required';
        }
        return errors;
    };
}

export default FolderForm;
