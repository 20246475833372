import * as React from 'react';
import {Field} from "formik";
import {RadioGroup, TextField} from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {DatePicker} from "@material-ui/pickers";
import * as moment from "moment";
import {Radio} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";

class FolderField extends React.Component {

    render() {
        const fieldType = this.fieldType();
        if (this.isType(fieldType, 'choice')) {
            return this.renderDropbox(fieldType);
        }
        else if (this.isType(fieldType, 'boolean')) {
            return this.renderBoolean();
        }
        else if (this.isType(fieldType, 'numeric')) {
            return this.renderNumeric();
        }
        else if (this.isType(fieldType, 'currency')) {
            return this.renderCurrency();
        }
        else if (this.isType(fieldType, 'text_long')) {
            return this.renderTextLong();
        }
        else if (this.isType(fieldType, 'date')) {
            return this.renderDate();
        }
        else {
            return this.renderTextShort();
        }
    };

    fieldType() {
        return this.props.fieldTypes.find((nextFieldType) => {
            return nextFieldType.id === this.props.field.type;
        }) || {
            id: 'text_short',
            name: 'Text',
            type: 'text_short'
        };
    }

    isType(fieldType, expectedType) {
        return fieldType.type === expectedType;
    }

    label() {
        return !!this.props.field.name ? this.props.field.name : 'Value';
    }

    renderBoolean() {
        return <Box>
            <FormLabel>{this.label()}</FormLabel>
            <Field name={this.props.name}
                   label={this.label()}
                   component={RadioGroup}
                   value={this.props.field.value}
                   row>
                <FormControlLabel
                    value="Yes"
                    control={<Radio/>}
                    labelPlacement={'end'}
                    label="Yes"
                />
                <FormControlLabel
                    value="No"
                    control={<Radio/>}
                    labelPlacement={'end'}
                    label="No"
                />
            </Field>
        </Box>;
    }

    renderCurrency() {
        return <Field name={this.props.name}
                      label={this.label() + ' ($)'}
                      xs={5}
                      component={TextField}
                      value={this.props.field.value}
                      type={"number"}
                      inputProps={{step: 0.01}}
                      fullWidth
                      variant={this.variant()}/>;
    }

    renderDate() {
        return <DatePicker
            name={this.props.name}
            clearable
            autoOk
            label={this.label()}
            format="DD MMM YYYY"
            onChange={(newValue) => {
                this.props.setValue(this.props.name, moment(newValue).format("DD MMM YYYY"));
            }}
            value={!!this.props.field.value ? this.props.field.value : null}
            animateYearScrolling={false}
            fullWidth
            variant={this.variant()}/>;
    }

    renderDropbox(fieldType) {
        return <Field name={this.props.name}
                      label={this.label()}
                      component={TextField}
                      select
                      value={!!this.props.field.value ? this.props.field.value : ''}
                      fullWidth>
            {fieldType.values.map((nextValue) => {
                return <MenuItem key={nextValue} value={nextValue}>
                    {nextValue}
                </MenuItem>;
            })}
        </Field>;
    }

    renderNumeric() {
        return <Field name={this.props.name}
                      label={this.label()}
                      xs={5}
                      component={TextField}
                      value={this.valueOrEmptyString()}
                      type={"number"}
                      fullWidth
                      variant={this.variant()}/>;
    }

    renderTextLong() {
        return <Field name={this.props.name}
                      label={this.label()}
                      xs={5}
                      component={TextField}
                      multiline={true}
                      fullWidth
                      rows={3}
                      value={this.valueOrEmptyString()}
                      variant={this.variant()}/>;
    }

    renderTextShort() {
        return <Field name={this.props.name}
                      label={this.label()}
                      xs={5}
                      component={TextField}
                      value={this.valueOrEmptyString()}
                      fullWidth
                      variant={this.variant()}/>;
    }

    valueOrEmptyString() {
        return !!this.props.field.value ? this.props.field.value : '';
    }

    variant() {
        return !!this.props.variant ? this.props.variant : 'standard';
    }
}

export default FolderField;
