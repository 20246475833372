import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import * as React from "react";
import ColorHash from "color-hash";

export default class UserAvatar extends React.Component {

    static colorHash = new ColorHash({lightness: 0.4});

    render() {
        let shortName = this.props.email[0];
        if (!!this.props.firstName && !!this.props.lastName) {
            shortName = this.props.firstName[0] + this.props.lastName[0];
        }
        return <Tooltip title={(this.props.tooltipPrefix || '') + this.props.email}>
            <Avatar className={this.classes()}
                    onClick={this.clicked}
                    style={{backgroundColor: UserAvatar.colorHash.hex(this.props.email)}}>
                {shortName.toUpperCase()}
            </Avatar>
        </Tooltip>;
    };

    classes = () => {
        return `user-avatar ${this.props.className || ''} ${!!this.props.onUserClick && 'clickable'}`;
    };

    clicked = () => {
        if (!!this.props.onUserClick) {
            this.props.onUserClick(this.props.email, this.props.firstName, this.props.lastName);
        }
    };
}
