import * as React from 'react';
import gql from "graphql-tag";
import {CircularProgress} from "@material-ui/core";
import FieldForm from "./FieldForm";
import {withRouter} from "react-router-dom";

class EditField extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            field: null,
            working: true,
        };
    };

    componentDidMount() {
        this.loadField();
    }

    render() {
        if (this.state.working) {
            return <CircularProgress/>
        }
        else {
            return <FieldForm field={this.state.field}
                              submitButtonText={'Save'}
                              save={this.update}
                              cancel={this.done}/>;
        }
    }

    done = () => {
        this.props.history.goBack();
    };

    loadField = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {
                    field(id: "${this.props.id}") { id name values }
                }`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    working: false,
                    field: results.data['field']
                });
            }).catch((reason) => {
                console.log(`Failed to load field with id ${this.props.id} because ${reason}`);
                this.setState({
                    working: false,
                    field: {
                        name: 'This field could not be found'
                    }
                });
            })
        );
    };

    update = (values) => {
        console.log(`Updating field with: ${JSON.stringify(values)}`);

        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation UpdateField($id: ID!, $input: UpdateField!) {
                    updateField(id: $id, input: $input)
                }`),
                variables: {
                    id: this.props.id,
                    input: {
                        name: values.name,
                        type: 'choice',
                        values: values.values
                    }
                }
            }).then(this.done)
        );
    };

    validate = (values) => {
        let errors = {};
        if (!values.name) {
            errors.name = 'Required';
        }
        return errors;
    };
}

export default withRouter(EditField);
