import React from 'react';
import Authentication from "./auth/Authentication";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from '@material-ui/styles';
import {createMuiTheme} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {BrowserRouter as Router} from "react-router-dom";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#ef465d',
        },
        secondary: grey,
    },
    direction: 'ltr',
});

function App() {
  return <Router>
      <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
              <div>
                <CssBaseline/>
                <Authentication/>
              </div>
          </MuiPickersUtilsProvider>
      </ThemeProvider>
  </Router>;
}

export default App;
