import * as React from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

class SignInForm extends React.Component {

    state = {
        username: this.props.username,
        password: ''
    };

    render() {
        return (
            <Box mt={2}>
                <form>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField name='username' value={this.state.username} label='Email' xs={12} autoFocus fullWidth onChange={this.updateUsername} onKeyPress={this.keyPress}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name='password' label='Password' type='password' xs={12} fullWidth onChange={this.updatePassword} onKeyPress={this.keyPress}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" xs={12} onClick={this.handleSignIn}>
                                Sign In
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" xs={12} onClick={this.props.forgotPassword} style={{float: 'right'}}>
                                Forgot Password
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        );
    }

    handleSignIn = (event) => {
        event.preventDefault();

        try {
            let username = this.state.username;
            let password = this.state.password;
            this.props.login(username, password);
        }
        catch (e) {
            this.props.error(e.message);
        }
    };

    keyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleSignIn(event);
        }
    };

    updatePassword = (event) => {
        this.setState({password: event.target.value});
    };

    updateUsername = (event) => {
        this.setState({username: event.target.value});
    };
}

export default SignInForm;
