import * as React from 'react';
import gql from "graphql-tag";
import {CircularProgress} from "@material-ui/core";
import StepForm from "./StepForm";
import {withRouter} from "react-router-dom";

class EditStep extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            step: null,
            working: true,
        };
    };

    componentDidMount() {
        this.loadStep();
    }

    render() {
        if (this.state.working) {
            return <CircularProgress/>
        }
        else {
            return <StepForm step={this.state.step}
                             submitButtonText={'Save'}
                             save={this.update}
                             cancel={this.done}/>;
        }
    }

    done = () => {
        this.props.history.goBack();
    };

    loadStep = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {
                    step(id: "${this.props.id}") { id name }
                }`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    working: false,
                    step: results.data['step']
                });
            }).catch((reason) => {
                console.log(`Failed to load step this id ${this.props.id} because ${reason}`);
                this.setState({
                    working: false,
                    step: {
                        name: 'This step could not be found'
                    }
                });
            })
        );
    };

    update = (values) => {
        console.log(`Updating step with: ${JSON.stringify(values)}`);

        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation UpdateStep($id: ID!, $input: UpdateStep!) {
                    updateStep(id: $id, input: $input)
                }`),
                variables: {
                    id: this.props.id,
                    input: {
                        name: values.name
                    }
                }
            }).then(this.done)
        );
    };

    validate = (values) => {
        let errors = {};
        if (!values.name) {
            errors.name = 'Required';
        }
        return errors;
    };
}

export default withRouter(EditStep);
