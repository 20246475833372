import React from 'react';
import {useDrop} from 'react-dnd';
import PlainLink from "../PlainLink";
import FolderIcon from "@material-ui/icons/Folder";
import Box from "@material-ui/core/Box";

const DroppableFolderLink = ({ folder, showParent = false }) => {
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'document',
        drop: () => ({ name: folder.id }),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const style = {};
    if (canDrop && isOver) {
        style.textDecoration = 'underline';
    }
    if (canDrop) {
        style.fontWeight = 'bold';
    }

    return <span ref={drop}>
        <PlainLink to={`/folders/${folder.id}`}
                   className={'folder-link vertically-centered'}
                   style={style}>
            <FolderIcon/>

            <Box component={'span'} ml={1}>
                {`${(!!folder.parent && showParent) ? `${folder.parent.description} / ` : ''}${folder.description}`}
            </Box>
        </PlainLink>
    </span>;
};
export default DroppableFolderLink;
