import * as React from 'react';
import DataTable from "../tables/DataTable";

class FolderSearchResult extends React.Component {

    render() {
        return <DataTable
            columns={this.props.columns}
            data={this.props.results.map((nextResult) => {
                return {
                    ...nextResult,
                    name: `${!!nextResult.parent ? `${nextResult.parent.description} / ` : ''}${nextResult.description}`
                }
            })}
            initialSort={this.props.initialSort}/>;
    }
}

export default FolderSearchResult;
