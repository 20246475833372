import * as React from 'react';
import gql from "graphql-tag";
import {CircularProgress, Box} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {CreateNewFolder} from "@material-ui/icons";
import AddFolderStepper from "./AddFolderStepper";
import AddFolder from "./AddFolder";
import Typography from "@material-ui/core/Typography";
import DataTable from "../tables/DataTable";

class ChooseTemplate extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            working: true,
            chosenTemplateId: null,
            templates: []
        };
    };

    componentDidMount() {
        this.loadTemplates();
    }

    render() {
        if (this.state.working) {
            return <CircularProgress/>;
        }
        else if (!!this.state.chosenTemplateId) {
            return <AddFolder apiClient={this.props.apiClient}
                              templateId={this.state.chosenTemplateId}
                              parentFolderId={this.props.parentFolderId}
                              onClose={() => this.setState({chosenTemplateId: null})}/>;
        }
        else {
            return <Box>
                <AddFolderStepper currentStep={0}/>

                <Box mb={2}>
                    <Typography variant={'body2'}>
                        Select the template that matches the type of folder you are creating.
                        Templates define the fields and workflow that will apply to your new folder.
                        You can create new templates from the "Templates" menu option.
                    </Typography>
                </Box>

                <DataTable
                    columns={[
                        {
                            Header: 'Name',
                            accessor: 'name'
                        },
                        {
                            Header: 'Category',
                            accessor: 'category'
                        },
                        {
                            Header: 'Description',
                            accessor: 'description'
                        },
                        {
                            Header: '',
                            id: 'actions',
                            width: 100,
                            Cell: ({ row }) =>
                                <Box className={'vertically-centered'}>
                                    <Tooltip title="Create folder from this template">
                                        <IconButton className={'new-folder-from-template'} onClick={() => {this.choose(row.original)}}>
                                            <CreateNewFolder/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                        },
                    ]}
                    data={this.state.templates}
                    initialSort={[{id: 'name'}]}/>
            </Box>;
        }
    }

    choose = (template) => {
        this.setState({chosenTemplateId: template.id});
    };

    loadTemplates = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {folderTemplates { id name category description }}`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    working: false,
                    templates: results.data['folderTemplates']
                });
            })
        );
    };
}

export default ChooseTemplate;
