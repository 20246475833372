import * as React from 'react';
import gql from "graphql-tag";
import UserForm from "./UserForm";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {withRouter} from "react-router-dom";

class AddUser extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    render() {
        return <Box>
            <Typography variant={'h4'}>
                New User
            </Typography>

            <UserForm user={{email: '', firstName: '', lastName: ''}}
                      submitButtonText={'Create'}
                      save={this.create}
                      cancel={this.done}/>
        </Box>;
    }

    create = (values, {setSubmitting}) => {
        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation CreateUser($email: AWSEmail!, $firstName: String!, $lastName: String!) {
                    createUser(email: $email, first_name: $firstName, last_name: $lastName)
                }`),
                variables: {
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName
                }
            }).then(this.done).finally((_) => {
                setSubmitting(false);
            })
        );
    };

    done = () => {
        this.props.history.goBack();
    };
}

export default withRouter(AddUser);
