import * as React from 'react';
import gql from "graphql-tag";
import StepForm from "./StepForm";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {withRouter} from "react-router-dom";

class AddStep extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
        };
    };

    render() {
        return <Box>
            <Typography variant={'h4'}>
                New Step
            </Typography>

            <StepForm step={{name: ''}}
                      submitButtonText={'Create'}
                      save={this.create}
                      cancel={this.done}/>
        </Box>;
    }

    create = (values, {setSubmitting}) => {
        console.log(`Creating step with: ${JSON.stringify(values)}`);

        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation CreateStep($input: UpdateStep!) {
                    createStep(input: $input)
                }`),
                variables: {
                    input: {
                        name: values.name
                    }
                }
            }).then((_) => {
                setSubmitting(false);
                this.done();
            })
        );
    };

    done = () => {
        this.props.history.goBack();
    };
}

export default withRouter(AddStep);
