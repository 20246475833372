import * as React from 'react';
import gql from "graphql-tag";
import {CircularProgress, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FolderTemplateForm from "./FolderTemplateForm";
import {withRouter} from "react-router-dom";

class AddFolderTemplate extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            working: true
        };
    };

    componentDidMount() {
        this.loadFields();
    }

    render() {
        if (this.state.working) {
            return <CircularProgress/>
        }
        else {
            return <Box>
                <Typography variant={'h4'}>
                    New Template
                </Typography>

                <FolderTemplateForm template={{name: '', category: '', description: '', fields: []}}
                                    fields={this.state.fields}
                                    submitButtonText={'Create'}
                                    save={this.create}
                                    cancel={this.done}/>
            </Box>;
        }
    }

    create = (values, {setSubmitting}) => {
        console.log(`Creating template with: ${JSON.stringify(values)}`);

        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation CreateFolderTemplate($input: UpdateFolderTemplate!) {
                    createFolderTemplate(input: $input)
                }`),
                variables: {
                    input: {
                        name: values.name,
                        category: values.category,
                        description: values.description,
                        fields: values.fields
                    }
                }
            }).then((_) => {
                setSubmitting(false);
                this.done();
            })
        );
    };

    done = () => {
        this.props.history.goBack();
    };

    loadFields = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {
                    fields { id name type }
                }`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    working: false,
                    fields: results.data['fields']
                });
            }).catch((reason) => {
                console.log(`Failed to load fields because ${reason}`);
                this.setState({
                    working: false,
                    fields: []
                });
            })
        );
    };
}

export default withRouter(AddFolderTemplate);
