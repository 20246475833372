import * as React from 'react';
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";

export default class PlainLink extends React.Component {

    render() {
        return <Link component={this.link()} {...this.props}>
            {this.props.children}
        </Link>;
    }

    link = () => {
        return React.forwardRef((itemProps, ref) => (
            <RouterLink {...itemProps} innerRef={ref} />
        ));
    };
}
