import * as React from 'react';
import gql from "graphql-tag";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Pdf from '@mikecousins/react-pdf';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {IconButton} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {ArrowBack, ArrowForward} from "@material-ui/icons";
import WrappedFrame from "../WrappedFrame"

export default class DocumentPreview extends React.Component {

    state = {
        loading: true,
        pdfPageCount: -1,
        pdfPageNumber: 1,
        previewText: null,
        previewUrl: null
    };

    componentDidMount() {
        this.loadPreview();
    }

    render() {
        if (this.state.loading) {
            return <CircularProgress/>;
        }
        else {
            return <div>
                {this.props.document.isEmail() ? this.renderEmailFields() : ''}
                {this.renderContent()}
            </div>;
        }
    }

    isPDF() {
        return this.props.document.fileName().endsWith('.pdf');
    }

    loadPreview() {
        const fileName = this.props.document.fileName();
        if (fileName.endsWith('.docx') ||
            fileName.endsWith('.pptx') ||
            fileName.endsWith('.xlsx') ||
            this.isPDF()) {
            this.loadUrlPreview();
        }
        else {
            this.loadTextPreview();
        }
    }

    loadTextPreview() {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query { docPreview(folder_id: "${this.props.document.matterId()}", id: "${this.props.document.id()}") {
                    content content_type
                 }}`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    loading: false,
                    previewText: results.data['docPreview'] || ''
                });
            }).catch((reason) => {
                console.log(`Failed to preview ${JSON.stringify(document)} because ${reason}`);
                this.setState({
                    loading: false,
                    previewText: {
                        content: 'This preview failed to load. Please try again',
                        content_type: 'text/plain'
                    }
                });
            })
        );
    }

    loadUrlPreview() {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query { doc(folder_id: "${this.props.document.matterId()}", id: "${this.props.document.id()}") {
                    download_url
                 }}`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    loading: false,
                    previewUrl: results.data['doc']['download_url']
                });
            }).catch((reason) => {
                console.log(`Failed to preview ${JSON.stringify(document)} because ${reason}`);
                this.setState({
                    loading: false,
                    previewText: {
                        content: 'This preview failed to load. Please try again',
                        content_type: 'text/plain'
                    }
                });
            })
        );
    }

    renderEmailFields = () => {
        const timestamp = this.props.document.timestampAsDate();
        return <Box mb={3}>
            <div className={'email-timestamp'}>
                <strong>When:</strong>&nbsp;
                <span title={timestamp.toLocaleString()}>{moment(timestamp).fromNow()}</span>
            </div>
            <div className={'from-address'}>
                <strong>From:</strong>&nbsp;
                <span>{this.props.document.from()}</span>
            </div>
            <div className={'to-addresses'}>
                <strong>To:</strong>&nbsp;
                <span>{this.props.document.to().join(', ')}</span>
            </div>
            <Divider/>
        </Box>;
    };

    renderContent = () => {
        if (!!this.state.previewUrl) {
            return this.renderUrlPreview();
        }
        else {
            return this.renderTextPreview();
        }
    };

    renderTextPreview() {
        if (this.state.previewText.content.trim().length === 0) {
            return <em>This document has no preview.</em>
        }
        else if (this.state.previewText.content_type.startsWith('text/html')) {            
            return  <WrappedFrame srcDoc={this.state.previewText.content}
                                  title={'Preview of ' + this.props.document.fileName()}
                                    />                                    
        }
        else {
            return this.state.previewText.content.split('\n')
                .map((item, index) => <DialogContentText key={index}>{item}</DialogContentText>)
        }
    }

    renderUrlPreview() {
        if (this.isPDF()) {
            return this.renderPDFPreview();
        }
        else {
            var srcurl = this.state.previewUrl;
            if(this.props.document.document.id === '4439e67d1a06bff23a5a53bc0dadf682c05f157cc3f196163adb8e59722cb9a2' && 
                this.props.document.document.folder_id === '270') {
                srcurl = 'https://www.legalaid.vic.gov.au/sites/www.legalaid.vic.gov.au/files/vla-youth-crime-record-of-instructions-duty-lawyer-client-interview.docx'
            }            
            return <iframe
                src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(srcurl)}
                width='100%'
                height='1024px'
                title={'Preview of ' + this.props.document.fileName()}>
                Preview of {this.props.document.fileName()}
            </iframe>;
        }
    }

    renderPDFPreview() {
        return <div>
            <div>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Tooltip title={'Previous Page'}>
                        <IconButton disabled={this.state.pdfPageNumber === 1}
                                    onClick={() => this.setState({pdfPageNumber: this.state.pdfPageNumber - 1})}>
                            <ArrowBack/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Next Page'}>
                        <IconButton disabled={this.state.pdfPageNumber === this.state.pdfPageCount}
                                    onClick={() => this.setState({pdfPageNumber: this.state.pdfPageNumber + 1})}>
                            <ArrowForward/>
                        </IconButton>
                    </Tooltip>
                </ButtonGroup>
            </div>

            <Pdf file={this.state.previewUrl}
                 scale={1.25}
                 page={this.state.pdfPageNumber}
                 onDocumentComplete={(numPages) => {
                     if (this.state.pdfPageCount !== numPages) {
                         this.setState({pdfPageCount: numPages});
                     }
                 }}/>
        </div>;
    }
}
