import * as React from 'react';
import gql from "graphql-tag";
import {CircularProgress, Tooltip} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DocumentLink from "./DocumentLink";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import FolderIcon from '@material-ui/icons/Folder';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import Done from '@material-ui/icons/Done';
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import IconButton from "@material-ui/core/IconButton";
import AccessDeniedMessage from "../auth/AccessDeniedMessage";
import {UserContext} from "../auth/UserContext";
import FormHelperText from "@material-ui/core/FormHelperText";
import Equals from "array-equal";

export default class EditPermissions extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {},
        finished: () => {},
        document: null,
        folder: null
    };

    constructor(props) {
        super(props);

        this.state = {
            accessDenied: false,
            editing: false,
            loading: true,
            permissions: [],
            parentPermissions: [],
            currentPermissionIndex: null,
            users: [],
            availableUsers: [],
            availableGroups: [],
        };
    };

    componentDidMount() {
        if (this.isFolder()) {
            this.loadFolder();
        }
        if (this.isDocument()) {
            this.loadDocument();
        }
    }

    render() {
        if (this.state.loading) {
            return <CircularProgress/>
        }
        else if (this.state.accessDenied) {
            return <AccessDeniedMessage close={this.props.finished}/>;
        }
        else {
            return <Box>
                <Typography variant={'h4'}>Editing Permissions</Typography>

                <Box style={{display: 'flex', justifyContent: 'space-between'}} mt={1}>
                    {
                        this.isDocument() &&
                        <DocumentLink apiClient={this.props.apiClient} document={this.props.document}/>
                    }
                    {
                        this.isFolder() &&
                        <Typography variant={'h5'} className={'vertically-centered'}>
                            <FolderIcon/> <Box component={'span'} ml={1}>{this.props.folder.description}</Box>
                        </Typography>
                    }

                    {
                        !this.state.editing &&
                        <Box>
                            {
                                (this.state.availableUsers.length > 0 || this.state.availableGroups.length > 0) &&
                                <Tooltip title={'Add permissions for a particular user or group'}>
                                    <IconButton onClick={this.addPermission} id={'addPermission'}>
                                        <AddIcon/>
                                    </IconButton>
                                </Tooltip>
                            }
                            <Fab color='primary' onClick={this.props.finished}>
                                <Tooltip title="Finish editing permissions">
                                    <Done/>
                                </Tooltip>
                            </Fab>
                        </Box>
                    }
                </Box>

                {this.renderPermissions()}
            </Box>;
        }
    }

    accessAllowed = (apiResponse) => {
        if (!!apiResponse.graphQLErrors && !!apiResponse.graphQLErrors.find((nextError) => {
                return nextError.errorType === 'Unauthorized'})) {
            this.setState({loading: false, accessDenied: true});
            return false;
        }
        else {
            return true;
        }
    };

    addPermission = () => {
        const newPermissions = [...this.state.permissions];
        if (this.state.availableUsers.length > 0) {
            const user = this.state.availableUsers[0];
            newPermissions.unshift({
                id: user.username,
                name: this.userFullName(user),
                allowed_to: [],
                user: user
            });
        }
        else {
            const group = this.state.availableGroups[0];
            newPermissions.unshift({
                id: group.id,
                name: group.name,
                allowed_to: []
            });
        }
        this.setState({
            editing: true,
            permissions: newPermissions,
            currentPermissionIndex: 0}, this.updateAvailableUsers);
    };

    allowNewPermissions = (currentUser, permissions) => {
        return permissions.length === 0 ||
            this.userHasPermissions(currentUser, permissions) ||
            this.hasGroupPermissions(permissions) /* Permissions must have come from group */ ||
            window.confirm('You are removing your access to this object. Are you sure you want to proceed?');
    };

    deletePermissions = (currentUser, index) => {
        const newPermissions = [...this.state.permissions];
        newPermissions.splice(index, 1);

        if (this.allowNewPermissions(currentUser, newPermissions)) {
            this.setState({permissions: newPermissions}, () => {
                this.savePermissions(currentUser);
                this.updateAvailableUsers();
            });
        }
    };

    fromApiPermissions = (users, groups) => {
        return users.map((user) => {
                return {
                    ...user,
                    name: this.userFullName(user.user)
                };
            }).concat(
                groups.map((group) => {
                    return {
                        ...group,
                        name: group.id
                    };
                })
            ).sort((permission0, permission1) => {
                return permission0.name.localeCompare(permission1.name);
            });
    };

    hasGroupPermissions = (permissions) => {
        return !!(permissions.concat(this.state.parentPermissions)).find((nextPermission) => {
            return !this.isUserPermission(nextPermission);
        });
    };

    hasPermissionFor = (id) => {
        return !!this.state.permissions.find((nextPermission) => {
            return nextPermission.id === id;
        });
    };

    helpTextForType = (type) => {
        switch (type) {
            case 'view':
                return 'User can view';
            case 'edit':
                return 'User can edit content and metadata';
            case 'delete':
                return 'User can delete';
            case 'file':
                return 'User can file documents and emails to this folder';
            case 'permissions':
                return 'User can view and modify permissions';
            default:
                return '';
        }
    };

    iconForPermission = (permission) => {
        if (this.isUserPermission(permission)) {
            return <PersonIcon/>;
        }
        else {
            return <GroupIcon/>;
        }
    };

    isDocument = () => {
        return !!this.props.document;
    };

    isFolder = () => {
        return !!this.props.folder;
    };

    isUserPermission = (permission) => {
        return !!permission.user;
    };

    loadDocument = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query DocPermission($folder_id: ID!, $id: ID!) {
                    docPermission(folder_id: $folder_id, id: $id) { 
                        ${this.permissionFields()}
                    } 
                }`),
                variables: {
                    id: this.props.document.id(),
                    folder_id: this.props.document.matterId()
                },
                fetchPolicy: 'no-cache'
            }).then((results) => {
                const permissions = results.data['docPermission'];
                this.setState({
                    permissions: this.fromApiPermissions(permissions.users, permissions.groups),
                    parentPermissions: this.fromApiPermissions(permissions.parent_users, permissions.parent_groups)
                }, this.loadUsersAndGroups);
            }).catch((error) => {
                if (this.accessAllowed(error)) {
                    console.log(`Got unexpected error: ${JSON.stringify(error)}`);
                }
            })
        );
    };

    loadFolder = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query FolderPermission($id: ID!) {
                    folderPermission(id: $id) { 
                        ${this.permissionFields()}
                    } 
                }`),
                variables: {id: this.props.folder.id},
                fetchPolicy: 'no-cache'
            }).then((results) => {
                const permissions = results.data['folderPermission'];
                this.setState({
                    permissions: this.fromApiPermissions(permissions.users, permissions.groups),
                    parentPermissions: this.fromApiPermissions(permissions.parent_users, permissions.parent_groups)
                }, this.loadUsersAndGroups);
            }).catch((error) => {
                if (this.accessAllowed(error)) {
                    console.log(`Got unexpected error: ${JSON.stringify(error)}`);
                }
            })
        );
    };

    loadUsersAndGroups = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {
                    users { id username first_name last_name email } 
                    groups { id name } 
                }`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    loading: false,
                    groups: results.data['groups'].sort((group0, group1) => {
                        return group0.name.localeCompare(group1.name);
                    }),
                    users: results.data['users'].sort((user0, user1) => {
                        return this.userFullName(user0).localeCompare(this.userFullName(user1));
                    })
                }, this.updateAvailableUsers);
            })
        );
    };

    permissionFields = () => {
        return `
            users {
                id
                allowed_to
                user
                {
                    id
                    username
                    first_name
                    last_name
                    email
                }
            }
            groups
            {
                id
                allowed_to
            }
            parent_users {
                id
                allowed_to
                user
                {
                    id
                    username
                    first_name
                    last_name
                    email
                }
            }
            parent_groups
            {
                id
                allowed_to
            }            
        `;
    };

    permissionSelectionChanged = (index) => {
        if (this.state.currentPermissionIndex === index) {
            this.setState({currentPermissionIndex: null});
        }
        else {
            this.setState({currentPermissionIndex: index});
        }
    };

    permissionTypeChanged = (permissionIndex, permissionType) => {
        const newPermissions = [...this.state.permissions];
        if (newPermissions[permissionIndex].allowed_to.includes(permissionType)) {
            newPermissions[permissionIndex].allowed_to.splice(
                newPermissions[permissionIndex].allowed_to.indexOf(permissionType), 1);
        }
        else {
            newPermissions[permissionIndex].allowed_to.push(permissionType);
        }
        this.setState({permissions: newPermissions});
    };

    permissionTypes = () => {
        if (this.isDocument()) {
            return ['view', 'edit', 'delete', 'permissions'];
        }
        else {
            return ['view', 'edit', 'delete', 'file', 'permissions'];
        }
    };

    permissionSubjectChanged = (permissionIndex, id) => {
        const newPermissions = [...this.state.permissions];
        newPermissions[permissionIndex].id = id;
        if (!!this.userWithId(id)) {
            newPermissions[permissionIndex].user = this.userWithId(id);
            newPermissions[permissionIndex].name = this.userFullName(newPermissions[permissionIndex].user);
        }
        else {
            newPermissions[permissionIndex].user = undefined;
            newPermissions[permissionIndex].name = id;
        }
        this.setState({permissions: newPermissions});
    };

    selectAllPermissions = (permissionIndex, selectAll) => {      
       const newPermissions = [...this.state.permissions];
       if (selectAll) {
           newPermissions[permissionIndex].allowed_to = [...this.permissionTypes()]
        }
       else {
           newPermissions[permissionIndex].allowed_to = [];
       }              
       newPermissions[permissionIndex].isSelectAllChecked = selectAll;
       this.setState({permissions: newPermissions});
    };

    renderPermissions() {
        return <Box mt={1}>
            {
                this.state.permissions.length === 0 &&
                this.state.parentPermissions.length === 0 &&
                this.renderPublicPermissionsMessage()
            }

            <UserContext.Consumer>
                {currentUser =>
                    this.state.permissions.map((nextPermissions, index) => {                                        
                        nextPermissions.isSelectAllChecked = Equals(nextPermissions.allowed_to.sort(),this.permissionTypes().sort())                        
                        
                        return <ExpansionPanel key={index} expanded={this.state.currentPermissionIndex === index}
                                               onChange={() => this.permissionSelectionChanged(index)}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                                {this.renderPermissionsSummary(nextPermissions)}
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Box>
                                    <Select value={nextPermissions.id}
                                            onChange={(event) => this.permissionSubjectChanged(index, event.target.value)}>
                                        {
                                            this.optionsForPermission(nextPermissions).map((nextOption, optionIndex) => {
                                                return <MenuItem key={optionIndex} value={nextOption.value}>
                                                    {nextOption.icon()}
                                                    <Box ml={1} component={'span'}
                                                         className={'permissionSubjectName'}>{nextOption.name}</Box>
                                                </MenuItem>;
                                            })
                                        }
                                    </Select>
                                </Box>

                                <Box ml={3}>
                                    <FormGroup>
                                        <Box>
                                            <FormControlLabel control={<Checkbox                                                                           
                                                                    checked={nextPermissions.isSelectAllChecked}
                                                                    onChange={() => this.selectAllPermissions(index, !nextPermissions.isSelectAllChecked)}
                                                                    value='select-all'/>} 
                                                                label="Select all"/>
                                        </Box>
                                        {  
                                            this.permissionTypes().map((nextType) => {
                                                return <Box key={nextType}>
                                                    <FormControlLabel control={<Checkbox
                                                                          checked={nextPermissions.allowed_to.includes(nextType)}
                                                                          onChange={() => this.permissionTypeChanged(index, nextType)}
                                                                          value={nextType}/>}
                                                                      label={nextType}/>
                                                    <FormHelperText>{this.helpTextForType(nextType)}</FormHelperText>
                                                </Box>;
                                            })
                                        }
                                    </FormGroup>
                                </Box>
                            </ExpansionPanelDetails>
                            <ExpansionPanelActions>
                                <Button size="small" color="primary" className={'save-permissions'}
                                        onClick={() => this.savePermissions(currentUser)}>
                                    Save
                                </Button>
                                <Button size="small" className={'delete-permissions'}
                                        onClick={() => this.deletePermissions(currentUser, index)}>
                                    Delete
                                </Button>
                            </ExpansionPanelActions>
                        </ExpansionPanel>;
                    })
                }
            </UserContext.Consumer>

            {
                this.state.parentPermissions.map((nextPermissions, index) => {
                    return <ExpansionPanel key={index} expanded={false} {...(this.hasPermissionFor(nextPermissions.id) && {disabled: true})}>
                        <ExpansionPanelSummary style={{cursor: 'default'}}>
                            <Tooltip title="These permissions are inherited from the folder">
                                <FolderIcon style={{alignSelf: 'center'}}/>
                            </Tooltip>
                            <Box ml={1}>{this.renderPermissionsSummary(nextPermissions)}</Box>
                        </ExpansionPanelSummary>
                    </ExpansionPanel>;
                })
            }
        </Box>;
    }

    optionsForPermission(permission) {
        const users = [...this.state.availableUsers];
        const groups = [...this.state.availableGroups];
        if (this.isUserPermission(permission)) {
            users.unshift(permission.user);
        }
        else {
            groups.unshift({
                id: permission.id,
                name: permission.id
            })
        }

        return users.map((nextUser) => {
                return {
                    icon: () => {return <PersonIcon/>;},
                    name: this.userFullName(nextUser),
                    value: nextUser.username,
                };
            })
            .concat(groups.map((nextGroup) => {
                return {
                    icon: () => {return <GroupIcon/>;},
                    name: nextGroup.name,
                    value: nextGroup.id
                }
            }));
    }

    renderPermissionsSummary(nextPermissions) {
        return <Typography variant={'h6'} className={'vertically-centered'}>
            {this.iconForPermission(nextPermissions)}
            <Box component={'span'} className={'permissionSubjectName'} ml={1}>{nextPermissions.name}</Box>
            <Typography variant={'body1'} component={'span'}>&nbsp;- {nextPermissions.allowed_to.length === 0 ? 'Cannot access' : nextPermissions.allowed_to.join(', ')}</Typography>
        </Typography>;
    }

    renderPublicPermissionsMessage() {
        return <Paper className={'vertically-centered'}>
            <Box p={1} component={'span'}><InfoIcon color={'secondary'}/></Box>
            <Box p={1} component={'span'}><Typography
                variant={'body1'}>This {this.isDocument() ? 'document' : 'folder'} can be accessed by
                anyone.</Typography></Box>
        </Paper>;
    }

    savePermissions = (currentUser) => {
        if (this.allowNewPermissions(currentUser, this.state.permissions)) {
            if (this.isDocument()) {
                this.saveDocPermissions();
            }
            else {
                this.saveFolderPermissions();
            }
        }
    };

    saveDocPermissions = () => {
        this.setState({loading: true}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.mutate({
                    mutation: gql(`mutation UpdatePermissions($folder_id: ID!, $id: ID!, $permissions: UpdatePermissions!) {
                        updateDocPermission(folder_id: $folder_id, id: $id, permissions: $permissions)
                    }`),
                    variables: {
                        id: this.props.document.id(),
                        folder_id: this.props.document.matterId(),
                        permissions: this.toApiPermissions()
                    }
                }).then(() => {
                    this.setState({currentPermissionIndex: null, editing: false,});
                }).then(this.loadDocument)
            );
        });
    };

    saveFolderPermissions = () => {
        this.setState({loading: true}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.mutate({
                    mutation: gql(`mutation UpdatePermissions($id: ID!, $permissions: UpdatePermissions!) {
                        updateFolderPermission(id: $id, permissions: $permissions)
                    }`),
                    variables: {
                        id: this.props.folder.id,
                        permissions: this.toApiPermissions()
                    }
                }).then(() => {
                    this.setState({currentPermissionIndex: null, editing: false,});
                }).then(this.loadFolder)
            );
        });
    };

    toApiPermissions = () => {
        const apiPermissions = {
            groups: [],
            users: []
        };

        this.state.permissions.forEach((nextPermission) => {
            const apiPermission = {
                id: nextPermission.id,
                allowed_to: nextPermission.allowed_to
            };
            if (this.isUserPermission(nextPermission)) {
                apiPermissions.users.push(apiPermission);
            }
            else {
                apiPermissions.groups.push(apiPermission);
            }
        });

        return apiPermissions;
    };

    updateAvailableUsers = () => {
        this.setState({
            availableUsers: [...this.state.users]
                .filter((nextUser) => {
                    return !this.state.permissions.find((nextPermission) => {
                        return this.isUserPermission(nextPermission) && nextPermission.user.username === nextUser.username;
                    });
                }),
            availableGroups: [...this.state.groups]
                .filter((nextGroup) => {
                    return !this.state.permissions.find((nextPermission) => {
                        return !this.isUserPermission(nextPermission) && nextPermission.id === nextGroup.id;
                    });
                })
        });
    };

    userFullName = (user) => {
        if (!!user.last_name && !!user.first_name) {
            return `${user.last_name}, ${user.first_name}`;
        }
        else {
            return user.email;
        }
    };

    userHasPermissions = (user, permissions) => {
        return !!(permissions.concat(this.state.parentPermissions)).find((nextPermission) => {
            return user.username === nextPermission.id;
        });
    };

    userWithId = (id) => {
        return this.state.users.find((nextUser) => { return nextUser.username === id;});
    }

}
