import * as React from 'react';
import Box from "@material-ui/core/Box";
import {
    Lock,
    MonetizationOn,
    AccessibilityNew,
    FormatListNumbered,
    Storage,
    FindInPage
} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

export default class Sales extends React.Component {

    render() {
        return <Box mt={5}>
            <Grid container className={'sales'} spacing={5}>
                <Grid item md={12} lg={4} className={'horizontally-centered'}>
                    <img src={'/assets/sales/fyler_logo.png'} className={'hero'} alt={'Fyler'} title={'Fyler'} width={384} height={516}/>
                </Grid>
                <Grid item md={12} lg={8}>
                    <Grid container spacing={5} style={{paddingBottom: '64px'}}>
                        <Grid item xs={12}>
                            <Box mb={5}>
                                <Typography variant={'h3'}>
                                    Intuitive Document & Email management<br/>for your entire business
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" className={'vertically-centered'}>
                                        <FindInPage fontSize={'large'} color={'primary'}/>
                                        <Box component={'span'} ml={1}>Powerful Search</Box>
                                    </Typography>
                                    <Box mt={2}>
                                        <List component="nav">
                                            <ListItem>
                                                <ListItemText primary={'Find documents and emails with ease using lightning fast, full text search.'}/>
                                            </ListItem>
                                        </List>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" className={'vertically-centered'}>
                                        <Storage fontSize={'large'} color={'primary'}/>
                                        <Box component={'span'} ml={1}>Unlimited Storage</Box>
                                    </Typography>
                                    <Box mt={2}>
                                        <List component="nav">
                                            <ListItem>
                                                <ListItemText primary={'Quickly save documents and emails into secure, reliable, and unlimited storage.'}/>
                                            </ListItem>
                                        </List>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" className={'vertically-centered'}>
                                        <Lock fontSize={'large'} color={'primary'}/>
                                        <Box component={'span'} ml={1}>Intuitive Security</Box>
                                    </Typography>
                                    <Box mt={2}>
                                        <List component="nav">
                                            <ListItem>
                                                <ListItemText primary={'Manage access to folders, documents and emails with powerful security controls.'}/>
                                            </ListItem>
                                        </List>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" className={'vertically-centered'}>
                                        <FormatListNumbered fontSize={'large'} color={'primary'}/>
                                        <Box component={'span'} ml={1}>Flexible Workflows</Box>
                                    </Typography>
                                    <Box mt={2}>
                                        <List component="nav">
                                            <ListItem>
                                                <ListItemText primary={'Streamline document handling and governance of internal processes with rich workflows.'}/>
                                            </ListItem>
                                        </List>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" className={'vertically-centered'}>
                                        <MonetizationOn fontSize={'large'} color={'primary'}/>
                                        <Box component={'span'} ml={1}>Easy Deployment</Box>
                                    </Typography>
                                    <Box mt={2}>
                                        <List component="nav">
                                            <ListItem>
                                                <ListItemText primary={'Pain-free deployment with minimal IT burden.'}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={'Pricing options which work for any sized business.'}/>
                                            </ListItem>
                                        </List>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" className={'vertically-centered'}>
                                        <AccessibilityNew fontSize={'large'} color={'primary'}/>
                                        <Box component={'span'} ml={1}>Access Anywhere</Box>
                                    </Typography>
                                    <Box mt={2}>
                                        <List component="nav">
                                            <ListItem>
                                                <ListItemText primary={'Use Fyler from the Web or in Microsoft Outlook and Word.'}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={'Native on Windows, Mac and mobile devices.'}/>
                                            </ListItem>
                                        </List>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <AppBar position="fixed" color="secondary" style={{top: 'auto', bottom: 0}}>
                <Toolbar>
                    <Box component={'span'} style={{marginLeft: 'auto'}} className={'vertically-centered'}>
                        <Box component={'span'} ml={1} mr={2}>
                            <Link href={'mailto:sales@fyler.app'}>
                                <Typography>sales@fyler.app</Typography>
                            </Link>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>;
    }
}
