import * as React from 'react';
import gql from "graphql-tag";
import {CircularProgress} from "@material-ui/core";
import FolderForm from "./FolderForm";
import { withRouter } from 'react-router-dom';

class EditFolder extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            working: true,
        };
    };

    componentDidMount() {
        this.loadFolder();
    }

    render() {
        if (this.state.working) {
            return <CircularProgress/>
        }
        else {
            return <FolderForm apiClient={this.props.apiClient}
                               asyncCallMade={this.props.asyncCallMade}
                               folder={this.state.folder}
                               submitButtonText={'Save'}
                               save={this.update}
                               cancel={this.done}/>;
        }
    }

    done = () => {
        this.props.history.goBack();
    };

    loadFolder = () => {
        this.setState({working: true, folder: null}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query({
                    query: gql(`query {
                        folder(folder_id: "${this.props.id}") { id parent_id description type fields { name type value } }
                    }`),
                    fetchPolicy: 'no-cache'
                }).then((results) => {
                    this.setState({
                        working: false,
                        folder: results.data['folder']
                    });
                }).catch((reason) => {
                    console.log(`Failed to load folder this id ${this.props.id} because ${reason}`);
                    this.setState({
                        working: false,
                        folder: {
                            description: 'This folder could not be found',
                            fields: [],
                            docs: []
                        }
                    });
                })
            );
        });
    };

    update = (values) => {
        console.log(`Updating folder with: ${JSON.stringify(values)}`);

        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation UpdateFolder($id: ID!, $input: FolderInput!) {
                    updateFolder(id: $id, input: $input)
                }`),
                variables: {
                    id: this.props.id,
                    input: {
                        parent_id: this.state.folder.parent_id,
                        description: values.description,
                        type: this.state.folder.type,
                        fields: values.fields.map((nextField) => {
                            return {name: nextField.name, type: nextField.type, value: nextField.value};
                        })
                    }
                }
            }).then(this.done)
        );
    };
}

export default withRouter(EditFolder);
