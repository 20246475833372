import * as React from 'react';
import {Box} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

class AddFolderStepper extends React.Component {

    render() {
        return <Box mb={2}>
            <Stepper activeStep={this.props.currentStep}>
                <Step completed={this.props.currentStep > 0}><StepLabel>Choose template</StepLabel></Step>
                <Step completed={this.props.currentStep > 1}><StepLabel>Enter details</StepLabel></Step>
                <Step completed={this.props.currentStep > 2}><StepLabel>Add permissions</StepLabel></Step>
            </Stepper>
        </Box>;
    }
}

export default AddFolderStepper;
