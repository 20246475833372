import * as React from 'react';
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

class UserForm extends React.Component {

    render() {
        return <Formik
            initialValues={this.props.user}
            validate={this.validate}
            onSubmit={this.props.save}>
            {
                ({ values, isSubmitting }) => {
                    return <Form style={{width: '50%'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field name='email' label={'Email Address'} component={TextField} value={values.email} fullWidth required/>
                            </Grid>
                            <Grid item xs={12}>
                                <Field name='firstName' label={'First Name'} component={TextField} value={values.firstName} fullWidth required/>
                            </Grid>
                            <Grid item xs={12}>
                                <Field name='lastName' label={'Last Name'} component={TextField} value={values.lastName} fullWidth/>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='contained' color='primary' disabled={isSubmitting} type={'submit'}>
                                    {this.props.submitButtonText}
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='contained' color='secondary' disabled={isSubmitting} onClick={this.props.cancel}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                }
            }
        </Formik>;
    };

    validate = (values) => {
        let errors = {};
        if (!values.email) {
            errors.email = 'Required';
        }
        if (!values.firstName) {
            errors.firstName = 'Required';
        }
        if (!values.lastName) {
            errors.lastName = 'Required';
        }
        return errors;
    };
}

export default UserForm;
