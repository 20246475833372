import * as React from 'react';
import moment from "moment";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import PlainLink from "../PlainLink";
import FolderIcon from "@material-ui/icons/Folder";

export default class FilingLocation extends React.Component {

    render() {
        const filedAt = moment(this.props.item.timestampAsDate());
        return <Box component={'span'} className={'vertically-centered'}>
            <PlainLink to={`/folders/${this.props.item.folderId()}`} className={'folder-link vertically-centered'} style={{marginRight: '0.5rem'}}>
                <Tooltip title={'Click to open folder'}>
                    <FolderIcon className={'clickable folder'} color="secondary"/>
                </Tooltip>
            </PlainLink>

            <Tooltip title={`Filed ${filedAt.format('DD-MM-YYYY HH:mm')}`}>
                <span>{filedAt.fromNow()}</span>
            </Tooltip>
        </Box>;
    }

}
