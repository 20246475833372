import React from 'react'

import clsx from 'clsx'
import DataTableFilter from './DataTableFilter'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Grid from "@material-ui/core/Grid";

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

export default function DataTableToolbar(props) {
    const classes = useToolbarStyles();
    const {
        numSelected,
        preGlobalFilteredRows,
        setGlobalFilter,
        globalFilter,
        title,
        selectionActions,
        globalActions
    } = props;
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            <Grid container>
                <Grid item xs={9} className={'vertically-centered'}>
                    {numSelected > 0 ? (
                        <Typography
                            className={classes.title}
                            color="inherit"
                            variant="subtitle1">
                            {numSelected} row(s) selected
                        </Typography>
                    ) : (
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            {title}
                        </Typography>
                    )}
                </Grid>

                {
                    numSelected === 0 && !!preGlobalFilteredRows &&
                        <Grid item xs={2} className={'vertically-centered horizontally-right'}>
                            <DataTableFilter preGlobalFilteredRows={preGlobalFilteredRows}
                                             globalFilter={globalFilter}
                                             setGlobalFilter={setGlobalFilter}/>
                        </Grid>
                }

                {
                    numSelected > 0 &&
                        <Grid item xs className={'vertically-centered horizontally-right'}>
                            {selectionActions}
                        </Grid>
                }

                {
                    numSelected === 0 && !!globalActions &&
                        <Grid item xs className={'vertically-centered horizontally-right'}>
                            {globalActions}
                        </Grid>
                }
            </Grid>
        </Toolbar>
    )
};
