import * as React from 'react';
import gql from "graphql-tag";
import GroupForm from "./GroupForm";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {withRouter} from "react-router-dom";

class AddGroup extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    render() {
        return <Box>
            <Typography variant={'h4'}>
                New Group
            </Typography>

            <GroupForm group={{name: ''}}
                      submitButtonText={'Create'}
                      save={this.create}
                      cancel={this.done}/>
        </Box>;
    }

    create = (values, {setSubmitting}) => {
        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation CreateGroup($name: String!) {
                    createGroup(name: $name)
                }`),
                variables: {
                    name: values.name
                }
            }).then(this.done).finally((_) => {
                setSubmitting(false);
            })
        );
    };

    done = () => {
        this.props.history.goBack();
    };
}

export default withRouter(AddGroup);
