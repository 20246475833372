import * as React from 'react';
import {DocumentDownloader, FylerUtils} from "@verlata/fyler-office-common";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {ArrowRightAlt, Clear, Done, Edit} from "@material-ui/icons";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import gql from "graphql-tag";
import Grid from "@material-ui/core/Grid";
import UserAvatar from "../UserAvatar";
import PlainLink from "../PlainLink";
import FolderIcon from "@material-ui/icons/Folder";

export default class DocumentLink extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {},
        showFilingLocation: true,
        dragging: false
    };

    state = {
        editingDocumentName: false,
        showAllRecipients: false,
        working: false,
    };

    render() {
        if (this.state.working) {
            return <CircularProgress/>;
        }
        else if (this.props.document.isEmail()) {
            return this.renderEmail();
        }
        else {
            return this.renderDocument();
        }
    }

    renderEmail() {
        const recipients = this.props.document.to().map((nextTo, index) =>
                <UserAvatar key={`to-${index}`} email={nextTo} tooltipPrefix={'to: '} className={'email-user-avatar user-avatar-collection'} onUserClick={this.props.onUserClick}/>)
            .concat(this.props.document.cc().map((nextCc, index) =>
                <UserAvatar key={`cc-${index}`} email={nextCc} tooltipPrefix={'cc: '} className={'email-user-avatar user-avatar-collection'} onUserClick={this.props.onUserClick}/>));

        return this.container(<div>
            <div ref={this.props.dragRef} className={'vertically-centered'}>
                <Tooltip title='Click to download'>
                    <Link href={'#'} onClick={this.download} style={{display: 'inline-flex', alignItems: 'center'}}>
                        {this.props.document.icon()}
                        <Box component={'span'} ml={1}>
                            {this.props.document.getMetadataValue('subject')}
                        </Box>
                    </Link>
                </Tooltip>
                <Box component={'span'} ml={2}>
                    <UserAvatar email={this.props.document.from()} tooltipPrefix={'from: '}
                                className={'email-user-avatar'} onUserClick={this.props.onUserClick}/>
                </Box>
                <ArrowRightAlt/>
                {recipients.slice(0, Math.min(recipients.length, this.maxRecipients()))}
                {
                    (recipients.length > this.maxRecipients()) &&
                    <Box ml={1} component={'span'} style={{fontStyle: 'italic'}}>
                        <Link href='#' onClick={() => this.setState({showAllRecipients: true})} variant='caption'>
                            Show {recipients.length - this.maxRecipients()} more...
                        </Link>
                    </Box>
                }
            </div>

            {this.snippet()}
            <Typography variant="caption" className={'vertically-centered'}>
                {this.renderSentDate()} {this.renderFilingIcon()} {this.renderFilingName()}
            </Typography>
        </div>);
    }

    renderDocument() {
        return this.container(<div>
            {
                this.state.editingDocumentName ?
                    <div>
                        <Formik
                            initialValues={{name: this.props.document.fileName()}}
                            validate={this.validate}
                            onSubmit={this.renameDocument}
                            render={({values, isSubmitting}) => (
                                <Form>
                                    <Grid container>
                                        <Grid item xs={10} className={'vertically-centered'}>
                                            <Field name='name' component={TextField} value={values.name} autoFocus fullWidth/>
                                        </Grid>
                                        <Grid item xs={2} className={'vertically-centered'}>
                                            <Tooltip title="Save">
                                                <Button color={'primary'} disabled={isSubmitting} type={'submit'}>
                                                    <Done/>
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Cancel">
                                                <Button color={'secondary'} disabled={isSubmitting} onClick={() => {this.setState({editingDocumentName: false})}}>
                                                    <Clear/>
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}/>
                    </div> :
                    <div ref={this.props.dragRef} className={'vertically-centered'}>
                        <Tooltip title='Click to download'>
                            <Link href={'#'} onClick={this.download} style={{display: 'inline-flex', alignItems: 'center'}}>
                                {this.props.document.icon()}
                                <Box component={'span'} ml={1} mr={1}>{this.props.document.fileName()}</Box>
                            </Link>
                        </Tooltip>
                        <Tooltip title="Rename">
                            <IconButton size={'small'} className={'edit-document-name'} onClick={this.editDocumentName}>
                                <Edit fontSize={'small'}/>
                            </IconButton>
                        </Tooltip>
                    </div>
            }

            {this.snippet()}
            <Typography variant="caption" className={'vertically-centered'}>
                {this.renderFilingIcon()} {this.renderFilingName()}
            </Typography>
        </div>);
    }

    container = (children) => {
        const style = {};
        if (this.props.dragging) {
            style.opacity = 0.4;
        }

        return <div style={style}>
            {children}
        </div>;
    };

    download = () => {
        this.setState({working: true});

        new DocumentDownloader(this.props.apiClient).download(this.props.document.asObject(), (_, downloadUrl) => {
            this.props.document.downloadFrom(downloadUrl);
            this.setState({working: false});
        });
    };

    editDocumentName = () => {
        this.setState({editingDocumentName: true});
    };

    maxRecipients = () => {
        return this.state.showAllRecipients ? Number.MAX_VALUE : 5;
    };

    renameDocument = (values) => {
        this.setState({working: true});

        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation {
                    renameDoc(folder_id: "${this.props.document.folderId()}", doc_id: "${this.props.document.id()}", file_name: "${values.name}")
                }`)
            }).then((_) => {
                this.props.document.setFileName(values.name);
                this.setState({working: false, editingDocumentName: false});
            }).catch((reason) => {
                console.log(`Failed to rename document: ${JSON.stringify(reason)}`);
                this.setState({working: false, editingDocumentName: false});
            })
        );
    };

    renderSentDate = () => {
        const sentAt = moment(this.props.document.emailSentAt());
        return <Tooltip title={`Sent ${sentAt.format('DD-MM-YYYY HH:mm')}`} style={{marginRight: '1rem'}}>
                <span>Sent {sentAt.fromNow()}</span>
            </Tooltip>;
    };

    renderFilingIcon = () => {
        return this.props.showFilingLocation ?
            <PlainLink to={`/folders/${this.props.document.folderId()}`} className={'folder-link vertically-centered'}>
                <Tooltip title={'Click to open folder'}>
                    <FolderIcon/>
                </Tooltip>
            </PlainLink>
            : <span/>;
    };

    renderFilingName = () => {
        const filedAt = moment(this.props.document.timestampAsDate());
        return this.props.showFilingLocation ?
            <Tooltip title={`Filed ${filedAt.format('DD-MM-YYYY HH:mm')}`}>
                <span>Filed {filedAt.fromNow()}</span>
            </Tooltip> : <span/>;
    };

    snippet = () => {
        return <Typography variant="caption" display="block" className={'snippet'}>
            {
                !!this.props.document.snippet() ?
                    `${FylerUtils.abbreviate(this.props.document.snippet().replace(/(\\n|\\t)/g, ' ').trim(), 90)}` :
                    ''
            }
        </Typography>;
    };

    validate = (values) => {
        let errors = {};
        if (!values.name) {
            errors.name = 'Required';
        }
        return errors;
    };

}
