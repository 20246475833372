import * as React from 'react';
import Box from "@material-ui/core/Box";
import {withStyles} from '@material-ui/styles';
import Search from "./folders/Search";
import {ApiClientContext} from "./ApiClientContext";
import Button from "@material-ui/core/Button";
import {HomeContext} from "./HomeContext";
import Menu from "@material-ui/core/Menu";
import {FormatListNumbered, TextFields, Extension, People, Person} from "@material-ui/icons";
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from "@material-ui/icons/Menu";
import Steps from "./steps/Steps";
import Fields from "./fields/Fields";
import FolderTemplates from "./folders/templates/FolderTemplates";
import ChooseTemplate from "./folders/ChooseTemplate";
import Header from "./Header";
import Users from "./users/Users";
import {UserContext} from "./auth/UserContext";
import Divider from "@material-ui/core/Divider";
import Groups from "./groups/Groups";
import {
    Switch,
    Route,
    withRouter
} from "react-router-dom";
import ListItemLink from "./ListItemLink";
import Folder from "./folders/Folder";
import EditFolder from "./folders/EditFolder";
import MoveFolder from "./folders/MoveFolder";
import EditStep from "./steps/EditStep";
import AddStep from "./steps/AddStep";
import EditField from "./fields/EditField";
import AddField from "./fields/AddField";
import AddGroup from "./groups/AddGroup";
import AddUser from "./users/AddUser";
import AddFolderTemplate from "./folders/templates/AddFolderTemplate";
import EditFolderTemplate from "./folders/templates/EditFolderTemplate";
import AccessDeniedMessage from "./auth/AccessDeniedMessage";
import KnowledgeBase from "./knowledge_base/KnowledgeBase";

const styles = theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
});

class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      menuAnchor: null,
      version: this.bumpVersion()
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.root}>
        <Header goHome={this.goHome} contextualTitle={this.contextualTitle()} signOut={this.props.signOut}>
          {this.renderTitle()}
        </Header>

        <main key={this.state.version} className={classes.content}>
          <div className={classes.toolbar}/>

          <HomeContext.Provider value={this.goHome}>
                        <ApiClientContext.Consumer>
                            {apiClient =>
                                <Switch>
                                    <Route path="/folders/new">
                                        <ChooseTemplate apiClient={apiClient}/>
                                    </Route>
                                    <Route path="/folders/:id/new" render={(props) => {
                                        return <ChooseTemplate key={`folder-new-${props.match.params.id}`}
                                                               apiClient={apiClient}
                                                               parentFolderId={props.match.params.id} />;
                                    }}/>
                                    <Route path="/folders/:id/edit" render={(props) => {
                                        return <EditFolder key={`folder-edit-${props.match.params.id}`}
                                                           apiClient={apiClient}
                                                           id={props.match.params.id}/>;
                                    }}/>
                                    <Route path="/folders/:id/move" render={(props) => {
                                        return <MoveFolder key={`folder-move-${props.match.params.id}`}
                                                           apiClient={apiClient}
                                                           id={props.match.params.id}/>;
                                    }}/>
                                    <Route path="/folders/:id" render={(props) => {
                                        return <Folder key={`folder-${props.match.params.id}`}
                                                       apiClient={apiClient}
                                                       id={props.match.params.id}
                                                       goHome={this.goHome}/>;
                                    }}/>
                                    <Route path="/fields/new">
                                        <AddField apiClient={apiClient}/>
                                    </Route>
                                    <Route path="/fields/:id/edit" render={(props) => {
                                        return <EditField apiClient={apiClient}
                                                          id={props.match.params.id}/>;
                                    }}/>
                                    <Route path="/fields">
                                        <Fields apiClient={apiClient}/>
                                    </Route>
                                    <Route path="/groups/new">
                                        <AddGroup apiClient={apiClient}/>
                                    </Route>
                                    <Route path="/groups">
                                        {this.isUserInGroup(this.props.user, 'administrator') ? 
                                            <Groups apiClient={apiClient}/> : 
                                            <AccessDeniedMessage close={this.goHome} cancelMessage={"Cancel"}/>}
                                    </Route>
                                    <Route path="/steps/new">
                                        <AddStep apiClient={apiClient}/>
                                    </Route>
                                    <Route path="/steps/:id/edit" render={(props) => {
                                        return <EditStep apiClient={apiClient}
                                                         id={props.match.params.id}/>;
                                    }}/>
                                    <Route path="/steps">
                                        <Steps apiClient={apiClient}/>
                                    </Route>
                                    <Route path="/templates/new">
                                        <AddFolderTemplate apiClient={apiClient}/>
                                    </Route>
                                    <Route path="/templates/:id/edit" render={(props) => {
                                        return <EditFolderTemplate apiClient={apiClient}
                                                                   id={props.match.params.id}/>;
                                    }}/>
                                    <Route path="/templates">
                                        <FolderTemplates apiClient={apiClient}/>
                                    </Route>
                                    <Route path="/users/new">
                                        <AddUser apiClient={apiClient}/>
                                    </Route>
                                    <Route path="/users">
                                        {this.isUserInGroup(this.props.user, 'administrator') ? 
                                            <Users apiClient={apiClient}/> : 
                                            <AccessDeniedMessage close={this.goHome} cancelMessage={"Cancel"}/>}
                                    </Route>
                                    <Route path="/KnowledgeBase">
                                      <KnowledgeBase/>
                                    </Route>
                                    <Route path="/">
                                        <Search apiClient={apiClient} currentFolder={this.state.folderId} user={this.props.user}/>
                                    </Route>
                                </Switch>
                            }
                        </ApiClientContext.Consumer>
                    </HomeContext.Provider>

        </main>
      </Box>
    );
  }

  bumpVersion = () => {
    return new Date().getTime();
  };

  contextualTitle = () => {
    return <Switch>
        <Route path="/fields">
            Fields
        </Route>
        <Route path="/groups">
            Groups
        </Route>
        <Route path="/steps">
            Steps
        </Route>
        <Route path="/templates">
            Templates
        </Route>
        <Route path="/users">
            Users
        </Route>
        <Route path="/">
            Home
        </Route>
    </Switch>;
  };

  goHome = () => {
    this.props.history.push('/');
  };

  isUserInGroup = (user, groupName) => {
    return !!user.groups && user.groups.includes(groupName);
  };

  renderTitle = () => {
    return <Box className={'vertically-centered'}>
        <Button onClick={this.menuClick} style={{color: 'white'}}>
            <MenuIcon/>
        </Button>
        <UserContext.Consumer>
            {user =>
                <Menu
                    anchorEl={this.state.menuAnchor}
                    keepMounted
                    open={Boolean(this.state.menuAnchor)}
                    onClose={this.menuClose}>
                    <ListItemLink to="/" primary="Home" icon={<HomeIcon />} onClick={this.menuClose}/>
                    <ListItemLink to="/fields" primary="Fields" icon={<TextFields />} onClick={this.menuClose} />
                    <ListItemLink to="/steps" primary="Steps" icon={<FormatListNumbered />} onClick={this.menuClose} />
                    <ListItemLink to="/templates" primary="Templates" icon={<Extension />} onClick={this.menuClose} />
                    {
                        this.isUserInGroup(user, 'administrator') &&
                            <Box>
                                <Divider/>
                                <ListItemLink to="/groups" primary="Groups" icon={<People />} onClick={this.menuClose} />
                                <ListItemLink to="/users" primary="Users" icon={<Person />} onClick={this.menuClose} />
                            </Box>
                    }
                </Menu>
            }
        </UserContext.Consumer>
    </Box>;
};

menuClick = (event) => {
    this.setState({menuAnchor: event.currentTarget});
};

menuClose = () => {
    this.setState({menuAnchor: null});
};
}

export default withStyles(styles)(withRouter(Home));
