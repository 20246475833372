import * as React from 'react';
import Box from "@material-ui/core/Box";
import gql from "graphql-tag";
import {
    AssignmentInd,
    CreateNewFolder,
    Description,
    Email,
    FilterList,
    InsertDriveFile,
    PermMedia,
    FolderOpen, 
    Home, KeyboardArrowRight, KeyboardArrowDown
} from "@material-ui/icons";
import FolderIcon from '@material-ui/icons/Folder';
import SearchField from "../SearchField";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {FylerUtils} from "@verlata/fyler-office-common";
import WebFylerDocument from "../documents/WebFylerDocument";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {UserContext} from "../auth/UserContext";
import DocumentPreview from "../documents/DocumentPreview";
import FilingLocation from "../documents/FilingLocation";
import FilterContents from "./FilterContents";
import ButtonLink from "../ButtonLink";
import {withRouter} from "react-router-dom";
import FylerWebUtils from "../FylerWebUtils";
import MovableDocumentLink from "../documents/MovableDocumentLink";
import DroppableFolderLink from "./DroppableFolderLink";
import Backend from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import DataTable from "../tables/DataTable";
import moment from "moment";
import TableCell from '@material-ui/core/TableCell'
import Grid from "@material-ui/core/Grid";

class Search extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {},
        unitTest: false
    };

    constructor(props) {
        super(props);

        this.state = {
            filterResultsAnchor: null,
            query: props.query || FylerWebUtils.queryParam('query'),
            results: null,
            results2: null,
            results3: null,
            resultsFilter: 'everything',
            searching: true,
            searchingTypes: [],
            types: []
        };
    };

    componentDidMount() {
        if (!!this.state.query && !this.props.unitTest) {
            this.runSearch(this.state.query);
        }
        else {
            this.loadHome(this.props.user);
        }
    }

    render() {
        return <Box>
            <Box className={'vertically-centered'} mb={2}>
                <SearchField key={`query-${this.state.query}`}
                             query={this.state.query}
                             search={this.search}
                             searching={this.state.searching}/>

                <Box ml={2} className={'vertically-centered'}>
                    <Select multiple displayEmpty variant="outlined"
                            id={'search-type'}
                            renderValue={selected => {
                                if (selected.length === 0) {
                                    return <Box className={'placeholder'} component={'span'}>Any Type</Box>;
                                }
                                else {
                                    return <div>{FylerUtils.abbreviate(selected.join(', '), 48)}</div>;
                                }
                            }}
                            title={this.state.searchingTypes.join(', ')}
                            onChange={this.changedSearchingTypes}
                            value={this.state.searchingTypes}
                            style={{maxWidth: '512px'}}>
                        {
                            this.state.types.map((nextType, index) => {
                                return <MenuItem key={index} value={nextType}>{nextType}</MenuItem>;
                            })
                        }
                    </Select>

                    <FilterContents anchor={this.state.filterResultsAnchor}
                                    open={Boolean(this.state.filterResultsAnchor)}
                                    close={() => this.setState({filterResultsAnchor: null})}
                                    currentFilter={this.state.resultsFilter}
                                    filterContentTo={(newFilter) => this.setState({filterResultsAnchor: null, resultsFilter: newFilter})}/>
                    <Tooltip title={'Filter results'}>
                        <IconButton onClick={(event) => this.setState({filterResultsAnchor: event.currentTarget})} className={'results-filter'}>
                            {this.renderFilterIcon()}
                        </IconButton>
                    </Tooltip>
                </Box>

                {this.renderMenu()}
            </Box>

            {!!this.state.results2 && !!this.state.results3 && this.renderHomeResults()}
            {!!this.state.results && !!!this.state.results2 && !!!this.state.results3 && this.renderResults()}
        </Box>;
    }

    renderMenu() {
        return <Box style={{marginLeft: 'auto'}}>
            <UserContext.Consumer>
                {user =>
                    <Tooltip title={'Home'}>
                    <IconButton onClick={() => this.loadHome(user)} className={'menu home'} color={this.selectedBtn('home')}>
                        <Home />
                    </IconButton>
                </Tooltip>
                }
            </UserContext.Consumer>
            
            <UserContext.Consumer>
                {user =>
                    <Tooltip title={'Root Folders'}>
                    <IconButton onClick={() => this.loadRootFolders(user)} className={'menu root-folders'} color={this.selectedBtn('root')}>
                        <FolderOpen />
                    </IconButton>
                </Tooltip>
                }
            </UserContext.Consumer>

            <UserContext.Consumer>
                {user =>
                    <Tooltip title={'Recent Items'}>
                        <IconButton onClick={() => this.showRecent(user)} className={'menu recent-items'} color={this.selectedBtn('recent')}>
                            <InsertDriveFile/>
                        </IconButton>
                    </Tooltip>
                }
            </UserContext.Consumer>

            <UserContext.Consumer>
                {user =>
                    <Tooltip title={'Recent Folders'}>
                        <IconButton onClick={() => this.showRecentFolders(user)} className={'menu recent-folders'} color={this.selectedBtn('recent-folders')}>
                            <PermMedia/>
                        </IconButton>
                    </Tooltip>
                }
            </UserContext.Consumer>

            <UserContext.Consumer>
                {user =>
                    <Tooltip title={'My Work'}>
                        <IconButton onClick={() => this.showWorkQueue(user)} className={'menu work-queue'} color={this.selectedBtn('work')}>
                            <AssignmentInd/>
                        </IconButton>
                    </Tooltip>
                }
            </UserContext.Consumer>

            <ButtonLink to={'/folders/new'}>
                <Tooltip title={'Add a new folder'}>
                    <IconButton className={'menu'}>
                        <CreateNewFolder/>
                    </IconButton>
                </Tooltip>
            </ButtonLink>
        </Box>;
    }

    selectedBtn = (button) => {
        return (this.state.page === button ? 'primary' : 'default');
    }

    renderFilterIcon = () => {
        if (this.state.resultsFilter === 'documents') {
            return <Description/>;
        }
        else if (this.state.resultsFilter === 'folders') {
            return <FolderIcon/>;
        }
        else if (this.state.resultsFilter === 'emails') {
            return <Email/>;
        }
        else {
            return <FilterList/>;
        }
    };

    changedSearchingTypes = (event) => {
        this.setState({searchingTypes: event.target.value}, () => {
            this.search(this.state.query);
        });
    };

    loadRootFolders = () => {
        this.setState({searching: true, results: null, results2: null, results3: null}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query(this.rootFoldersQuery()).then((results) => {
                    this.setState({
                        searching: false,
                        results: this.folderResults(results.data.rootFolders),
                        types: results.data.folderTemplates.map((nextTemplate) => {
                            return nextTemplate.name;
                        }).sort(),
                        page: 'root'
                    });
                }).catch((reason) => {
                    console.log(`Failed to load rootFolders: ${JSON.stringify(reason)}`);
                    this.clearSearch();
                })
            );
        });
    };

    rootFoldersQuery = () => {
        return {
            query: gql(`query {
                rootFolders(size: 100) { 
                    id description type parent { id description } 
                }
                folderTemplates {
                    id name
                }
            }`),
            fetchPolicy: 'no-cache'
        };
    }

    recentDocsQuery = (user, size = null, fields = null) => {
        return {
            query: gql(`query {
                recentDocs(user_email: "${user.email}", size: ${!!size ? size : 20}) {
                    ` + (!!fields ? fields : `id folder_id file_name user_name snippet metadata { name value }`) + ` 
                }
            }`),
            fetchPolicy: 'no-cache'
        };
    }

    searchFoldersQuery = (folder_ids) => {
        let folderQuery = [...new Set(folder_ids.map(x => "id:" + x))].join(" OR ");
        console.log("searching with query: " + folderQuery);
        return {
            query: gql(`query Query($query: String!) {
                searchFolders(query: $query, size: 50) { 
                    id description type parent_id parent { id description } 
                }                       
            }`),
            variables: {
                query: folderQuery
            },
            fetchPolicy: 'no-cache'
        };
    }

    addFolderAccessTimesToResults = (folder_access_times, results) => {
        let distinct_folders = [];

        for (var j = 0; j < folder_access_times.length; j++) {
            let folder = results.data.searchFolders.filter(x => x.id == folder_access_times[j].folder_id)[0];

            if (folder !== undefined) {
                folder.accessed = folder_access_times[j].accessed;

                distinct_folders.push(results.data.searchFolders.filter(x => x.id == folder_access_times[j].folder_id)[0]);
            }
            else {
                console.log(`Folder id ${folder_access_times[j].folder_id} missing from search results`);
            }
        }

        return distinct_folders;
    }

    loadHome = (user) => {
        this.setState({searching: true, results: null, results2: null, results3: null}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query(this.rootFoldersQuery()).then((results1) => {
                    this.props.asyncCallMade(
                        this.props.apiClient.query(this.recentDocsQuery(user, 100, `id folder_id file_name snippet metadata { name value }`)).then((results2) => {
                            const folder_access_times = this.collateFolderAccessTimes(results2.data.recentDocs)
                    
                            const folder_ids = results2.data.recentDocs.map((doc) => {return doc.folder_id});
                            this.props.asyncCallMade(
                                this.props.apiClient.query(this.searchFoldersQuery(folder_ids)).then((results3) => {
                                    let distinct_folders = this.addFolderAccessTimesToResults(folder_access_times, results3);

                                    this.setState({
                                        searching: false,
                                        results: this.folderResults(results1.data.rootFolders),
                                        results2: this.documentResults(results2.data.recentDocs),
                                        results3: this.folderResults(distinct_folders, true, true),
                                        types: results1.data.folderTemplates.map((nextTemplate) => {
                                            return nextTemplate.name;
                                        }).sort(),
                                        page: 'home'
                                    });
                                }).catch((reason1) => {
                                    console.log(`Failed to load searchFolders (recent folders) on homepage: ${this.jsonifyException(reason1)}`);
                                    this.clearSearch();
                                })
                            );
                        }).catch((reason2) => {
                            console.log(`Failed to load recentDocs on homepage: ${this.jsonifyException(reason2)}`);
                            this.clearSearch();
                        })
                    );
                }).catch((reason3) => {
                    console.log(`Failed to load rootFolders on homepage: ${this.jsonifyException(reason3)}`);
                    this.clearSearch();
                })
            );
        });
    }

    renderHomeResults = () => {
        return <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {this.renderResults(this.state.results, 'Top Level Folders')}
                    <br/>
                    {this.renderResults(this.state.results3, 'Recent Folders')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {this.renderResults(this.state.results2, 'Recent Items')}
                </Grid>
            </Grid>
        </Box>;
    }

    renderResults = (results = null, title = null) => {
        return <DndProvider backend={Backend}>
            <DataTable
                columns={[
                    {
                        id: 'preview',
                        Cell: ({ row }) =>
                            row.original.hasPreview ?
                                <Tooltip title={'Preview'}>
                                    <IconButton {...row.getToggleRowExpandedProps({title: ''})} style={{display: 'block', margin: 'auto'}}>
                                        {row.isExpanded ? <KeyboardArrowDown/> : <KeyboardArrowRight/>}
                                    </IconButton>
                                </Tooltip>
                                : null,
                        width: 25,
                        maxWidth: 25,
                    },
                    {
                        Header: !!title ? title : 'Results',
                        id: 'description',
                        accessor: row => row.renderDescription(),
                        minWidth: 300,
                        width: 1000
                    },
                    {
                        Header: 'Filed',
                        id: 'filed',
                        accessor: row => row.renderFiled(),
                        width: 200,
                        maxWidth: 200,
                        sortDescFirst: true,
                        sortType: (row0, row1) => {
                            return row0.original.filedTimestamp().getTime() - row1.original.filedTimestamp().getTime();
                        }
                    },
                ]}
                data={!!results ? 
                        results.filter((nextResult) => nextResult.isVisible()) : 
                        this.state.results.filter((nextResult) => nextResult.isVisible())}
                initialSort={[{id: 'filed', desc: true}]}
                subComponent={(row) => {                    
                    return (
                        <React.Fragment>
                            <TableCell />
                            <TableCell colspan="2">
                                {row.original.renderPreview()}
                            </TableCell>
                        </React.Fragment>
                    )}                
                }
                />
        </DndProvider>
    };

    search = (query) => {
        this.props.history.push(`/?query=${query}`);

        this.runSearch(query);
    };

    runSearch(query) {
        this.setState({searching: true, results: null, results2: null, results3: null, query: query}, () => {
            const queryParts = [];
            if (!!query) {
                queryParts.push(query);
            }
            if (this.state.searchingTypes.length > 0) {
                queryParts.push(this.searchingTypesQuery());
            }

            this.props.asyncCallMade(
                this.props.apiClient.query({
                    query: gql(`query Query($query: String!) {
                        searchFolders(query: $query, size: 100) { 
                            id description type parent_id parent { id description } 
                        }
                        searchDocs(query: $query, size: 100) { 
                            id folder_id file_name user_name snippet metadata { name value } 
                        }                        
                    }`),
                    variables: {
                        query: queryParts.join(' AND ')
                    },
                    fetchPolicy: 'no-cache'
                }).then((results) => {
                    this.setState({
                        searching: false,
                        results: this.folderResults(results.data.searchFolders)
                            .concat(this.documentResults(this.sortDocumentsByName(results.data.searchDocs)))
                    });
                }).catch((reason) => {
                    console.log(`Failed to load runSearch: ${JSON.stringify(reason)}`);
                    this.clearSearch();
                })
            );
        });
    }

    showRecent = (user) => {
        this.setState({searching: true, results: null, results2: null, results3: null}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query(this.recentDocsQuery(user)).then((results) => {
                    this.setState({
                        searching: false,
                        results: this.documentResults(results.data.recentDocs),
                        page: 'recent'
                    });
                })
            );
        });
    };

    showRecentFolders = (user) => {
        this.setState({searching: true, results: null, results2: null, results3: null}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query(this.recentDocsQuery(user, 50, `id folder_id metadata { name value }`)).then((results) => {
                    const folder_access_times = this.collateFolderAccessTimes(results.data.recentDocs)
                    
                    const folder_ids = results.data.recentDocs.map((doc) => {return doc.folder_id});
                    this.props.asyncCallMade(
                        this.props.apiClient.query(this.searchFoldersQuery(folder_ids)).then((results) => {
                            let distinct_folders = this.addFolderAccessTimesToResults(folder_access_times, results);
                            this.setState({
                                searching: false,
                                results: this.folderResults(distinct_folders, true),
                                page: 'recent-folders'
                            });
                        }).catch((reason) => {
                            console.log(`Failed to load searchFolders: ${JSON.stringify(reason)}`);
                            this.clearSearch();
                        })
                    );
                }).catch((reason) => {
                    console.log(`Failed to load recentFolders: ${JSON.stringify(reason)}`);
                    this.clearSearch();
                })
            );
        });
    };

    clearSearch = () => {
        this.setState({
            searching: false, results: [], types: []
        });
    }

    collateFolderAccessTimes = (docs) => {
        const folders = [];

        for (var i = 0; i < docs.length; i++) {
            if (folders.filter(x => x.folder_id == docs[i].folder_id).length < 1) {
                folders.push({
                    "folder_id": docs[i].folder_id,
                    "accessed": this.docTimestamp(docs[i])
                });
            }
            else {
                if (folders.filter(x => x.folder_id == docs[i].folder_id).accessed < this.docTimestamp(docs[i])) {
                    folders.filter(x => x.folder_id == docs[i].folder_id).accessed = this.docTimestamp(docs[i])
                }
            }
        }

        return folders;
    }

    docTimestamp = (doc) => {
        return this.documentMetadata(doc, "timestamp")
    }

    documentMetadata = (doc, key) => {
        return doc.metadata.filter(x => x.name == key)[0].value;
    }

    showWorkQueue = (user) => {
        this.setState({searching: true, results: null, results2: null, results3: null}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query({
                    query: gql(`query {
                        workQueue(user_id: "${user.username}") { 
                            id description type parent { id description } 
                        }                    
                    }`),
                    fetchPolicy: 'no-cache'
                }).then((results) => {
                    this.setState({
                        searching: false,
                        results: this.folderResults(results.data.workQueue),
                        page: 'work'
                    });
                })
            );
        });
    };

    documentResults(docs) {
        return docs.map((nextDocument) => {
            const fylerDocument = new WebFylerDocument(nextDocument);
            return {
                renderDescription: () => <MovableDocumentLink apiClient={this.props.apiClient}
                                                              document={fylerDocument}
                                                              moved={() => this.runSearch(this.state.query)}
                                                              onUserClick={this.resultUserClicked}
                                                              showFilingLocation={false}/>,
                renderFiled: () => <FilingLocation item={fylerDocument}/>,
                hasPreview: true,
                renderPreview: () => <Box pl={3}>
                    <DocumentPreview apiClient={this.props.apiClient}
                                     document={fylerDocument}
                                     asyncCallMade={this.props.asyncCallMade}/>
                </Box>,
                filedTimestamp: () => fylerDocument.timestampAsDate(),
                isVisible: () => (fylerDocument.isEmail() && this.isResultVisible('emails')) ||
                    (!fylerDocument.isEmail() && this.isResultVisible('documents'))
            };
        });
    }

    folderResults(results, recentFolders = false, homepage = false) {
        return results.filter((folder) => {
                if (!!folder.parent_id) {
                    const parent = results.find((nextResult) => nextResult.id === folder.parent_id);
                    if (!recentFolders) { 
                        if (!!parent) {
                            parent.children = (parent.children || []).concat([folder]);
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                    else {
                        if (!!folder.accessed) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                }
                else {
                    return true;
                }
            })
            .sort((folder0, folder1) => {
                return folder0.description.localeCompare(folder1.description);
            })
            .map((nextFolder) => {
                let tempAccessed = !!nextFolder.accessed ? FylerUtils.epochToDate(parseInt(this.splitValue(nextFolder.accessed.replace(/"/g, ""),10))) : null;
                let filedAt = recentFolders ? moment(tempAccessed) : null;

                return {
                    renderDescription: () => this.renderFolderDescription(nextFolder, 0, !homepage),
                    hasPreview: false,
                    renderFiled: () => {
                        return (recentFolders ? 
                            <Tooltip title={`Filed ${filedAt.format('DD-MM-YYYY HH:mm')}`}>
                                    <span>{filedAt.fromNow()}</span>
                            </Tooltip> :
                            null); 
                    },
                    filedTimestamp: () => {
                        return recentFolders ? tempAccessed : new Date(Number.MAX_SAFE_INTEGER);
                    },
                    isVisible: () => this.isResultVisible('folders')
                };
            });
    }

    splitValue = (value, index) => {
        return value.substring(0, index) + "," + value.substring(index);
    }

    isResultVisible = (typeOfContent) => {
        return this.state.resultsFilter === 'everything' ||
            this.state.resultsFilter === typeOfContent;
    };

    renderFolderDescription = (folder, depth, type = true) => {
        return (<Box key={`folder-description-${folder.description}`}
                    style={{marginLeft: `${Math.min(depth, 1)}rem`, marginTop: `${Math.min(depth, 0.25)}rem`}}>
            <Box className={'vertically-centered'}>
                <DroppableFolderLink folder={folder} showParent={depth === 0}/>

                {type && <Typography style={{marginLeft: '0.5rem'}} variant="caption">
                    {folder.type}
                </Typography>}
            </Box>

            { (folder.children || []).map((nextChild) => this.renderFolderDescription(nextChild, depth + 1)) }
        </Box>);
    };

    resultUserClicked = (userEmail) => {
        let newQuery = `"${userEmail}"`;
        if (this.state.query.trim().length > 0) {
            newQuery = `${this.state.query} AND ${newQuery}`;
        }
        this.search(newQuery);
    };

    searchingTypesQuery = () => {
        return `type:(${this.state.searchingTypes.map((nextType) => `"${nextType}"`)})`;
    };

    sortDocumentsByName(docs) {
        return [...docs].sort((document0, document1) => {
            return document0.file_name.localeCompare(document1.file_name);
        });
    }

    jsonifyException = (exception) => {
        let json = JSON.stringify(exception)
        if (json == "{}") {
            return exception;
        }
        return json;
    }
}

export default withRouter(Search);
