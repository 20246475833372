import * as React from 'react';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {Description, Email, FilterList, Folder} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default class FilterContents extends React.Component {

    render() {
        return <Menu className={'contents-filter'}
                     anchorEl={this.props.anchor}
                     keepMounted
                     open={this.props.open}
                     onClose={this.props.close}>
            <MenuItem selected={this.props.currentFilter === 'everything'}
                      onClick={() => this.props.filterContentTo('everything')}>
                <ListItemIcon>
                    <FilterList/>
                </ListItemIcon>
                <ListItemText primary="Everything"/>
            </MenuItem>
            <MenuItem selected={this.props.currentFilter === 'documents'}
                      onClick={() => this.props.filterContentTo('documents')}>
                <ListItemIcon>
                    <Description/>
                </ListItemIcon>
                <ListItemText primary="Documents"/>
            </MenuItem>
            <MenuItem selected={this.props.currentFilter === 'emails'}
                      onClick={() => this.props.filterContentTo('emails')}>
                <ListItemIcon>
                    <Email/>
                </ListItemIcon>
                <ListItemText primary="Emails"/>
            </MenuItem>
            <MenuItem selected={this.props.currentFilter === 'folders'}
                      onClick={() => this.props.filterContentTo('folders')}>
                <ListItemIcon>
                    <Folder/>
                </ListItemIcon>
                <ListItemText primary="Folders"/>
            </MenuItem>
        </Menu>;
    }

}
