import * as React from 'react';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ErrorIcon from '@material-ui/icons/Error';
import Paper from "@material-ui/core/Paper";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default class AccessDeniedMessage extends React.Component {

    render() {
        return <Paper style={{display: 'flex', justifyContent: 'space-between'}}>
            <Box p={1} className={'vertically-centered'}>
                <Box component={'span'} mr={1}><ErrorIcon color={'secondary'}/></Box>
                <Typography component={'span'} variant={'body1'}>
                    You do not have permission to access this.
                </Typography>
            </Box>

            <Tooltip title={!!this.props.cancelMessage ? this.props.cancelMessage : "Return to folder"}>
                <IconButton onClick={this.props.close}>
                    <ArrowBackIcon/>
                </IconButton>
            </Tooltip>
        </Paper>;
    }
}
