import * as React from 'react';
import gql from "graphql-tag";
import IconButton from "@material-ui/core/IconButton";
import {CloudUpload} from "@material-ui/icons";
import {CircularProgress} from "@material-ui/core";
import {FylerUtils} from "@verlata/fyler-office-common";
import {FileUploader} from "@verlata/fyler-office-common";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Dropzone from "react-dropzone";
import {withStyles} from '@material-ui/styles';
import DialogTitle from "@material-ui/core/DialogTitle";
import {MSGReader} from "wl-msg-reader";
import moment from "moment";

const styles = () => ({
    '@keyframes progress': {
        '0%': {
            backgroundPosition: '0 0',
        },
        '100%': {
            backgroundPosition: '-70px 0',
        },
    },
    dropZone: {
        position: 'relative',
        width: '100%',
        minHeight: '250px',
        backgroundColor: '#F0F0F0',
        border: 'dashed',
        borderColor: '#C8C8C8',
        cursor: 'pointer',
        boxSizing: 'border-box',
    },
    dropzoneTextStyle: {
        textAlign: 'center',
        width: '100%'
    },
    uploadIconSize: {
        width: 36,
        height: 36,
        color: '#909090'
    },
    dropzoneParagraph: {
        fontSize: 18
    }
});

class DocumentUpload extends React.Component {

    state = {
        open: false,
        uploading: false,
    };

    render() {
        const { classes } = this.props;
        return (
            this.state.uploading ? <CircularProgress size={24}/> :
                <Box component={'span'}>
                    <Tooltip title={this.props.disabled ? "" : "Upload"}>
                        <IconButton onClick={() => this.setState({open: true})} disabled={this.props.disabled}>
                            <CloudUpload/>
                        </IconButton>
                    </Tooltip>

                    <Dialog onClose={this.close}
                            open={this.state.open}
                            fullWidth
                            maxWidth={'sm'}>
                        <DialogTitle>Upload</DialogTitle>

                        <Dropzone onDrop={this.uploadDocuments}
                                  className={classes.dropZone}
                                  multiple={!!!this.props.documentId}
                                  maxSize={104857600}>
                            {({getRootProps, getInputProps}) => (
                                <section style={{padding: '0.5rem'}}>
                                    <div {...getRootProps({className: `${classes.dropZone} vertically-centered`})}>
                                        <input {...getInputProps()} />
                                        <div className={classes.dropzoneTextStyle}>
                                            <p className={classes.dropzoneParagraph}>
                                                {
                                                    !!this.props.documentId ?
                                                        'Drag and drop, or click to select, the file to upload' :
                                                        'Drag and drop, or click to select, files or folders to upload'
                                                }
                                            </p>
                                            <CloudUpload className={classes.uploadIconSize} />
                                        </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Dialog>
                </Box>
        );
    }

    close = () => {
        this.setState({open: false});
    };

    uploadDocuments = (files) => {
        this.setState({uploading: true}, async () => {
            for (let i = 0; i < files.length; ++i) {
                if (!!this.props.documentId) {
                    await this.update(files[i]);
                }
                else {
                    if (files[i].name.endsWith('.msg')) {
                        await new FileUploader(this.props.apiClient).parseAndCreateEmail(files[i], this.props.username, this.props.folderId);
                    }
                    else {
                        await new FileUploader(this.props.apiClient).create(files[i], this.props.folderId, this.props.username);
                    }
                }
            }
            this.setState({uploading: false}, this.props.finished);
        });
    };

    async update(file) {
        await this.props.apiClient.mutate({
            mutation: gql(`mutation UpdateDoc($folderId: String!, $docId: ID!, $username: String!, $fileName: String!) {
                updateDoc(folder_id: $folderId, doc_id: $docId, user_name: $username, file_name: $fileName) {
                    id
                    upload {
                        url
                        headers { name value }
                    }
                }
            }`),
            variables: {
                folderId: this.props.folderId,
                docId: this.props.documentId,
                username: this.props.username,
                fileName: file.name
            }
        }).then((results) => {
            return new FileUploader(this.props.apiClient).upload(file, results.data['updateDoc']);
        });
    }

}

export default withStyles(styles)(DocumentUpload)
