import React, {useEffect} from 'react'
import MUITable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {useExpanded, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable} from 'react-table'
import Paper from "@material-ui/core/Paper";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableContainer from "@material-ui/core/TableContainer";
import DataTableToolbar from "./DataTableToolbar";
import Checkbox from "@material-ui/core/Checkbox";

export default function DataTable({
                                      columns,
                                      data,                                    
                                      initialSort = [],
                                      title = null,
                                      selectionChanged = null,
                                      filterFunction = null,
                                      selectionActions = null,
                                      globalActions = null,
                                      subComponent = null
                                  }) {
    const selectable = !!selectionChanged;
    const filterable = !!filterFunction;
    let filterTypes = null;
    if (filterable) {
        filterTypes = {
            text: filterFunction,
        };
    }

    const {
        getTableProps,
        flatHeaders,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, selectedRowIds, globalFilter }
    } = useTable({
            columns,
            data,
            initialState: {
                sortBy: initialSort
            },
            autoResetPage: false,
            autoResetSelectedRows: false,
            filterTypes,
            globalFilter: 'text'            
        },
        filterable && useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        hooks => {
            if (selectable) {
                addSelectColumn(hooks);
            }
        });

    const handleChangePage = (event, newPage) => {
        gotoPage(newPage)
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(Number(event.target.value))
    };

    useEffect(() => {
        if (selectable) {
            const selectedData = Object.keys(filterSubRows(selectedRowIds)).map((nextIndex) => {
                return data[parseInt(nextIndex)];
            });
            selectionChanged(selectedData);
        }
    });

    return <Paper>
        <TableContainer>
            {
                (selectable || filterable) &&
                    <DataTableToolbar
                        numSelected={Object.keys(filterSubRows(selectedRowIds)).length}
                        title={title}
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        setGlobalFilter={setGlobalFilter}
                        globalFilter={globalFilter}
                        selectionActions={selectionActions}
                        globalActions={globalActions}/>
            }

            <MUITable className={'nested-data'} {...getTableProps()}>
                <TableHead>
                    <TableRow>
                        {flatHeaders.map(column => (
                            <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} style={styleForColumn(column)}>
                                {column.render('Header')}

                                {
                                    column.canSort &&
                                    <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'}/>
                                }
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <React.Fragment>
                                <TableRow {...row.getRowProps()} className={row.isSelected ? 'selected' : ''}>
                                    {row.cells.map(cell => {
                                        return (
                                            <TableCell {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>                                
                                {                                    
                                    !!row.isExpanded ? (                                                                        
                                    <TableRow className='subcomponent'>                                                                                                                                                             
                                        {subComponent(row)}                                                                                                                                                                                                        
                                    </TableRow>
                                    ) : null
                                }                                
                            </React.Fragment>
                        )
                    })}
                </TableBody>

                <TableFooter>
                    <TableRow>
                        <TableCell/>
                        <TablePagination
                            rowsPerPageOptions={[
                                { label: '5 rows', value: 5 },
                                { label: '10 rows', value: 10 },
                                { label: '20 rows', value: 20 },
                                { label: 'All', value: data.length },
                            ]}
                            count={data.length}
                            rowsPerPage={pageSize}
                            page={pageIndex}
                            labelRowsPerPage={null}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </MUITable>
        </TableContainer>
    </Paper>;
}

function addSelectColumn(hooks) {
    hooks.allColumns.push(columns => [
        {
            id: 'selection',
            Header: ({getToggleAllRowsSelectedProps}) =>
                <SelectionCheckbox {...getToggleAllRowsSelectedProps()}/>
            ,
            Cell: ({row}) =>
                row.depth === 0 ?
                    <SelectionCheckbox {...row.getToggleRowSelectedProps()}/> :
                    null,
            width: 25,
            maxWidth: 25,
        },
        ...columns,
    ]);
}

const filterSubRows = (rowIds) => {
    return Object.keys(rowIds)
        .filter(key => !key.includes('.'))
        .reduce((obj, key) => {
            obj[key] = rowIds[key];
            return obj;
        }, {});
};

const styleForColumn = column => {
    return {
        width: column.width,
        maxWidth: column.maxWidth,
        minWidth: column.minWidth
    };
};

const SelectionCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate]);

        return <Checkbox size={'small'} ref={resolvedRef} {...rest} style={{display: 'block', margin: 'auto'}}/>;
    }
);
