import * as React from 'react';
import gql from "graphql-tag";
import {CircularProgress} from "@material-ui/core";
import FolderTemplateForm from "./FolderTemplateForm";
import {withRouter} from "react-router-dom";

class EditFolderTemplate extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            template: null,
            working: true,
        };
    };

    componentDidMount() {
        this.loadFolderTemplate();
    }

    render() {
        if (this.state.working) {
            return <CircularProgress/>;
        }
        else {
            return <FolderTemplateForm template={this.state.template}
                                       fields={this.state.fields}
                                       submitButtonText={'Save'}
                                       save={this.update}
                                       cancel={this.done}/>;
        }
    }

    done = () => {
        this.props.history.goBack();
    };

    loadFolderTemplate = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {
                    folderTemplate(id: "${this.props.id}") { id name category description fields { name type quick_search } }
                    fields { id name type }
                }`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    working: false,
                    template: results.data['folderTemplate'],
                    fields: results.data['fields']
                });
            }).catch((reason) => {
                console.log(`Failed to load folderTemplate with id ${this.props.id} because ${reason}`);
                this.setState({
                    working: false,
                    template: {
                        name: 'This folderTemplate could not be found'
                    }
                });
            })
        );
    };

    update = (values) => {
        console.log(`Updating folderTemplate with: ${JSON.stringify(values)}`);

        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation UpdateFolderTemplate($id: ID!, $input: UpdateFolderTemplate!) {
                    updateFolderTemplate(id: $id, input: $input)
                }`),
                variables: {
                    id: this.props.id,
                    input: {
                        name: values.name,
                        category: values.category,
                        description: values.description,
                        fields: values.fields.map((nextField) => {
                            return {
                                name: nextField.name,
                                type: nextField.type,
                                quick_search: nextField.quick_search
                            };
                        })
                    }
                }
            }).then(this.done)
        );
    };

    validate = (values) => {
        let errors = {};
        if (!values.name) {
            errors.name = 'Required';
        }
        return errors;
    };
}

export default withRouter(EditFolderTemplate);
