import * as React from 'react';
import gql from "graphql-tag";
import Folder from "./Folder";
import FolderForm from "./FolderForm";
import Box from "@material-ui/core/Box";
import {HomeContext} from "../HomeContext";
import AddFolderStepper from "./AddFolderStepper";
import {CircularProgress} from "@material-ui/core";
import EditPermissions from "../documents/EditPermissions";
import Button from "@material-ui/core/Button";
import {ArrowBack} from "@material-ui/icons";

class AddFolder extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            editingPermissions: false,
            working: true,
            createdFolder: null
        };
    };

    componentDidMount() {
        this.initFromTemplate();
    }

    render() {
        if (this.state.working) {
            return <CircularProgress/>;
        }
        else if (this.state.editingPermissions) {
            return <Box>
                <AddFolderStepper currentStep={2}/>
                <EditPermissions apiClient={this.props.apiClient}
                                 folder={this.state.createdFolder}
                                 finished={() => {this.setState({editingPermissions: false})}}/>
            </Box>;
        }
        else if (!!this.state.createdFolder) {
            return <HomeContext.Consumer>
                {goHome =>
                    <Folder apiClient={this.props.apiClient}
                            id={this.state.createdFolder.id}
                            asyncCallMade={this.props.asyncCallMade}
                            closeViewingFolder={goHome}
                            goHome={goHome}/>
                }
            </HomeContext.Consumer>;
        }
        else {
            return <Box>
                <AddFolderStepper currentStep={1}/>

                {
                    !!this.props.onClose &&
                        <Box mb={2} className={'horizontally-right'}>
                            <Button color="secondary" startIcon={<ArrowBack/>} onClick={this.props.onClose}>
                                Back
                            </Button>
                        </Box>
                }

                <HomeContext.Consumer>
                    {goHome =>
                        <FolderForm apiClient={this.props.apiClient}
                                    asyncCallMade={this.props.asyncCallMade}
                                    folder={this.state.folder}
                                    submitButtonText={'Create'}
                                    save={this.create}
                                    cancel={goHome}/>
                    }
                </HomeContext.Consumer>
            </Box>;
        }
    }

    create = (values, {setSubmitting}) => {
        const folder = {
            parent_id: this.props.parentFolderId,
            description: values.description,
            type: this.state.folder.type,
            fields: values.fields.map((nextField) => {
                return {
                    name: nextField.name,
                    type: nextField.type,
                    value: nextField.value
                };
            })
        };
        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation CreateFolder($input: FolderInput!) {
                    createFolder(input: $input)
                }`),
                variables: {
                    input: folder
                }
            }).then((results) => {
                setSubmitting(false);
                this.setState({
                    editingPermissions: true,
                    createdFolder: {
                        ...folder,
                        id: results.data['createFolder']
                    }
                });
            }).catch((reason) => {console.log(`Failed to create folder: ${reason}`);})
        );
    };

    initFromTemplate = () => {
        this.setState({working: true, folder: null}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query({
                    query: gql(`query { folderFromTemplate(template_id: "${this.props.templateId}") { id description type fields { name type value } }}`),
                    fetchPolicy: 'no-cache'
                }).then((results) => {
                    this.setState({
                        working: false,
                        folder: {
                            ...results.data.folderFromTemplate,
                            description: ''
                        }
                    });
                })
            );
        });
    };

    validate = (values) => {
        let errors = {};
        if (!values.description) {
            errors.description = 'Required';
        }
        return errors;
    };
}

export default AddFolder;
