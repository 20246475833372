import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SearchIcon from "@material-ui/icons/Search";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import Box from "@material-ui/core/Box";
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ButtonBase from '@material-ui/core/ButtonBase';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  link: {
    cursor: 'pointer',
     color: 'blue',
     textDecoration: 'underline',
  }
}));

export default function PermanentDrawerLeft() {
  const classes = useStyles();

  const [selected, setSelected] = useState("Home");
  const [expanded, setExpanded] = useState([]);

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left">
        <div className={classes.toolbar} />
        <h3 style={{ textAlign: "center" }}>Knowledge Base</h3>
        <Divider />
        <List>
          <ListItem button key={"Home"} selected={selected === "Home"} onClick={() => toggleExpansion("Home")}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItem>
          <ListItem button key={"Searching"} selected={selected === "Searching"} onClick={() => toggleExpansion("Searching")}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary={"Searching"} />
            {!!expanded && expanded.includes("Searching") ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={expanded.includes("Searching")} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} selected={selected === "AdvancedSearching"} onClick={() => setSelected("AdvancedSearching")}>
                <ListItemIcon>
                  <FindInPageIcon />
                </ListItemIcon>
                <ListItemText primary="Advanced Search" />
              </ListItem>
              <ListItem button className={classes.nested} selected={selected === "EmailSearching"} onClick={() => setSelected("EmailSearching")}>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary="Email Search" />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer>
      <main className={classes.content}>
        {articleContent(selected)}
      </main>
    </div>
  );

  function toggleExpansion(catagory) {
    expanded.includes(catagory) ?
      setExpanded(expanded.map(x => x != catagory)) :
      setExpanded(expanded.concat(catagory));

    setSelected(catagory);
  }

  function articleContent (title) {
    switch (title) {
      case "Searching":
        return (
          <Box>
            <h3 className={'fylerText'}>Home page searching</h3>
            <Typography component={'span'}>
              On the home page of Fyler, there is a global search bar. This search allows you to do a ‘full text’ search across all documents, emails and folders that you have access to in the system. See screenshot below
            </Typography>
            <img src={'/knowledge_base/search.png'} width={700}/>
            <Typography component={'span'}>
              <ol>
                <li>Searching for a word or number
                  <ol type="a">
                    <li>To search for a word, type the full word or number in the search bar and press enter or click the magnifying glass</li>
                    <li>Your results will be all documents where that word is
                      <ul>
                        <li>In the title of the document/email/folder</li>
                        <li>In the content of the document/email</li>
                        <li>In the metadata of a document/email/folder</li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li>Searching for a part of a word or number
                  <ol type="a">
                    <li>To search for part of a word, type the part of the word or number and put an asterisk after it, like fold*
                      <ul>
                        <li>This search would return the word fold, folder, folding, folds, etc</li>
                      </ul>
                    </li>
                    <li>
                      Your results will be all documents where that part word is
                      <ul>
                        <li>In the title of the document/email/folder</li>
                        <li>In the content of the document/email</li>
                        <li>In the metadata of a document/email/folder</li>
                      </ul>
                    </li>
                  </ol>
                </li>
              </ol>
            </Typography>
          </Box>
        );
        break;
      case "EmailSearching":
        return(
          <Box>
            <h3 className={'fylerText'}>Email Search</h3>
            <Typography component={'span'}>
              <ol>
                <li>When searching for an email, you can specify certain fields, like to, from, cc and subject. Below are some examples on how to use these fields
                  <ul>
                    <li>from: joe.blogs@fyler.app</li>
                    <li>to: adam*</li>
                    <li>cc: adam*</li>
                    <li>subject: fyler</li>
                  </ul>
                </li>
                <li>You can use these in conjunction with all the operators listed in the <span className={classes.link} onClick={() => setSelected("AdvancedSearching")}>Advance Searching</span> section.</li>
              </ol>
            </Typography>
          </Box>
        );
        break;
      case "AdvancedSearching":
        return (
          <Box>
            <h3 className={'fylerText'}>Advanced Searching</h3>
            <Typography component={'span'}>
              <h4>Searching Operators</h4>
              <ul>
                <li>
                  AND
                  <ul>
                    <li>This allows you to search for both words/numbers, like Jason AND Fyler. This would only return documents/emails/folders where both Jason AND Fyler were in the document/email/folder</li>
                  </ul>
                </li>
                <li>
                  OR
                  <ul>
                    <li>This allows you to search for either word, like Berlin OR Germany. This would return all documents/emails/folders where either Berlin or Germany were in the document/email/folder</li>
                  </ul>
                </li>
                <li>
                  NOT
                  <ul>
                    <li>This allows you to exclude a word from your search, like swimming NOT pool. This would return all documents/emails/folders where swimming is in the document/email/folder, but pool is not</li>
                  </ul>
                </li>
                <li>
                  ~1 (fuzzy logic, for misspellings)
                  <ul>
                    <li>This allows you to search for a word and include misspellings, like their~1. This would return all documents/emails/folders where either their or thier were in the document/email/folder</li>
                  </ul>
                </li>
                <li>
                  ?
                  <ul>
                    <li>This allows you to search for a word where you aren’t sure of the exact spelling, like customi?e. This would return all documents/emails/folders where either customise or customize were in the document/email/folder</li>
                  </ul>
                </li>
                <li>
                  *
                  <ul>
                    <li>This allows you to search for part of a word or number, like fold*. This would return all documents/emails/folders where fold, folder, folding, folds etc were in the document/email/folder</li>
                  </ul>
                </li>
                <li>
                +, - (Advanced Boolean operators)
                  <ul>
                    <li>
                      This allows you to search where some words MUST be returned and where some MUST NOT be returned, and some words CAN be returned. The preferred operators are + (this term must be present) and - (this term must not be present). All other terms are optional. For example, this query: quick brown +fox -news
                      States that
                      <ol>
                        <li>fox must be present</li>
                        <li>news must not be present</li>
                        <li>quick and brown are optional — their presence increases the relevance</li>
                      </ol>
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Box>
          );
        break;
      default:
        return (
          <Box className={'knowledge'}>
            <Grid container>
              <Grid item md={12} lg={12} className={'horizontally-centered'}>
                  <img src={'/assets/sales/fyler_logo.png'} className={'hero'} alt={'Fyler'} title={'Fyler'} height={300}/>
              </Grid>
            </Grid>
            <h2 className={'horizontally-centered'}>Find answers instantly with our Knowledge base.</h2>
            <Typography paragraph className={'horizontally-centered'}>
              Please checkout the articles which appear in the navigation pane on the left of screen. As we update Fyler with new features we will also update the this space with the knowledge you will need to be a Fyler pro.
            </Typography>
            <h2><u>Quick links:</u></h2>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={4}>
                  <Card>
                  <ButtonBase onClick={(event) => setSelected("Searching")}>
                      <CardContent>
                          <Typography variant="h5" className={'vertically-centered'}>
                              <SearchIcon fontSize={'large'} color={'primary'}/>
                              <Box component={'span'} ml={1}>General Searching</Box>
                          </Typography>
                          <Box mt={2}>
                              <List component="nav">
                                  <ListItem>
                                      <ListItemText primary={'Conduct a ‘full text’ search across all documents, emails and folders that you have access to in the system.'}/>
                                  </ListItem>
                              </List>
                          </Box>
                      </CardContent>
                    </ButtonBase>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Card>
                    <ButtonBase onClick={(event) => {
                        toggleExpansion("Searching");
                        setSelected("EmailSearching");
                        }}>
                      <CardContent>
                          <Typography variant="h5" className={'vertically-centered'}>
                              <EmailIcon fontSize={'large'} color={'primary'}/>
                              <Box component={'span'} ml={1}>Email Search</Box>
                          </Typography>
                          <Box mt={2}>
                              <List component="nav">
                                  <ListItem>
                                      <ListItemText primary={'Search for emails containing specific content.'}/>
                                  </ListItem>
                              </List>
                          </Box>
                      </CardContent>
                    </ButtonBase>

                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Card>
                    <ButtonBase onClick={(event) => {window.location = "mailto:support@fyler.app"}}>
                      <CardContent>
                        <Typography variant="h5" className={'vertically-centered'}>
                            <ContactSupportIcon fontSize={'large'} color={'primary'}/>
                            <Box component={'span'} ml={1}>Contact Support</Box>
                        </Typography>
                        <Box mt={2}>
                            <List component="nav">
                                <ListItem>
                                    <ListItemText primary={'Have a question? Get in contact with your query and we will get back to you as soon as possible.'}/>
                                </ListItem>
                            </List>
                        </Box>
                      </CardContent>
                      </ButtonBase>
                  </Card>
                </Grid>
              </Grid>
          </Box>
        );
    }
  }
}
