import * as React from 'react';
import {Field, FieldArray, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {AddCircle, RemoveCircle} from "@material-ui/icons";

class FieldForm extends React.Component {

    render() {
        return <Formik
            initialValues={this.props.field}
            validate={this.validate}
            onSubmit={this.props.save}
            render={({ values, isSubmitting }) => (
                <Form style={{width: '50%'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field name='name' label={'Name'} component={TextField} value={values.name} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <FieldArray
                                name='values'
                                render={arrayHelpers => (
                                    <Box mt={1}>
                                        <Typography variant={'h5'} component={'span'}>
                                            Values
                                            <Tooltip title={'Add a custom field'}>
                                                <IconButton onClick={() => arrayHelpers.push('')}>
                                                    <AddCircle/>
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                        {values.values.map((nextValue, index) => (
                                            <Grid container key={index} spacing={1}>
                                                <Grid item xs={5}>
                                                    <Field name={`values[${index}]`} label={'Value'} component={TextField} value={nextValue} fullWidth/>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Tooltip title={'Remove this field'}>
                                                        <IconButton onClick={() => arrayHelpers.remove(index)} xs={2}>
                                                            <RemoveCircle/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Box>
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant='contained' color='primary' disabled={isSubmitting} type={'submit'}>
                                {this.props.submitButtonText}
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant='contained' color='secondary' disabled={isSubmitting} onClick={this.props.cancel}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}/>;
    };

    validate = (values) => {
        let errors = {};
        if (!values.name) {
            errors.name = 'Required';
        }
        return errors;
    };
}

export default FieldForm;
