import moment from "moment";
import * as React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
    AddComment,
    CheckCircle, Clear, Delete,
    Done, Edit,
    ExpandMore, PersonAdd,
    QuestionAnswer,
    RadioButtonUnchecked,
    Warning
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Chip from "@material-ui/core/Chip";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import {CircularProgress} from "@material-ui/core";
import {TextField} from "formik-material-ui";
import {Field, Form, Formik} from "formik";
import Button from "@material-ui/core/Button";
import gql from "graphql-tag";
import Select from "@material-ui/core/Select";
import UserAvatar from "../UserAvatar";
import MenuItem from "@material-ui/core/MenuItem";

export default class FolderStep extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {},
        dependencyHovered: (_0, _1) => {}
    };

    constructor(props) {
        super(props);

        this.updateCompletedAt();

        this.state = {
            addingComment: false,
            editingAssignees: false,
            editingDependencies: false,
            expanded: !this.isComplete(),
            newAssignees: null,
            newDependencies: null,
            working: false,
        };
    };

    render() {
        if (this.state.working) {
            return <CircularProgress/>;
        }
        else {
            return <Box mt={1} style={{maxWidth: '1024px'}}>
                <Card className={`workflow-step ${this.props.highlight ? 'workflow-step-highlighted' : ''}`}>
                    <CardContent>
                        <Typography className={'workflow-step-name vertically-centered'} variant="subtitle1">
                            {this.renderStatusIcon()}

                            <Box component={'span'} className={'vertically-centered'}>
                                {this.props.reorderHandle}
                            </Box>

                            <Box component={'span'} ml={1}>{this.props.step.name}</Box>

                            <IconButton className={this.state.expanded ? 'expanded' : 'collapsed'}
                                        onClick={this.expandClicked}
                                        style={{marginRight: 'auto'}}>
                                <ExpandMore/>
                            </IconButton>

                            {!this.state.editingAssignees && this.renderAssignees()}
                            {this.renderDelete()}
                        </Typography>

                        {this.state.editingAssignees && this.renderEditAssignees()}

                        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                            <Box mt={2}>
                                {this.state.editingDependencies ? this.renderEditDependencies() : this.renderDependencies()}
                                {this.renderComments()}
                            </Box>
                        </Collapse>
                    </CardContent>
                </Card>
            </Box>;
        }
    };

    renderAssignees = () => {
        return <Box component={'span'}>
            <Grid container justify="center" alignItems="center">
                {
                    this.props.step.assignees.map((nextAssignee, index) => {
                        return this.avatar(nextAssignee.first_name, nextAssignee.last_name, nextAssignee.email, index);
                    })
                }

                <Tooltip title={'Edit the assignees for this step'}>
                    <IconButton className={'workflow-step-reassign'} onClick={() => {this.setState({working: true, editingAssignees: true, newAssignees: this.props.step.assignees, expanded: true}, this.loadUsers)}}>
                        <PersonAdd/>
                    </IconButton>
                </Tooltip>
            </Grid>
        </Box>;
    };

    renderDelete = () => {
        return <Box>
            <Tooltip title={'Delete this step'}>
                <IconButton edge="end" className={'workflow-step-delete'} onClick={this.delete}>
                    <Delete/>
                </IconButton>
            </Tooltip>
        </Box>;
    };

    renderEditAssignees() {
        return <Grid container>
            <Grid item xs={10} className={'vertically-centered'}>
                <Select multiple displayEmpty variant="outlined"
                        className={'workflow-step-assignee-select'}
                        renderValue={selected => {
                            return <Box className={'placeholder'} component={'span'}>
                                { selected.length === 0 ? 'No assignees' : `${selected.length} assignee(s)` }
                            </Box>;
                        }}
                        title={this.state.newAssignees.map((nextUser) => this.userFullName(nextUser)).join(', ')}
                        onChange={this.changedAssignees}
                        value={this.state.newAssignees.map((nextUser) => nextUser.id)}
                        style={{width: '100%'}}>
                    {
                        this.state.users.map((nextUser, index) => {
                            return <MenuItem key={index} value={nextUser.id}>{this.userFullName(nextUser)}</MenuItem>;
                        })
                    }
                </Select>
            </Grid>
            <Grid item xs={2} className={'vertically-centered'}>
                <Tooltip title="Save">
                    <Button color={'primary'} type={'submit'} onClick={this.saveAssignees}>
                        <Done/>
                    </Button>
                </Tooltip>
                <Tooltip title="Cancel">
                    <Button color={'secondary'} onClick={() => {
                        this.setState({editingAssignees: false})
                    }}>
                        <Clear/>
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>;
    }

    renderComments = () => {
        return <List dense={true}>
            <ListItem dense={true}>
                <ListItemIcon><QuestionAnswer/></ListItemIcon>
                <ListItemText primary={'Comments'} secondary={this.props.step.comments.length === 0 ? 'None' : ''}/>
                <ListItemSecondaryAction>
                    <Tooltip title={'Make a comment'}>
                        <IconButton edge="end" className={'workflow-step-add-comment'} onClick={this.addingComment}>
                            <AddComment/>
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
            {
                this.state.addingComment &&
                <List className={'sublist'} dense={true}>
                    <ListItem dense={true} className={'workflow-step-comment'}>
                        <ListItemAvatar>{this.avatar(this.props.user.first_name, this.props.user.last_name, this.props.user.email)}</ListItemAvatar>
                        <Formik
                            initialValues={{comment: '', user: this.props.user.email}}
                            validate={this.validateComment}
                            onSubmit={this.addComment}
                            render={({values, isSubmitting}) => (
                                <Form>
                                    <Grid container>
                                        <Grid item xs={10} className={'vertically-centered'}>
                                            <Field name={'user'} type={'hidden'}/>
                                            <Field name='comment' component={TextField} value={values.comment} autoFocus fullWidth multiline={true} rows={3} variant={'outlined'}/>
                                        </Grid>
                                        <Grid item xs={2} className={'vertically-centered'}>
                                            <Tooltip title="Save">
                                                <Button color={'primary'} disabled={isSubmitting} type={'submit'}>
                                                    <Done/>
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Cancel">
                                                <Button color={'secondary'} disabled={isSubmitting} onClick={() => {this.setState({addingComment: false})}}>
                                                    <Clear/>
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}/>
                    </ListItem>
                </List>
            }
            <List className={'sublist'} dense={true}>
                {
                    this.props.step.comments.sort((comment0, comment1) => {
                        return comment1.when - comment0.when;
                    }).map((nextComment, index) => {
                        const commentTimestamp = moment(new Date(nextComment.when * 1000));
                        return <ListItem key={index} dense={true} className={'workflow-step-comment'}>
                            <ListItemAvatar>{this.avatar(null, null, nextComment.by)}</ListItemAvatar>
                            <ListItemText primary={nextComment.content} secondary={this.timestampShort(commentTimestamp)}/>
                        </ListItem>
                    })
                }
            </List>
        </List>;
    };

    renderDependencies = () => {
        return <List dense={true}>
            <ListItem dense={true}>
                <ListItemIcon><Warning/></ListItemIcon>
                <ListItemText primary={'Pre-requisites'} secondary={this.props.step.dependencies.length === 0 ? 'None' : ''}/>
                <ListItemSecondaryAction>
                    <Tooltip title={'Edit the pre-requisites of this step'}>
                        <IconButton edge="end" className={'workflow-step-edit-dependencies'} onClick={() => {this.setState({editingDependencies: true, newDependencies: this.props.step.dependencies})}}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
            <List className={'sublist'} dense={true}>
                <ListItem dense={true}>
                    {
                        this.props.step.dependencies.map((nextDependency, index) => {
                            const completed = !!nextDependency.completed_at;
                            return <Chip key={index}
                                         className={`workflow-step-dependency ${completed ? 'workflow-step-dependency-completed' : 'workflow-step-dependency-incomplete'}`}
                                         label={nextDependency.name}
                                         onMouseEnter={() => this.props.dependencyHovered(nextDependency.id, true)}
                                         onMouseLeave={() => this.props.dependencyHovered(nextDependency.id, false)}
                                         variant={'outlined'}
                                         size={'small'}/>
                        })
                    }
                </ListItem>
            </List>
        </List>;
    };

    renderEditDependencies = () => {
        return <Grid container>
            <Grid item xs={10} className={'vertically-centered'}>
                <Select multiple displayEmpty variant="outlined"
                        renderValue={selected => {
                            return <Box className={'placeholder'} component={'span'}>
                                { selected.length === 0 ? 'No pre-requisites' : `${selected.length} pre-requisites` }
                            </Box>;
                        }}
                        title={this.state.newDependencies.map((nextStep) => nextStep.name).join(', ')}
                        onChange={this.changedDependencies}
                        value={this.state.newDependencies.map((nextStep) => nextStep.id)}
                        style={{width: '100%'}}>
                    {
                        this.props.previousSteps.map((nextStep, index) => {
                            return <MenuItem key={index} value={nextStep.id}>{nextStep.name}</MenuItem>;
                        })
                    }
                </Select>
            </Grid>
            <Grid item xs={2} className={'vertically-centered'}>
                <Tooltip title="Save">
                    <Button color={'primary'} type={'submit'} onClick={this.saveDependencies}>
                        <Done/>
                    </Button>
                </Tooltip>
                <Tooltip title="Cancel">
                    <Button color={'secondary'} onClick={() => {
                        this.setState({editingDependencies: false})
                    }}>
                        <Clear/>
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>;
    };

    renderStatusIcon() {
        return <IconButton className={'workflow-step-status'} onClick={this.statusClicked}>
            {
                this.isComplete() ?
                    <Tooltip title={`Completed on ${this.timestampLong(this.completed_at)}. Click to re-open.`}>
                        <CheckCircle fontSize={'default'} className={'workflow-step-complete'}/>
                    </Tooltip> :
                    <Tooltip title={'Click to complete'}>
                        <RadioButtonUnchecked className={'workflow-step-incomplete'}/>
                    </Tooltip>
            }
        </IconButton>;
    }

    addComment = (values) => {
        this.setState({working: true});

        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation Comment($comment: String!) {
                    commentOnWorkflow(folder_id: "${this.props.folderId}", step_id: "${this.props.step.id}", comment: $comment, by: "${values.user}")
                }`),
                variables: {
                    comment: values.comment
                }
            }).then((response) => {
                this.props.step.comments.unshift({
                    id: response['commentOnWorkflow'],
                    content: values.comment,
                    by: values.user,
                    when: new Date().getTime() / 1000
                });
            }).catch((reason) => {
                console.log(`Failed to add comment: ${JSON.stringify(reason)}`);
            }).finally(() => {
                this.setState({working: false, addingComment: false});
            })
        );
    };

    addingComment = () => {
        this.setState({addingComment: true});
    };

    avatar = (firstName, lastName, email, key = null) => {
        return <UserAvatar key={key} email={email} firstName={firstName} lastName={lastName} className={'user-avatar-collection'}/>;
    };

    changedAssignees = (event) => {
        this.setState({
            newAssignees: (event.target.value || []).map((nextId) => {
                return this.userWithId(nextId);
            })
        });
    };

    changedDependencies = (event) => {
        this.setState({
            newDependencies: (event.target.value || []).map((nextId) => {
                return this.stepWithId(nextId);
            })
        });
    };

    delete = () => {
        if (this.props.unitTest || window.confirm('Are you sure you want to delete this step?')) {
            this.setState({working: true}, () => {
                this.props.asyncCallMade(
                    this.props.apiClient.mutate({
                        mutation: gql(`mutation {
                            deleteWorkflowStep(folder_id: "${this.props.folderId}", step_id: "${this.props.step.id}")
                        }`)
                    }).then(this.props.reload).catch((reason) => {
                        console.log(`Failed to delete step because ${reason}`);
                    }).finally(() => {
                        this.setState({working: false});
                    })
                );
            });
        }
    };

    expandClicked = () => {
        this.setState({expanded: !this.state.expanded});
    };

    isComplete() {
        return !!this.completed_at;
    }

    loadUsers = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {
                    users { id first_name last_name email } 
                }`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    working: false,
                    users: results.data['users']
                });
            }).catch((reason) => {console.log(`Failed to load users: ${JSON.stringify(reason)}`)})
        );
    };

    saveAssignees = () => {
        this.setState({working: true});

        this.props.step.assignees = this.state.newAssignees;
        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation Assign($userIds: [ID!]!) {
                    assignWorkflowStep(folder_id: "${this.props.folderId}", step_id: "${this.props.step.id}", user_ids: $userIds)
                }`),
                variables: {
                    userIds: this.props.step.assignees.map((nextAssignee) => { return nextAssignee.id; }),
                }
            }).catch((reason) => {
                console.log(`Failed to save assignees: ${JSON.stringify(reason)}`);
            }).finally(() => {
                this.setState({working: false, editingAssignees: false, newAssignees: null});
            })
        );
    };

    saveDependencies = () => {
        this.setState({working: true});

        this.props.step.dependencies = this.state.newDependencies;
        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation Dependencies($stepIds: [ID!]!) {
                    updateWorkflowStepDependencies(folder_id: "${this.props.folderId}", step_id: "${this.props.step.id}", folder_step_ids: $stepIds)
                }`),
                variables: {
                    stepIds: this.props.step.dependencies.map((nextDependency) => { return nextDependency.id; }),
                }
            }).catch((reason) => {
                console.log(`Failed to update dependencies: ${JSON.stringify(reason)}`);
            }).finally(() => {
                this.setState({working: false, editingDependencies: false, newDependencies: null});
            })
        );
    };

    statusClicked = () => {
        this.setState({working: true});

        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation {
                    updateWorkflowStepStatus(folder_id: "${this.props.folderId}", step_id: "${this.props.step.id}", complete: ${!this.isComplete()})
                }`)
            }).then((_) => {
                if (this.isComplete()) {
                    this.props.step.completed_at = null;
                }
                else {
                    this.props.step.completed_at = new Date().getTime() / 1000;
                }
                this.updateCompletedAt();
            }).catch((reason) => {
                console.log(`Failed to update status: ${JSON.stringify(reason)}`);
            }).finally(() => {
                this.setState({working: false, expanded: false});
            })
        );
    };

    stepWithId = (id) => {
        return this.props.previousSteps.find((nextStep) => nextStep.id === id);
    };

    timestampLong(timestamp) {
        return timestamp.format('DD MMM YYYY HH:mm');
    }

    timestampShort(timestamp) {
        return timestamp.format('DD MMM');
    }

    updateCompletedAt() {
        if (!!this.props.step.completed_at) {
            this.completed_at = moment(new Date(this.props.step.completed_at * 1000));
        } else {
            this.completed_at = null;
        }
    }

    userFullName = (user) => {
        if (!!user.last_name && !!user.first_name) {
            return `${user.last_name}, ${user.first_name}`;
        }
        else {
            return user.email;
        }
    };

    userWithId = (id) => {
        return this.state.users.find((nextUser) => nextUser.id === id);
    };

    validateComment = (values) => {
        let errors = {};
        if (!values.comment) {
            errors.comment = 'Required';
        }
        else if (values.comment.length > 3000) {
            errors.comment = `${values.comment.length} / 3000 characters used`
        }
        return errors;
    };
}
