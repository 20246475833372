import React from 'react';
import { useDrag } from 'react-dnd';
import DocumentLink from "./DocumentLink";
import gql from "graphql-tag";

const MovableDocumentLink = ({ apiClient, document, moved, onUserClick = null, showFilingLocation = false }) => {
    const moveDocumentTo = (folderId) => {
        apiClient.mutate({
            mutation: gql(`mutation {
                moveDoc(current_folder_id: "${document.folderId()}", doc_id: "${document.id()}", new_folder_id: "${folderId}")
            }`)
        }).then(moved).catch((reason) => {
            console.log(`Failed to move document: ${JSON.stringify(reason)}`);
        })
    };

    // eslint-disable-next-line
    const [{isDragging}, drag] = useDrag({
        item: {
            name: document.id(),
            type: 'document'
        },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                moveDocumentTo(dropResult.name);
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    return <DocumentLink apiClient={apiClient}
                         dragRef={drag}
                         dragging={isDragging}
                         document={document}
                         onUserClick={onUserClick}
                         showFilingLocation={showFilingLocation}/>;
};
export default MovableDocumentLink;
