import * as React from 'react';
import {CircularProgress} from "@material-ui/core";
import gql from "graphql-tag";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import {Add, Delete, KeyboardArrowDown, KeyboardArrowRight} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";
import User from "./User";
import IconButton from "@material-ui/core/IconButton";
import ButtonLink from "../ButtonLink";
import DataTable from "../tables/DataTable";
import TableCell from '@material-ui/core/TableCell'

class Users extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            users: []
        };
    };

    componentDidMount() {
        this.loadUsers();
    }

    render() {
        if (this.state.loading) {
            return <CircularProgress/>;
        }
        else {
            return this.renderUsers();
        }
    }

    renderUsers() {
        return <Box>
            <Box mb={1} className={'vertically-centered'}>
                {this.renderMenu()}
            </Box>

            <DataTable
                columns={[
                    {
                        id: 'preview',
                        Cell: ({ row }) =>
                            !!row.original.id ?
                                <IconButton {...row.getToggleRowExpandedProps()} style={{display: 'block', margin: 'auto'}}>
                                    {row.isExpanded ? <KeyboardArrowDown/> : <KeyboardArrowRight/>}
                                </IconButton>
                                : null,
                        width: 25,
                    },
                    {
                        Header: 'Last Name',
                        id: 'last_name',
                        accessor: 'last_name',
                    },
                    {
                        Header: 'First Name',
                        accessor: 'first_name'
                    },
                    {
                        Header: 'Email',
                        accessor: 'email'
                    },
                    {
                        Header: '',
                        id: 'actions',
                        Cell: ({ row }) =>
                            !!row.original.id ?
                                <Box className={'vertically-centered'}>
                                    <Tooltip title="Delete this user">
                                        <IconButton onClick={() => {this.deleteUser(row.original)}} className={'delete-user'}>
                                            <Delete/>
                                        </IconButton>
                                    </Tooltip>
                                </Box> :
                                null
                    },
                ]}
                data={this.state.users}                
                initialSort={[{id: 'last_name'}]}
                subComponent={(row) => {                                                
                    return (    
                        <React.Fragment>
                            <TableCell />
                            <TableCell colSpan='2'>
                                {this.renderUserDetails(row.original)}
                            </TableCell>
                        </React.Fragment>
                    )
                }
            }/>
        </Box>;
    }

    renderUserDetails = (user) => {
        return <User apiClient={this.props.apiClient} id={user.id}/>;
    };

    deleteUser = (user) => {
        if (this.props.unitTest || window.confirm("Are you sure you want to delete this user?")) {
            this.setState({loading: true}, () => {
                this.props.asyncCallMade(
                    this.props.apiClient.mutate({
                        mutation: gql(`mutation {
                            deleteUser(id: "${user.id}")
                        }`)
                    }).catch((reason) => {
                        console.log(`Failed to delete user: ${JSON.stringify(reason)}`);
                    }).finally(this.loadUsers)
                );
            });
        }
    };

    loadUsers = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {users { id first_name last_name email }}`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    loading: false,
                    users: results.data['users']
                });
            })
        );
    };

    renderMenu() {
        return <ButtonLink to={`/users/new`} style={{marginLeft: 'auto'}}>
            <Tooltip title={'Add a new user'}>
                <Fab color="primary">
                    <Add/>
                </Fab>
            </Tooltip>
        </ButtonLink>;
    }
}

export default Users;
