import * as React from 'react';
import {CircularProgress} from "@material-ui/core";
import gql from "graphql-tag";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import {Add, Delete} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import ButtonLink from "../ButtonLink";
import DataTable from "../tables/DataTable";

class Groups extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            groups: []
        };
    };

    componentDidMount() {
        this.loadGroups();
    }

    render() {
        if (this.state.loading) {
            return <CircularProgress/>;
        }
        else {
            return this.renderGroups();
        }
    }

    renderGroups() {
        return <Box>
            <Box mb={1} className={'vertically-centered'}>
                {this.renderMenu()}
            </Box>

            <DataTable
                columns={[
                    {
                        Header: 'Name',
                        accessor: 'name'
                    },
                    {
                        Header: '',
                        id: 'actions',
                        width: 100,
                        Cell: ({ row }) =>
                            <Box className={'vertically-centered'}>
                                {
                                    !this.isProtectedGroup(row.original) &&
                                    <Tooltip title="Delete this group">
                                        <IconButton onClick={() => {this.deleteGroup(row.original)}} className={'delete-group'}>
                                            <Delete/>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Box>
                    },
                ]}
                data={this.state.groups}
                initialSort={[{id: 'name'}]}/>
        </Box>;
    }

    deleteGroup = (group) => {
        if (this.props.unitTest || window.confirm("Are you sure you want to delete this group?")) {
            this.setState({loading: true}, () => {
                this.props.asyncCallMade(
                    this.props.apiClient.mutate({
                        mutation: gql(`mutation {
                            deleteGroup(id: "${group.id}")
                        }`)
                    }).catch((reason) => {
                        console.log(`Failed to delete group: ${JSON.stringify(reason)}`);
                    }).finally(this.loadGroups)
                );
            });
        }
    };

    isProtectedGroup = (group) => {
        return ['administrator', 'user'].includes(group.id);
    };

    loadGroups = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {groups { id name }}`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    loading: false,
                    groups: results.data['groups'].sort((group0, group1) => {
                        return group0.name.localeCompare(group1.name);
                    })
                });
            })
        );
    };

    renderMenu() {
        return <ButtonLink to={`/groups/new`} style={{marginLeft: 'auto'}}>
            <Tooltip title={'Add a new group'}>
                <Fab color="primary">
                    <Add/>
                </Fab>
            </Tooltip>
        </ButtonLink>;
    }
}

export default Groups;
