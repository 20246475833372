import * as React from 'react';
import gql from "graphql-tag";
import {CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import * as moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LockIcon from '@material-ui/icons/Lock';
import WebFylerDocument from "../documents/WebFylerDocument";
import Grid from "@material-ui/core/Grid";
import EditPermissions from "../documents/EditPermissions";
import DocumentVersions from "../documents/DocumentVersions";
import {
    CreateNewFolder,
    DeleteSweep, FilterList,
    KeyboardArrowDown, KeyboardArrowRight,
    Layers,
    LocalShipping,
    MoreVert,
} from "@material-ui/icons";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import DocumentUpload from "../documents/DocumentUpload";
import {UserContext} from '../auth/UserContext';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FolderWorkflow from "./FolderWorkflow";
import FilterContents from "./FilterContents";
import ListItemLink from "../ListItemLink";
import PlainLink from "../PlainLink";
import { withRouter } from 'react-router-dom';
import ButtonLink from "../ButtonLink";
import Link from "@material-ui/core/Link";
import {DndProvider} from "react-dnd";
import Backend from 'react-dnd-html5-backend'
import MovableDocumentLink from "../documents/MovableDocumentLink";
import DroppableFolderLink from "./DroppableFolderLink";
import MoveDocumentsPopup from "./MoveDocumentsPopup";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import DataTable from "../tables/DataTable";
import DocumentPreview from "../documents/DocumentPreview";
import TableCell from '@material-ui/core/TableCell'

class Folder extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {},
        unitTest: false
    };

    constructor(props) {
        super(props);

        this.state = {
            bulkActionsDialogOpen: false,
            contentFilter: 'everything',
            currentTab: 'content',
            editingPermissions: false,
            editingDocumentPermissions: null,
            folder: null,
            filterContentAnchor: null,
            selected: [],
            viewingDocumentVersions: null,
            working: true,
            errors: 0
        };
    };

    componentDidMount() {
        this.loadFolder();
    }

    render() {
        if (this.state.working) {
            return <CircularProgress/>;
        }
        else if (!!this.state.editingDocumentPermissions) {
            return <EditPermissions apiClient={this.props.apiClient}
                                    document={this.state.editingDocumentPermissions}
                                    finished={() => {this.setState({editingDocumentPermissions: null})}}/>;
        }
        else if (!!this.state.viewingDocumentVersions) {
            return <DocumentVersions apiClient={this.props.apiClient}
                                     document={this.state.viewingDocumentVersions}
                                     finished={() => {this.setState({viewingDocumentVersions: null})}}/>;
        }
        else if (this.state.editingPermissions) {
            return <EditPermissions apiClient={this.props.apiClient}
                                    folder={this.state.folder}
                                    finished={() => {this.setState({editingPermissions: false})}}/>;
        }
        else {
            return this.renderFolder();
        }
    }

    renderFolder = () => {
        return <Grid container spacing={2}>
            <Grid item xs={2}>
                <Breadcrumbs className={'header'}>
                    {
                        !!this.state.folder.parent &&
                        <PlainLink to={`/folders/${this.state.folder.parent.id}`} className={'folder-link'}>
                            {this.state.folder.parent.description}
                        </PlainLink>
                    }
                    <Typography variant={'h5'} component={'span'} color="textPrimary">
                        {this.state.folder.description}
                    </Typography>
                </Breadcrumbs>

                <Box mt={2}>{this.fields()}</Box>
            </Grid>
            <Grid item xs={10}>
                <Box style={{display: 'flex', alignItems: 'flex-end'}} mb={1}>
                    <Box>
                        <Tabs value={this.state.currentTab}
                              onChange={(_, tab) => this.setState({currentTab: tab})}
                              indicatorColor={'primary'}>
                            <Tab label="CONTENT" value={'content'} />
                            <Tab label="WORKFLOW" value={'workflow'} />
                        </Tabs>
                    </Box>

                    <Box ml={'auto'} component={'span'} className={'vertically-centered'}>
                        <Tooltip title="Return to the previous page">
                            <IconButton onClick={() => this.props.history.goBack()}>
                                <ArrowBackIcon/>
                            </IconButton>
                        </Tooltip>

                        <ButtonLink to={`/folders/${this.props.id}/edit`}>
                            <Tooltip title="Edit this folder">
                                <IconButton>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                        </ButtonLink>

                        <UserContext.Consumer>
                            {user =>
                                <DocumentUpload apiClient={this.props.apiClient}
                                                folderId={this.props.id}
                                                username={(user || {}).email}
                                                finished={this.loadFolder}/>
                            }
                        </UserContext.Consumer>

                        <Tooltip title="Fetch the latest data for this folder">
                            <IconButton onClick={this.loadFolder}>
                                <RefreshIcon/>
                            </IconButton>
                        </Tooltip>

                        <IconButton onClick={this.menuClick}>
                            <MoreVert/>
                        </IconButton>
                        <Menu anchorEl={this.state.menuAnchor}
                              keepMounted
                              open={Boolean(this.state.menuAnchor)}
                              onClose={this.menuClose}>
                            <MenuItem onClick={this.delete} className={'delete-folder'}>
                                <ListItemIcon>
                                    <DeleteSweep/>
                                </ListItemIcon>
                                Delete
                            </MenuItem>
                            <ListItemLink to={`/folders/${this.props.id}/move`}
                                          primary="Move"
                                          icon={<LocalShipping />}
                                          onClick={this.menuClose}/>
                            <ListItemLink to={`/folders/${this.props.id}/new`}
                                          primary="New sub-folder"
                                          icon={<CreateNewFolder />}
                                          onClick={this.menuClose}/>
                            <MenuItem onClick={this.editPermissions}>
                                <ListItemIcon>
                                    <LockIcon/>
                                </ListItemIcon>
                                Permissions
                            </MenuItem>
                        </Menu>
                    </Box>
                </Box>

                {this.renderTabContent()}
                {!!this.state.bulkActionsDialogOpen && this.moveDocPopup()}
                {!!this.state.docsMoved && this.renderMoveSuccess()}
                {!!this.state.docsMoveFailed && this.renderMoveError()}
                {!!this.state.docsCopied && this.renderCopySuccess()}
                {!!this.state.docsCopyFailed && this.renderCopyError()}
                {!!this.state.docsDeleted && this.renderDeleteSuccess()}
                {!!this.state.docsDeleteFailed && this.renderDeleteError()}
            </Grid>
        </Grid>;
    };

    finishedMove = (errorCount) => {
        this.loadFolder();
        this.setState({ moveErrorCount: errorCount });
        errorCount < 1 ? 
            this.setState({docsMoved: true}) : 
            this.setState({docsMoveFailed: true});
    }

    finishedCopy = (errorCount) => {
        this.loadFolder();
        this.setState({ copyErrorCount: errorCount });
        errorCount < 1 ? 
            this.setState({docsCopied: true}) : 
            this.setState({docsCopyFailed: true});
    }

    finishedDelete = () => {
        this.loadFolder();
        this.state.errors < 1 ? 
            this.setState({docsDeleted: true}) : 
            this.setState({docsDeleteFailed: true});
    }

    renderMoveSuccess = () => {
        return <Snackbar open={this.state.docsMoved} autoHideDuration={5000} onClose={this.closeSuccess}>
            <MuiAlert variant="filled" severity="success" onClose={this.closeSuccess}>
                Successfully Moved Documents
            </MuiAlert>
        </Snackbar>
    }

    renderMoveError = () => {
        return <Snackbar open={this.state.docsMoveFailed} autoHideDuration={5000} onClose={this.closeError}>
            <MuiAlert variant="filled" severity="error" onClose={this.closeError}>
                There was an issue while moving {this.state.moveErrorCount} documents...
            </MuiAlert>
        </Snackbar>
    }

    renderCopySuccess = () => {
        return <Snackbar open={this.state.docsCopied} autoHideDuration={5000} onClose={this.closeSuccess}>
            <MuiAlert variant="filled" severity="success" onClose={this.closeSuccess}>
                Successfully Copied Documents
            </MuiAlert>
        </Snackbar>
    }

    renderCopyError = () => {
        return <Snackbar open={this.state.docsCopyFailed} autoHideDuration={5000} onClose={this.closeError}>
            <MuiAlert variant="filled" severity="error" onClose={this.closeError}>
                There was an issue while copying {this.state.copyErrorCount} documents...
            </MuiAlert>
        </Snackbar>
    }

    renderDeleteSuccess = () => {
        return <Snackbar open={this.state.docsDeleted} autoHideDuration={5000} onClose={this.closeDeleteSuccess}>
            <MuiAlert variant="filled" severity="success" onClose={this.closeDeleteSuccess}>
                Successfully Deleted documents...
            </MuiAlert>
        </Snackbar>
    }

    renderDeleteError = () => {
        return <Snackbar open={this.state.docsDeleteFailed} autoHideDuration={5000} onClose={this.closeDeleteError}>
            <MuiAlert variant="filled" severity="error" onClose={this.closeDeleteError}>
                There was an issue while deleting {this.state.errors} documents...
            </MuiAlert>
        </Snackbar>
    }

    closeSuccess = () => {
        this.setState({docsMoved: false});
    }

    closeError = () => {
        this.setState({docsMoveFailed: false});
    }

    closeDeleteSuccess = () => {
        this.setState({docsDeleted: false});
    }

    closeDeleteError = () => {
        this.setState({docsDeleteFailed: false});
    }

    renderTabContent = () => {
        if (this.state.currentTab === 'content') {
            return this.contents();
        }
        else {
            return this.workflow();
        }
    };

    delete = () => {
        this.menuClose();

        if (this.props.unitTest || window.confirm('Are you sure you want to delete this folder, its documents, and sub-folders?')) {
            this.setState({working: true}, () => {
                this.props.asyncCallMade(
                    this.props.apiClient.mutate({
                        mutation: gql(`mutation DeleteFolder($id: ID!) {
                    deleteFolder(id: $id)
                }`),
                        variables: {
                            id: this.props.id
                        }
                    }).then(this.props.goHome).catch((reason) => {
                        console.log(`Failed to delete folder ${this.props.id} because ${reason}`);
                        this.setState({
                            working: false
                        });
                    })
                );
            });
        }
    };

    deleteDocument = (document) => {
        return new Promise((resolve, reject) => {
            console.log(`deleting document: ${JSON.stringify(document)}`);
            this.props.apiClient.mutate({
                mutation: gql(`mutation DeleteDoc($folderId: String!, $docId: ID!) {
                    deleteDoc(folder_id: $folderId, doc_id: $docId)
                }`),
                variables: {
                    folderId: this.props.id,
                    docId: document.id
                }
            }).then(resolve).catch((reason) => {
                this.addError();
                console.log(`Failed to delete document: ${JSON.stringify(reason)}`);
                reject();
            });
        });
    };

    deleteSelectedDocuments = () => {
        if (this.props.unitTest || window.confirm('Are you sure you want to delete these document?')) {

            this.setState({working: true});

            const documents = this.state.selected.filter(item => item.document); 
            //const folders = this.state.selected.filter(item => item.folder);

            const actions = documents.map((row) => {
                return this.deleteDocument(row.document.document)
            }).map(p => p.catch(e => e));

            Promise.all(actions).then((results) => {
                this.finishedDelete();
            });
        }
    }

    addError = () => {
        this.setState((prevState, props) => ({
            errors: prevState.errors + 1
        }));
    }

    fields = () => {
        return <Grid container
                     spacing={1}
                     direction="row"
                     justify="flex-start"
                     alignItems="flex-start">
            {
                this.state.folder.fields.map((nextField) => {
                    return <Grid item xs={12} className={'folder-field'} key={nextField.name}>
                        <Box component={'span'} mr={1}>
                            <strong>{nextField.name}{nextField.type === 'currency' && ' ($)'}</strong>
                        </Box>
                        {
                            (!!nextField.value && (nextField.value.startsWith('http:') || nextField.value.startsWith('https:'))) ?
                                <Link href={nextField.value}>
                                    {nextField.value}
                                </Link> :
                                <span className={'folder-field-value'}>{nextField.value}</span>
                        }
                    </Grid>;
                })
            }
        </Grid>;
    };

    contentTitle = () => {
        if (this.state.contentFilter === 'documents') {
            return 'Documents'
        }
        else if (this.state.contentFilter === 'emails') {
            return 'Emails';
        }
        else if (this.state.contentFilter === 'folders') {
            return 'Folders';
        }
        else {
            return '';
        }
    };

    contents = () => {
        return <Box>
            <FilterContents anchor={this.state.filterContentAnchor}
                            open={Boolean(this.state.filterContentAnchor)}
                            close={() => this.setState({filterContentAnchor: null})}
                            currentFilter={this.state.contentFilter}
                            filterContentTo={(newFilter) => this.setState({filterContentAnchor: null, contentFilter: newFilter})}/>

            <DndProvider backend={Backend}>
                <DataTable
                    columns={[
                        {
                            id: 'preview',
                            Cell: ({ row }) =>
                                !!row.original.document ?
                                    <Tooltip title={'Preview'}>
                                        <IconButton {...row.getToggleRowExpandedProps({title: ''})} style={{display: 'block', margin: 'auto'}}>
                                            {row.isExpanded ? <KeyboardArrowDown/> : <KeyboardArrowRight/>}
                                        </IconButton>
                                    </Tooltip>
                                    : null,
                            width: 25,
                            maxWidth: 25,
                        },
                        {
                            Header: 'Name',
                            id: 'name',
                            Cell: ({ row }) =>                                
                                !!row.original.document ?
                                    this.documentLink(row.original) :
                                    this.folderLink(row.original),
                        },
                        {
                            Header: 'Filed',
                            id: 'filed',
                            accessor: row => !!row.filed && moment.unix(row.filed).format('DD MMM YYYY HH:mm:ss'),
                            sortType: (row0, row1) => {
                                return row0.original.filed - row1.original.filed;
                            },
                            width: 100,
                            maxWidth: 100,
                        },
                        {
                            Header: 'Filed by',
                            accessor: 'filedBy'
                        },
                        {
                            Header: '',
                            id: 'actions',
                            width: 200,
                            Cell: ({ row }) =>
                                !!row.original.document &&
                                <Box className={'vertically-centered'}>
                                    <UserContext.Consumer>
                                        {user =>
                                            <DocumentUpload apiClient={this.props.apiClient}
                                                            folderId={this.props.id}
                                                            documentId={row.original.document.id()}
                                                            username={(user || {}).email}
                                                            finished={this.loadFolder}
                                                            disabled={this.rowsSelected()}/>
                                        }
                                    </UserContext.Consumer>
                                    <Tooltip title={this.rowsSelected() ? "" : "View the versions of this document"}>
                                        <IconButton onClick={() => {this.viewDocumentVersions(row.original.document)}} disabled={this.rowsSelected()}>
                                            <Layers/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={this.rowsSelected() ? "" : "Edit the permissions of this document"}>
                                        <IconButton onClick={() => {this.editDocumentPermissions(row.original.document)}} disabled={this.rowsSelected()}>
                                            <LockIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={this.rowsSelected() ? "" : "Move/Copy this document to another folder"}>
                                        <IconButton onClick={() => {this.selectDocumentForMove(row.original)}} disabled={this.rowsSelected()}>
                                            <LocalShipping/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={this.rowsSelected() ? "" : "Delete this document"}>
                                        <IconButton onClick={() => {this.selectDocumentForDelete(row.original)}} disabled={this.rowsSelected()}>
                                            <DeleteSweep/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                        },
                    ]}
                    title={this.contentTitle()}
                    data={this.visibleContents()}
                    selectionChanged={(selectedRows) => {
                        if (selectedRows.length !== this.state.selected.length) {
                            this.setState({selected: selectedRows});
                        }
                    }}
                    selectionActions={
                        <Box>
                            <Tooltip title="Move/Copy All Selected Documents">
                                <IconButton onClick={() => this.setState({bulkActionsDialogOpen: true})}>
                                    <LocalShipping/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete All Selected Documents">
                                <IconButton onClick={this.deleteSelectedDocuments}>
                                    <DeleteSweep/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }                
                    filterFunction={(rows, id, filterValue) => {
                        filterValue = filterValue.toLowerCase();
                        return rows.filter(row => {
                            return (row.original.name || filterValue).toLowerCase().includes(filterValue) ||
                                (row.original.filedBy || filterValue).toLowerCase().includes(filterValue);
                        });
                    }}
                    globalActions={
                        <Tooltip title="Filter">
                            <IconButton onClick={(event) => this.setState({filterContentAnchor: event.currentTarget})}>
                                <FilterList className={'contents-filter'}/>
                            </IconButton>
                        </Tooltip>
                    }
                    subComponent={(row) => {                            
                            return (      
                                <React.Fragment>
                                <TableCell />
                                <TableCell />
                                <TableCell colSpan="3">
                                    <DocumentPreview apiClient={this.props.apiClient}
                                    document={row.original.document}
                                    asyncCallMade={this.props.asyncCallMade}/>                        
                                </TableCell>    
                                </React.Fragment>                      
                            )
                        }
                    }/>
            </DndProvider>
        </Box>;
    };

    rowsSelected = () => {
        return this.state.selected.length > 0;
    };

    moveDocPopup = () => {
        const documents = this.state.selected.filter(doc => !!doc.document).map(doc => doc.document.document);
        //const folders = this.state.selected.filter(fld => !!fld.folder); // uncomment when we are ready to select folders as well and concat with the docs

        return <MoveDocumentsPopup apiClient={this.props.apiClient}
                                   selected={documents}
                                   close={this.closeDialog}
                                   finishedMove={this.finishedMove}
                                   finishedCopy={this.finishedCopy}/>;
    };

    closeDialog = () => {
        this.setState({bulkActionsDialogOpen: false,
                       selected: []});
    };

    documentLink(row) {
        return <MovableDocumentLink apiClient={this.props.apiClient}
                                    document={row.document}
                                    moved={this.loadFolder}/>;
    }

    selectDocumentForMove = (document) => {
        this.setState({selected: [document],
                       bulkActionsDialogOpen: true});
    };

    selectDocumentForDelete = (document) => {
        this.setState({selected: [document]}, 
            this.deleteSelectedDocuments
        );
    };

    folderLink(folder) {
        return <DroppableFolderLink folder={folder.folder}/>;
    }

    editDocumentPermissions = (document) => {
        this.setState({editingDocumentPermissions: document});
    };

    editPermissions = () => {
        this.menuClose();
        this.setState({editingPermissions: true});
    };

    isContentVisible = (typeOfContent) => {
        return this.state.contentFilter === 'everything' ||
            this.state.contentFilter === typeOfContent;
    };

    loadFolder = () => {
        this.setState({working: true, folder: null}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query({
                    query: gql(`query {
                        folder(folder_id: "${this.props.id}") {
                            id description fields { name type value }
                            parent { id description }
                            children { id description }                         
                        }
                        docs(folder_id: "${this.props.id}") { id folder_id file_name user_name snippet metadata { name value } }
                    }`),
                    fetchPolicy: 'no-cache'
                }).then((results) => {
                    this.setState({
                        working: false,
                        folder: {
                            ...results.data['folder'],
                            docs: results.data['docs'].map((nextDoc) => {
                                return new WebFylerDocument(nextDoc);
                            })
                        }
                    });
                }).catch((reason) => {
                    console.log(`Failed to load folder this id ${this.props.id} because ${reason}`);
                    this.setState({
                        working: false,
                        folder: {
                            description: 'This folder could not be found',
                            fields: [],
                            docs: [],
                            children: []
                        }
                    });
                })
            );
        });
    };

    menuClick = (event) => {
        this.setState({menuAnchor: event.currentTarget});
    };

    menuClose = () => {
        this.setState({menuAnchor: null});
    };

    workflow = () => {
        return <FolderWorkflow apiClient={this.props.apiClient}
                               id={this.props.id}/>;
    };

    viewDocumentVersions = (document) => {
        this.setState({viewingDocumentVersions: document});
    };

    visibleContents() {
        let items = [];

        if (this.isContentVisible('folders')) {
            items = items.concat(this.state.folder.children.sort((children0, children1) => {
                return children0.description.localeCompare(children1.description);
            }).map((nextChildFolder) => {
                return {
                    id: nextChildFolder.id,
                    name: nextChildFolder.description,
                    folder: nextChildFolder,
                    tableData: {
                        checked: this.state.selected.filter(row => this.selectedContainsFolder(row, nextChildFolder)).length > 0
                    }
                };
            }));
        }

        items = items.concat(this.state.folder.docs.sort((doc0, doc1) => {
            return doc1.timestamp() - doc0.timestamp();
        }).filter((nextDoc) => {
            if (nextDoc.isEmail()) {
                return this.isContentVisible('emails');
            }
            else {
                return this.isContentVisible('documents');
            }
        }).map((nextDoc) => {
            return {
                name: nextDoc.fileName(),
                filed: nextDoc.timestamp(),
                filedBy: nextDoc.asObject().user_name,
                document: nextDoc,
                tableData: {
                    checked: this.state.selected.filter(row => this.selectedContains(row, nextDoc)).length > 0
                }
            };
        }));

        return items;
    }

    selectedContains = (row, nextDoc) => {
        if (!!row.document) {
            return row.document.document.id === nextDoc.document.id;
        }
        return false;
    };

    selectedContainsFolder = (row, nextFolder) => {
        if (!!row.folder) {
            return row.folder.id === nextFolder.id;
        }
        return false;
    };
}

export default withRouter(Folder);
