import * as React from 'react';
import gql from "graphql-tag";
import {CircularProgress, Tooltip} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from "@material-ui/core/IconButton";
import * as moment from "moment";
import Box from "@material-ui/core/Box";
import DataTable from "../tables/DataTable";

export default class DocumentVersions extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {},
        finished: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            versions: []
        };
    };

    componentDidMount() {
        this.loadVersions();
    }

    render() {
        if (this.state.loading) {
            return <CircularProgress/>
        }
        else {
            return <Box>
                <Box className={'horizontally-right'}>
                    <Tooltip title="Return to folder">
                        <IconButton onClick={this.props.finished}>
                            <ArrowBackIcon/>
                        </IconButton>
                    </Tooltip>
                </Box>

                <DataTable
                    columns={[
                        {
                            Header: 'Version',
                            accessor: 'version'
                        },

                        {
                            Header: 'Timestamp',
                            id: 'timestamp',
                            Cell: ({ row }) => <span>{moment.unix(row.original.timestamp).format('DD MMM YYYY HH:mm:ss')}</span>,
                            disableSortBy: true
                        },
                        {
                            Header: 'User',
                            id: 'user',
                            Cell: ({ row }) => <span>{this.userForVersion(row.original)}</span>
                        },
                        {
                            Header: '',
                            id: 'actions',
                            width: 100,
                            Cell: ({ row }) =>
                                <Tooltip title="Download this version">
                                    <IconButton onClick={() => {this.download(row.original)}}>
                                        <CloudDownloadIcon/>
                                    </IconButton>
                                </Tooltip>
                        },
                    ]}
                    data={this.state.versions}
                    initialSort={[{id: 'version', desc: true}]}/>
            </Box>;
        }
    }

    download = (version) => {
        this.props.apiClient.query({
            query: gql(`query {docVersion(folder_id: "${this.props.document.matterId()}", id: "${this.props.document.id()}", version_id: "${version.id}") { download_url }}`),
            fetchPolicy: 'no-cache'
        }).then((results) => {
            this.props.document.downloadFrom(results.data['docVersion']['download_url']);
        });
    };

    loadVersions = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query DocVersions($folder_id: String!, $id: ID!) {
                    docVersions(folder_id: $folder_id, id: $id) { 
                        id timestamp metadata { name value }
                    } 
                }`),
                variables: {
                    id: this.props.document.id(),
                    folder_id: this.props.document.matterId()
                },
                fetchPolicy: 'no-cache'
            }).then((results) => {
                const versions = results.data['docVersions'];
                this.setState({
                    loading: false,
                    versions: versions.map((nextVersion, index) => {
                        return {
                            ...nextVersion,
                            version: `v${versions.length - index}`
                        }
                    })
                });
            }).catch((error) => {
                console.log(`Got unexpected error: ${JSON.stringify(error)}`);
            })
        );
    };

    userForVersion = (version) => {
        let userMetadata = version.metadata.find((nextMetadata) => {
            return nextMetadata.name === 'user';
        });

        if (!!userMetadata) {
            return JSON.parse(userMetadata.value);
        }
        else {
            return undefined;
        }
    };
}
