import * as React from 'react';
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

class GroupForm extends React.Component {

    render() {
        return <Formik
            initialValues={this.props.group}
            validate={this.validate}
            onSubmit={this.props.save}>
            {
                ({ values, isSubmitting }) => {
                    return <Form style={{width: '50%'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field name='name' label={'Name'} component={TextField} value={values.name} fullWidth helperText="Must not contain whitespace or punctuation"/>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='contained' color='primary' disabled={isSubmitting} type={'submit'}>
                                    {this.props.submitButtonText}
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='contained' color='secondary' disabled={isSubmitting} onClick={this.props.cancel}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                }
            }
        </Formik>;
    };

    validate = (values) => {
        let errors = {};
        if (!values.name) {
            errors.name = 'Required';
        }
        else if (!new RegExp('^\\w+$').test(values.name)) {
            errors.name = 'Invalid group name. Ensure it contains no whitespace or punctuation characters.';
        }
        return errors;
    };
}

export default GroupForm;
