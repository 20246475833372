import * as React from 'react';
import gql from "graphql-tag";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {Check, Error, VerticalAlignTop} from "@material-ui/icons";
import FolderSearchResult from "./FolderSearchResult";
import SearchField from "../SearchField";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Link from "@material-ui/core/Link";
import {withRouter} from "react-router-dom";
import PlainLink from "../PlainLink";

class MoveFolder extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            results: null,
            searching: false,
            errorMessage: null
        };
    };

    render() {
        return <Box>
            <Typography variant={'h5'} style={{textAlign: 'center'}}>
                Moving <PlainLink to={`/folders/${this.props.id}`}>
                    Folder
                </PlainLink>
            </Typography>

            <Box mt={3}>
                <Box style={{display: 'flex', justifyContent: 'center'}} mb={2}>
                    <SearchField query={this.props.query}
                                 search={this.search}
                                 searching={this.state.searching}/>

                    <Box component={'span'} style={{alignSelf: 'center', marginRight: 'auto'}} ml={2}>
                        <Box component={'span'} mr={1}>or move this folder to the top</Box>
                        <Button className={'move-to-root'} variant={'contained'} color={'primary'} onClick={() => this.moveTo(null)}>
                            <VerticalAlignTop/>
                        </Button>
                    </Box>
                </Box>

                {!!this.state.errorMessage && this.renderError()}

                {!!this.state.results && this.renderResults()}
            </Box>
        </Box>
    }

    renderResults = () => {
        return <FolderSearchResult
            columns={[
                {
                    Header: "Folder",
                    id: 'name',
                    Cell: ({ row }) => <Link href={'#'} className={'folder'} onClick={() => this.viewFolder(row.original.id)}>
                        {row.original.name}
                    </Link>,
                    sortType: (row0, row1) => {
                        return row0.original.name.localeCompare(row1.original.name);
                    },
                    width: 200
                },
                {
                    Header: "",
                    id: 'actions',
                    Cell: ({ row }) => <Tooltip title="Move to this folder">
                        <Button className={'move-to-folder'} variant={'outlined'} color={'primary'} onClick={() => this.moveTo(row.original.id)}>
                            <Check/>
                        </Button>
                    </Tooltip>,
                    width: 25
                },
            ]}
            initialSort={[{id: 'name'}]}
            results={this.state.results.filter((nextResult) => {
                return nextResult.id !== this.props.id;
            })}/>;
    };

    moveTo = (parentId) => {
        this.setState({searching: true}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.mutate({
                    mutation: gql(`mutation MoveFolder($childId: ID!, $parentId: ID) {
                        moveFolder(id: $childId, parent_id: $parentId)
                    }`),
                    variables: {
                        childId: this.props.id,
                        parentId: parentId
                    }
                }).then(this.props.history.goBack).catch((reason) => {
                    console.log(`Failed to move folder ${JSON.stringify(reason)}`);
                    this.setState({searching: false, errorMessage: reason.graphQLErrors[0].message});
                })
            );
        });
    };

    renderError() {
        return <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                         open={true}
                         onClose={this.clearError}
                         autoHideDuration={5000}>
            <SnackbarContent className={'error'}
                             message={
                                 <div className={'vertically-centered'}>
                                     <Error/>
                                     <Box component={'span'} ml={1}>{this.state.errorMessage}</Box>
                                 </div>
                             }/>
        </Snackbar>;
    }

    search = (query) => {
        this.setState({searching: true, results: null}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query({
                    query: gql(`query Query($query: String!) {
                        searchFolders(query: $query, size: 100) { id description type parent { id description } }
                    }`),
                    variables: {
                        query: query
                    },
                    fetchPolicy: 'no-cache'
                }).then((results) => {
                    const folders = results.data['searchFolders'];
                    this.setState({
                        searching: false,
                        results: folders
                    });
                })
            );
        });
    };

    viewFolder = (id) => {
        this.setState({searching: true, results: null}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query({
                    query: gql(`query {
                        folder(folder_id: "${id}") {
                            children { id description parent { id description } }                         
                        }
                    }`),
                    fetchPolicy: 'no-cache'
                }).then((results) => {
                    const folders = results.data['folder'].children;
                    this.setState({
                        searching: false,
                        results: folders
                    });
                })
            );
        });
    };
}

export default withRouter(MoveFolder);
