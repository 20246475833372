import * as React from 'react';
import {CircularProgress} from "@material-ui/core";
import gql from "graphql-tag";
import Tooltip from "@material-ui/core/Tooltip";
import {Group, AddCircle, RemoveCircleOutline, Done, Clear} from "@material-ui/icons";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';

class User extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            addingGroup: false,
            loading: true,
            users: []
        };
    };

    componentDidMount() {
        this.loadUser();
    }

    render() {
        if (this.state.loading) {
            return <CircularProgress/>;
        }
        else {
            return this.renderUser();
        }
    }

    renderEditGroups = () => {
        return <Grid container>
            <Grid item xs={10} className={'vertically-centered'}>
                <Select variant="outlined"
                        onChange={(event) => this.setState({newGroup: event.target.value})}
                        value={this.state.newGroup}
                        style={{width: '100%'}}>
                    {
                        this.state.groups.map((nextStep, index) => {
                            return <MenuItem key={index} value={nextStep.id}>{nextStep.name}</MenuItem>;
                        })
                    }
                </Select>
            </Grid>
            <Grid item xs={2} className={'vertically-centered'}>
                <Tooltip title="Save">
                    <Button color={'primary'} type={'submit'} onClick={this.addGroup}>
                        <Done/>
                    </Button>
                </Tooltip>
                <Tooltip title="Cancel">
                    <Button color={'secondary'} onClick={() => this.setState({addingGroup: false})}>
                        <Clear/>
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>;
    };

    renderUser() {
        return <Box>
            <Typography variant="subtitle1">
                Groups

                <Tooltip title={'Add this user to a group'}>
                    <IconButton edge="end" className={'user-add-group'} onClick={this.addingGroup}>
                        <AddCircle/>
                    </IconButton>
                </Tooltip>
            </Typography>

            <Alert severity="info">The user will need to log out and back in before any changes to groups are applied.</Alert>

            { this.state.addingGroup && <Box mt={1}>{this.renderEditGroups()}</Box> }

            <List dense={true}>
                {
                    this.state.user.groups.length === 0 ?
                        <ListItem dense={true}>
                            <ListItemText secondary={'None'}/>
                        </ListItem> :
                        this.state.user.groups.sort((group0, group1) => {
                            return group0.name.localeCompare(group1.name);
                        }).map((nextGroup, index) => {
                            return <ListItem key={index} dense={true} className={'user-group'}>
                                <ListItemIcon><Group/></ListItemIcon>
                                <ListItemText primary={nextGroup.name}/>
                                <ListItemSecondaryAction>
                                    <Tooltip title={'Remove the user from this group'}>
                                        <IconButton edge="end" className={'user-remove-group'} onClick={() => this.removeFromGroup(nextGroup.id)}>
                                            <RemoveCircleOutline/>
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                        })
                }
            </List>
        </Box>;
    }

    addGroup = () => {
        if (!!this.state.newGroup) {
            this.setState({loading: true}, () => {
                this.props.asyncCallMade(
                    this.props.apiClient.mutate({
                        mutation: gql(`mutation {
                            addUserToGroup(username: "${this.state.user.username}", group: "${this.state.newGroup}")
                        }`)
                    }).then((_) => {
                        this.state.user.groups.push({id: this.state.newGroup, name: this.state.newGroup});
                    }).catch((reason) => {
                        console.log(`Failed to add user to group: ${JSON.stringify(reason)}`);
                    }).finally(() => {
                        this.setState({loading: false, addingGroup: false, newGroup: null});
                    })
                );
            });
        }
        else {
            this.setState({addingGroup: false});
        }
    };

    addingGroup = () => {
        this.setState({addingGroup: true, loading: true}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query({
                    query: gql(`query {groups { id name } }`),
                    fetchPolicy: 'no-cache'
                }).then((results) => {
                    const userGroupIds = this.state.user.groups.map((nextUserGroup) => nextUserGroup.id);
                    this.setState({
                        loading: false,
                        groups: results.data['groups'].filter((nextGroup) => !userGroupIds.includes(nextGroup.id))
                    });
                })
            );
        });
    };

    loadUser = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {user(id: "${this.props.id}") { id username groups { id name } }}`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    loading: false,
                    user: results.data['user']
                });
            })
        );
    };

    removeFromGroup = (groupId) => {
        this.setState({loading: true}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.mutate({
                    mutation: gql(`mutation {
                    removeUserFromGroup(username: "${this.state.user.username}", group: "${groupId}")
                }`)
                }).then((_) => {
                    this.setState({user: {
                        ...this.state.user,
                        groups: this.state.user.groups.filter((nextGroup) => nextGroup.id !== groupId)
                    }});
                }).catch((reason) => {
                    console.log(`Failed to remove user from group: ${JSON.stringify(reason)}`);
                }).finally(() => {
                    this.setState({loading: false});
                })
            );
        });
    };
}

export default User;
