import {FylerDocument} from "@verlata/fyler-office-common";
import React from "react";
import {FaEnvelope, FaFile, FaFileExcel, FaFilePdf, FaFilePowerpoint, FaFileWord} from "react-icons/fa";
import * as fileDownload from "js-file-download";
import axios from "axios";
import Box from "@material-ui/core/Box";

export default class WebFylerDocument extends FylerDocument {

    unitTest = false;

    constructor(document, unitTest) {
        super(document);

        this.unitTest = unitTest;
    }

    cc = () => {
        return this.getMetadataValue('cc') || [];
    };

    downloadFrom = (downloadUrl) => {
        axios.get(downloadUrl, {
            responseType: 'arraybuffer'
        }).then((response) => {
            console.log(`Got response: ${JSON.stringify(response)}`);
            if (!this.unitTest) {
                fileDownload(response.data, super.fileName(), response.headers['content-type']);
            }
        });
    };

    folderId() {
        return this.matterId();
    }

    icon() {
        const officeIcon = this.iconForExtension();
        if (this.isEmail()) {
            return <Box component={'div'} className={'vertically-centered'} style={{color: this.colourForExtension()}}><FaEnvelope/></Box>;
        }
        else if (officeIcon === 'PDF') {
            return <Box component={'div'} className={'vertically-centered'} style={{color: this.colourForExtension()}}><FaFilePdf/></Box>;
        }
        else if (officeIcon === 'WordDocument') {
            return <Box component={'div'} className={'vertically-centered'} style={{color: this.colourForExtension()}}><FaFileWord/></Box>;
        }
        else if (officeIcon === 'ExcelDocument') {
            return <Box component={'div'} className={'vertically-centered'} style={{color: this.colourForExtension()}}><FaFileExcel/></Box>;
        }
        else if (officeIcon === 'PowerPointDocument') {
            return <Box component={'div'} className={'vertically-centered'} style={{color: this.colourForExtension()}}><FaFilePowerpoint/></Box>;
        }
        else {
            return <FaFile/>;
        }
    }

    id() {
        return this.asObject().id;
    }

    setFileName(newFileName) {
        this.asObject()['file_name'] = newFileName;
    }
}
