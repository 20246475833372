import * as React from 'react';
import {CircularProgress} from "@material-ui/core";
import gql from "graphql-tag";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Add, Delete, Edit} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import ButtonLink from "../../ButtonLink";
import DataTable from "../../tables/DataTable";

class FolderTemplates extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            templates: []
        };
    };

    componentDidMount() {
        this.loadTemplates();
    }

    render() {
        if (this.state.loading) {
            return <CircularProgress/>;
        }
        else {
            return <Box>
                <Box mb={1} className={'vertically-centered'} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant={'body2'}>
                        Templates are folder configurations that define required fields and a common layout.
                        Users will choose a template to inherit from when creating a new folder.
                    </Typography>
                    {this.renderMenu()}
                </Box>

                <DataTable
                    columns={[
                        {
                            Header: 'Name',
                            accessor: 'name'
                        },
                        {
                            Header: 'Category',
                            accessor: 'category'
                        },
                        {
                            Header: '',
                            id: 'actions',
                            width: 100,
                            Cell: ({ row }) =>
                                <Box className={'vertically-centered'}>
                                    <ButtonLink to={`/templates/${row.original.id}/edit`}>
                                        <Tooltip title="Edit this template">
                                            <IconButton>
                                                <Edit/>
                                            </IconButton>
                                        </Tooltip>
                                    </ButtonLink>

                                    <Tooltip title="Delete">
                                        <IconButton className={'delete-folder-template'} onClick={() => {this.delete(row.original)}}>
                                            <Delete/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                        },
                    ]}
                    data={this.state.templates}
                    initialSort={[{id: 'name'}]}/>
            </Box>;
        }
    }

    delete = (template) => {
        if (this.props.unitTest ||
            window.confirm('Are you sure you want to delete this template?')) {
            this.setState({loading: true}, () => {
                this.props.asyncCallMade(
                    this.props.apiClient.mutate({
                        mutation: gql(`mutation DeleteFolderTemplate($id: ID!) {
                            deleteFolderTemplate(id: $id)
                        }`),
                        variables: {
                            id: template.id
                        }
                    }).then(this.loadTemplates).catch((reason) => {
                        console.log(`Failed to delete folderTemplate ${template.id} because ${reason}`);
                        this.setState({
                            loading: false
                        });
                    })
                );
            });
        }
    };

    loadTemplates = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {folderTemplates { id name category }}`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    loading: false,
                    templates: results.data['folderTemplates']
                });
            })
        );
    };

    renderMenu() {
        return <ButtonLink to={`/templates/new`} style={{marginLeft: 'auto'}}>
            <Tooltip title={'Add a new template'}>
                <Fab color="primary">
                    <Add/>
                </Fab>
            </Tooltip>
        </ButtonLink>;
    }
}

export default FolderTemplates;
