import * as React from 'react';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {CircularProgress} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

class SearchField extends React.Component {

    static defaultProps = {
        style: {}
    };

    constructor(props) {
        super(props);

        this.state = {
            query: props.query,
        };
    };

    render() {
        return <TextField name='query'
                          label='Search'
                          value={this.state.query || ''}
                          onChange={this.queryChanged}
                          onKeyPress={this.keyPress}
                          fullWidth
                          variant="outlined"
                          style={{maxWidth: '512px', marginLeft: 'auto', ...this.props.style}}
                          autoFocus
                          InputProps={{
                              endAdornment: (
                                  <InputAdornment position="end" className={'pointer'} onClick={() => this.props.search(this.state.query)}>
                                      {this.props.searching ? <CircularProgress/> : <SearchIcon/>}
                                  </InputAdornment>
                              ),
                          }}/>;
    }

    keyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.props.search(this.state.query);
        }
    };

    queryChanged = (event) => {
        this.setState({query: event.target.value});
    };
}

export default SearchField;
