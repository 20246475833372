import * as React from 'react';
import gql from "graphql-tag";
import {CircularProgress} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FolderStep from "./FolderStep";
import {Add, Clear, Done, ImportExport, Info} from "@material-ui/icons";
import {UserContext} from "../auth/UserContext";
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const DragHandle = sortableHandle(() =>
    <Tooltip title={'Click and drag to re-order this step'}>
        <IconButton>
            <ImportExport/>
        </IconButton>
    </Tooltip>
);

const SortableItem = sortableElement(({apiClient, folderId, step, previousSteps, highlight, reloadCallback, hoveredCallback}) => (
    <UserContext.Consumer>
        {user =>
            <FolderStep apiClient={apiClient}
                        folderId={folderId}
                        step={step}
                        previousSteps={previousSteps}
                        user={user}
                        highlight={highlight}
                        reload={reloadCallback}
                        dependencyHovered={hoveredCallback}
                        reorderHandle={<DragHandle/>}/>
        }
    </UserContext.Consumer>
));

const SortableContainer = sortableContainer(({children}) => {
    return <ul>{children}</ul>;
});

class FolderWorkflow extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            addingStep: false,
            addingStepSelection: null,
            highlightedStepId: null,
            workflow: [],
            working: true
        };
    };

    componentDidMount() {
        this.loadWorkflow();
    }

    render() {
        if (this.state.working) {
            return <CircularProgress/>;
        }
        else {
            return <Box>
                {
                    this.state.addingStep ? this.renderAddStepForm() : this.renderAddStepButton()
                }

                {
                    this.state.workflow.length > 0 ?
                        this.renderWorkflow() :
                        this.renderNoWorkflow()
                }
            </Box>;
        }
    }

    addStep = () => {
        if (!!this.state.addingStepSelection) {
            this.setState({working: true});

            this.props.asyncCallMade(
                this.props.apiClient.mutate({
                    mutation: gql(`mutation {
                        addWorkflowStep(folder_id: "${this.props.id}", step_id: "${this.state.addingStepSelection}")
                    }`)
                }).then(() => {
                    this.setState({addingStep: false}, this.loadWorkflow);
                }).catch((reason) => {
                    console.log(`Failed to add workflow step because: ${JSON.stringify(reason)}`);
                    this.setState({working: false, addingStep: false});
                })
            );
        }
    };

    loadAvailableSteps = () => {
        this.setState({working: true, addingStep: true, availableSteps: null}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query({
                    query: gql(`query {
                        steps { id name }
                    }`),
                    fetchPolicy: 'no-cache'
                }).then((results) => {
                    this.setState({
                        working: false,
                        availableSteps: results.data['steps'].filter((nextStep) => {
                            return !this.state.workflow.map((workflowStep) => workflowStep.step_id)
                                .includes(nextStep.id);
                        })
                    });
                }).catch((reason) => {
                    console.log(`Failed to load available steps because ${reason}`);
                    this.setState({working: false, addingStep: false});
                })
            );
        });
    };

    loadWorkflow = () => {
        this.setState({working: true, folder: null}, () => {
            this.props.asyncCallMade(
                this.props.apiClient.query({
                    query: gql(`query {
                        folder(folder_id: "${this.props.id}") {
                            id 
                            workflow { 
                                id 
                                step_id
                                name 
                                completed_at 
                                order 
                                dependencies
                                assignees { 
                                    id 
                                    first_name 
                                    last_name 
                                    email 
                                } 
                                comments {
                                    id
                                    content
                                    when
                                    by
                                }
                            }                
                        }
                    }`),
                    fetchPolicy: 'no-cache'
                }).then((results) => {
                    this.setState({
                        working: false,
                        workflow: this.sortWorkflow(
                            this.linkDependencies(results.data['folder']['workflow']))
                    });
                }).catch((reason) => {
                    console.log(`Failed to load workflow for id ${this.props.id} because ${reason}`);
                    this.setState({working: false});
                })
            );
        });
    };

    renderAddStepButton = () => {
        return <Box component={'span'} style={{float: 'right'}}>
            <Tooltip title={'Add a new step to the workflow'} style={{marginLeft: 'auto'}}>
                <Fab color="primary" size={'small'} onClick={this.loadAvailableSteps}>
                    <Add/>
                </Fab>
            </Tooltip>
        </Box>;
    };

    renderAddStepForm = () => {
        return <Grid container>
            <Grid item xs={4} className={'vertically-centered'}>
                <Select autoFocus
                        fullWidth
                        required
                        variant={'outlined'}
                        value={this.state.addingStepSelection}
                        onChange={(event) => this.setState({addingStepSelection: event.target.value})}>
                    {
                        this.state.availableSteps.map((nextStep, optionIndex) => {
                            return <MenuItem key={optionIndex} value={nextStep.id}>
                                <Box ml={1} component={'span'} className={'permissionSubjectName'}>{nextStep.name}</Box>
                            </MenuItem>;
                        })
                    }
                </Select>
            </Grid>
            <Grid item xs={2} className={'vertically-centered'}>
                <Tooltip title="Save">
                    <Button color={'primary'} type={'submit'} onClick={this.addStep}>
                        <Done/>
                    </Button>
                </Tooltip>
                <Tooltip title="Cancel">
                    <Button color={'secondary'} onClick={() => {this.setState({addingStep: false})}}>
                        <Clear/>
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>;
    };

    renderNoWorkflow = () => {
        return <div className={'info vertically-centered'}>
            <Info/>
            <Box component={'span'} ml={1}>This folder has no workflow</Box>
        </div>;
    };

    renderWorkflow = () => {
        const stepsSoFar = [];
        return (
            <SortableContainer onSortEnd={this.reordered} useDragHandle>
                {
                    this.state.workflow.map((nextStep, index) => {
                        const previousSteps = [...stepsSoFar];
                        const folderStep = <SortableItem key={index}
                                                         index={index}
                                                         apiClient={this.props.apiClient}
                                                         folderId={this.props.id}
                                                         step={nextStep}
                                                         previousSteps={previousSteps}
                                                         highlight={nextStep.id === this.state.highlightedStepId}
                                                         reloadCallback={this.loadWorkflow}
                                                         hoveredCallback={this.stepHovered}/>;
                        stepsSoFar.push(nextStep);
                        return folderStep;
                    })
                }
            </SortableContainer>
        );
    };

    linkDependencies = (workflow) => {
        const stepIdToFolderStep = {};
        workflow.forEach((nextFolderStep) => {
            stepIdToFolderStep[nextFolderStep.step_id] = nextFolderStep;
        });

        workflow.forEach((nextFolderStep) => {
            nextFolderStep.dependencies = nextFolderStep.dependencies.map((nextDependency) => {
                return stepIdToFolderStep[nextDependency];
            }).filter((nextDependency) => !!nextDependency);
        });
        return workflow;
    };

    reordered = ({oldIndex, newIndex}) => {
        this.setState({working: true});

        const step = this.state.workflow[oldIndex];
        const newOrder = step.order + (newIndex - oldIndex);
        this.props.asyncCallMade(
            this.props.apiClient.mutate({
                mutation: gql(`mutation {
                    updateWorkflowStepOrder(folder_id: "${this.props.id}", step_id: "${step.id}", order: ${newOrder})
                }`)
            }).then(this.loadWorkflow).catch((reason) => {
                console.log(`Failed to update workflow order: ${JSON.stringify(reason)}`);
                this.setState({working: false});
            })
        );
    };

    sortWorkflow = (workflow) => {
        return workflow.sort((step0, step1) => {
            return step0.order - step1.order;
        });
    };

    stepHovered = (dependencyId, hovered) => {
        this.setState({highlightedStepId: hovered ? dependencyId : null});
    };
}

export default FolderWorkflow;
